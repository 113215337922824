
const persistence = {
  CONFIGURED_LOCATION_RADIUS: 'distanceInMet',
  CONFIGURED_LOCATION_RADIUS_EVENT: 'distanceInMetEvent',
  PLACES: 'locations',
  LAST_KNOW_POSITION: 'lastKnowPosition',
  NEW_USER: 'newUser',
  DEVICE_PUSH_TOKEN: 'devicePushToken',
  IS_AUTHENTICATED: 'isAuthenticated',
};

export default persistence;
