import { useEffect, useState } from 'react';
import { getGroup } from 'middleware/src/database/groups';

const useGroupPasswordChecker = (groupId, participant, notParticipant) => {
  const [password, setPassword] = useState(undefined);
  const [showAskPassword, setShowAskPassword] = useState(null);
  const [loadingPassword, setLoadingPassword] = useState(true);

  const passwordWasRequested = showAskPassword === true && loadingPassword === false;

  useEffect(() => {
    if (notParticipant) {
      getGroup(groupId).then((groupData) => {
        if (groupData.private) {
          setPassword(groupData.password);
          setShowAskPassword(true);
        } else {
          setShowAskPassword(false);
        }
        setLoadingPassword(false);
      });
    } else if (participant) {
      setLoadingPassword(false);
      setShowAskPassword(false);
    }
  }, [groupId, notParticipant, participant]);

  return {
    password,
    showAskPassword,
    loadingPassword,
    passwordWasRequested,
    setLoadingPassword,
  };
};

export default useGroupPasswordChecker;
