import React, { useState } from 'react';
import styled from 'styled-components/native';
import {
  Dimensions, View, TouchableOpacity, Image, Modal,
} from 'react-native';
import PropTypes from 'prop-types';
import moment from 'moment';
import MainTheme from '../../state/constants/Colors';
import StyledText from '../atoms/StyledText';
import mainTheme from '../../state/constants/Galery';
import ArrowBackIcon from '../../../assets/images/arrow-back-icon.png';
import DateView from '../atoms/DateView';
import TextLink from './TextLink';
import Spacer from '../atoms/Spacer';
import Title from '../atoms/Title';

const Width = Dimensions.get('window').width;

const MessageContainer = styled.View`
    background: #ffffff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    elevation: 1;
    padding: 7px 12px;
    border-radius: 10;
    min-width: 30;
    max-width: ${Width - Width / 3};
    align-self: ${(props) => (props.sent ? 'flex-end' : 'flex-start')};
    background-color: ${(props) => (props.sent ? MainTheme.lightPrimary : 'white')};
    margin-bottom: 8;
`;

const MessageBallon = ({
  userId,
  sent,
  item,
  dateInHeader,
  shouldDisplayDateInHeader,
  navigation,
  openOptionsModal,
  allowInteractions,
}) => {
  const [showImageModal, setShowImageModal] = useState(false);

  return (
    <View>
      {shouldDisplayDateInHeader ? <DateView date={dateInHeader} /> : null}
      <TouchableOpacity
        onLongPress={() => (allowInteractions ? openOptionsModal() : false)}
      >
        <MessageContainer sent={sent}>
          { !sent && (
          <Title
            primary
            nano
            bold
            onPress={() => (allowInteractions
              ? navigation.navigate('Profile', { userId }) : false)}
          >
            { item.username || item.name }
          </Title>
          )}
          <Spacer size={8} />
          {
            !!item.message && (
              <TextLink micro text={item.message} />
            )
          }
          {
            item.imageUrl && (
              <TouchableOpacity
                onPress={() => {
                  setShowImageModal(true);
                }}
              >
                <Image
                  style={{
                    width: 230,
                    height: 230,
                    borderRadius: 5,
                    marginTop: 4,
                    marginBottom: 3,
                  }}
                  source={{
                    uri: item.imageUrl,
                  }}
                />
              </TouchableOpacity>
            )
          }
          <View>
            <StyledText style={{
              textAlign: 'right',
              fontSize: 12,
              opacity: 0.6,
            }}
            >
              {moment(item.createdAt)
                .format('HH:mm')}
            </StyledText>
          </View>
          {
            showImageModal && (
              <Modal
                animationType="slide"
                transparent={false}
                visible={showImageModal}
                onRequestClose={() => setShowImageModal(false)}
              >
                <View style={{ zIndex: 9999 }}>
                  <TouchableOpacity onPress={() => setShowImageModal(false)}>
                    <Image
                      style={{
                        tintColor: mainTheme.primary,
                        height: 30,
                        width: 30,
                        marginTop: 50,
                        marginLeft: 10,
                      }}
                      source={ArrowBackIcon}
                    />
                  </TouchableOpacity>
                </View>
                <Image
                  style={{
                    resizeMode: 'contain',
                    width: '100%',
                    height: '100%',
                    marginTop: -40,
                    zIndex: 1,
                  }}
                  source={{ uri: item.imageUrl }}
                />
              </Modal>
            )
          }
        </MessageContainer>
      </TouchableOpacity>
    </View>
  );
};

MessageBallon.propTypes = {
  userId: PropTypes.any.isRequired,
  sent: PropTypes.bool,
  item: PropTypes.any,
  dateInHeader: PropTypes.any,
  shouldDisplayDateInHeader: PropTypes.bool,
  navigation: PropTypes.object.isRequired,
  openOptionsModal: PropTypes.any,
  allowInteractions: PropTypes.bool,
};

MessageBallon.defaultProps = {
  sent: false,
  item: null,
  dateInHeader: '',
  shouldDisplayDateInHeader: false,
  openOptionsModal: null,
  allowInteractions: false,
};

export default MessageBallon;
