import React from 'react';
import { TouchableOpacity, Image } from 'react-native';
import PropTypes from 'prop-types';
import AppCard from '../atoms/AppCard';
import Title from '../atoms/Title';
import Photos from '../../state/constants/Galery';
import Row from '../atoms/Row';

const SubGroup = ({ name, onPress }) => (
  <AppCard style={{ padding: 10 }}>
    <TouchableOpacity onPress={onPress}>
      <Row style={{ margin: 8 }}>
        <Image style={{ height: 25, width: 25 }} source={Photos.subGroup} />
        <Title
          numberOfLines={1}
          bold
          lite
          style={{
            flex: 1,
            flexWrap: 'wrap',
            marginLeft: 8,
          }}
        >
          {name}
        </Title>
      </Row>
    </TouchableOpacity>
  </AppCard>
);

SubGroup.propTypes = {
  name: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
};

export default SubGroup;
