import { useEffect, useState } from 'react';
import { usersCollection } from 'middleware/src/database/collections';
import { fireAuth } from '../../utils/FirebaseSettings';
import { getAllHobbiesAndSkills, getAllProfessions } from '../../utils/CollectionUtils';

// FIXME migrate method to the middleware project
const useProfile = (foreignUserId) => {
  const [listOfWhoBlockedForeignUser, setListOfWhoBlockedForeignUser] = useState([]);
  const [listBlockedUsersOfCurrentUser, setListBlockedUsersOfCurrentUser] = useState([]);
  const [listOfWhoBlockedCurrentUser, setListOfWhoBlockedCurrentUser] = useState([]);

  const [profileData, setProfileData] = useState(undefined);
  const [unAlteredData, setUnAlteredData] = useState({});
  const [professions, setProfessions] = useState([]);
  const [allProfessions, setAllProfessions] = useState([]);
  const [allHobbiesAndSkills, setAllHobbiesAndSkills] = useState([]);
  const [hobbiesAndSkills, setHobbiesAndSkills] = useState([]);

  const isLoggedUser = !foreignUserId || foreignUserId === fireAuth.currentUser.uid;
  const userIsBlocked = listBlockedUsersOfCurrentUser.includes(foreignUserId);
  const userHasBlockedMe = listOfWhoBlockedCurrentUser.includes(foreignUserId);

  useEffect(() => {
    if (foreignUserId) {
      const unsubscribe = usersCollection()
        .doc(foreignUserId)
        .onSnapshot((doc) => {
          const userData = doc.data();
          if (userData && userData.blockedMeUsers) {
            setListOfWhoBlockedForeignUser(userData.blockedMeUsers);
          }
        });
      return () => unsubscribe();
    }
    return () => {};
  }, [foreignUserId]);

  useEffect(() => {
    const unsubscribe = usersCollection().doc(fireAuth.currentUser.uid).onSnapshot((doc) => {
      const userData = doc.data();
      if (userData) {
        if (userData.blockedUsers) {
          setListBlockedUsersOfCurrentUser(userData.blockedUsers);
        }
        if (userData.blockedMeUsers) {
          setListOfWhoBlockedCurrentUser(userData.blockedMeUsers);
        }
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const uid = foreignUserId || fireAuth.currentUser.uid;
    getAllProfessions().then((result) => setAllProfessions(result));
    getAllHobbiesAndSkills().then((result) => setAllHobbiesAndSkills(result));

    const unsubscribe = usersCollection().doc(uid).onSnapshot((doc) => {
      const userData = doc.data();
      if (userData) {
        setProfileData(userData);
        setProfessions(userData.professions);
        setHobbiesAndSkills(userData.hobbiesAndSkills);
        setUnAlteredData(userData);
      }
    });

    return () => unsubscribe();
  }, [foreignUserId]);

  return {
    listBlockedUsersOfCurrentUser,
    listOfWhoBlockedForeignUser,
    profileData,
    unAlteredData,
    professions,
    allProfessions,
    allHobbiesAndSkills,
    hobbiesAndSkills,
    isLoggedUser,
    userIsBlocked,
    userHasBlockedMe,
  };
};

export default useProfile;
