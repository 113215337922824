import { useCallback, useMemo } from 'react';
import * as Linking from 'expo-linking';
import { getGroupByExternalId } from 'middleware/src/database/groups';
import useListenUrl from './useListenUrl';
import Routes from '../../components/routes/Routes';
import useRegistrationOngoing from './useRegistrationOngoing';
import useAuthWatcher from './useAuthWatcher';

const useGroupAccessByURL = (navigation, errorAction) => {
  const { isAuthenticated } = useAuthWatcher();
  const { seekOngoing } = useRegistrationOngoing();

  const getAuthGroup = useCallback(async (externalId) => {
    if (isAuthenticated === false) {
      return Promise.reject({
        reason: 'Não foi possível obter as informações.',
      });
    }
    const ongoing = await seekOngoing();
    if (ongoing === false) {
      return getGroupByExternalId(externalId)
    } else {
      return Promise.resolve({ result: null });
    }
  }, [isAuthenticated]);

  const checkAndGetExternalId = useMemo(() => (url) => {
    const { path, queryParams } = Linking.parse(url);
    const validUrl = (
      Object.keys(queryParams).length > 0
      && queryParams.externalId
    );
    return validUrl ? queryParams.externalId : null;
  }, []);

  useListenUrl((url) => {
    const externalId = checkAndGetExternalId(url);
    if (!externalId) return;

    getAuthGroup(externalId)
      .then(({ result }) => {
        if (!result) return;
        navigation.navigate(Routes.EXPLORE_LOCATIONS);
        navigation.navigate(Routes.GROUP_CHAT, {
          groupId: result.id,
          parentId: result.parentId,
          groupData: result,
          name: result.name,
        });
      }).catch((data) => errorAction(data
        ? data.reason
        : 'Não foi possível redirecionar para o grupo 😞'));
  });
};

export default useGroupAccessByURL;
