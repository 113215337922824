import React, { useEffect, useMemo, useState } from 'react';
import {
  SafeAreaView,
  ScrollView,
} from 'react-native';
import alert from '../../utils/Alert'
import { usersCollection } from 'middleware/src/database/collections';
import { startPrivateMessageForUser, updateUserProfile } from 'middleware/src/database/users';
import PropTypes from 'prop-types';
import ProfileHeader from '../molecules/ProfileHeader';
import { fireAuth, fireStore } from '../../utils/FirebaseSettings';
import useProfile from '../../state/hooks/useProfile';
import ReportModal from '../molecules/ReportModal';
import Routes from '../routes/Routes';
import ProfileEdit from '../molecules/ProfileEdit';
import Loading from '../atoms/Loading';
import ProfileAbout from '../organisms/ProfileAbout';
import ProfileProfessions from '../organisms/ProfileProfessions';
import ProfileHobbiesAndSkills from '../organisms/ProfileHobbiesAndSkills';
import ProfileButtons from '../molecules/ProfileButtons';

const Profile = ({ navigation }) => {
  const foreignUserId = navigation.getParam('userId');
  const batch = fireStore.batch();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);

  const [aboutMeText, setAboutMeText] = useState('');
  const [professionsChanged, setChangeInProfessions] = useState([]);
  const [hobbiesAndSkillsChanged, setChangeInHobbiesAndSkills] = useState([]);
  const [showReportModal, setShowReportModal] = useState(false);

  const {
    listBlockedUsersOfCurrentUser,
    listOfWhoBlockedForeignUser,
    profileData,
    unAlteredData,
    professions,
    allProfessions,
    allHobbiesAndSkills,
    hobbiesAndSkills,
    isLoggedUser,
    userIsBlocked,
    userHasBlockedMe,
  } = useProfile(foreignUserId);

  useEffect(() => {
    if (profileData) {
      setLoading(false);
      setAboutMeText(profileData.aboutMe);
    }
  }, [profileData]);

  useEffect(() => {
    setChangeInHobbiesAndSkills(hobbiesAndSkills);
    setChangeInProfessions(professions);
  }, [hobbiesAndSkills, professions]);

  const cancelEdit = () => {
    setChangeInProfessions(unAlteredData.professions);
    setChangeInHobbiesAndSkills(unAlteredData.hobbiesAndSkills);
    setEditing(false);
  };

  // FIXME migrate method to the middleware project
  const blockUser = async () => {
    listBlockedUsersOfCurrentUser.push(foreignUserId);
    listOfWhoBlockedForeignUser.push(fireAuth.currentUser.uid);

    batch.update(usersCollection()
      .doc(fireAuth.currentUser.uid), { blockedUsers: listBlockedUsersOfCurrentUser });
    batch.update(usersCollection()
      .doc(foreignUserId), { blockedMeUsers: listOfWhoBlockedForeignUser });
    await batch.commit();
  };

  // FIXME migrate method to the middleware project
  const unblockUser = async (canUnblock) => {
    if (canUnblock) {
      batch.update(usersCollection()
        .doc(fireAuth.currentUser.uid), {
        blockedUsers: listBlockedUsersOfCurrentUser.filter((palette) => palette !== foreignUserId),
      });
      batch.update(usersCollection()
        .doc(foreignUserId), {
        blockedMeUsers: listOfWhoBlockedForeignUser.filter(
          (palette) => palette !== fireAuth.currentUser.uid,
        ),
      });
      await batch.commit();
    }
  };

  const askToBlockOrUnblockUser = async () => {
    alert(
      userIsBlocked ? 'Desbloquear usuário (a)' : 'Bloquear usuário (a)',
      userIsBlocked ? 'Tem certeza que deseja desbloquear o usuário(a)' : 'Tem certeza que deseja bloquear o usuário(a)?',
      [
        {
          text: 'Cancelar',
          style: 'cancel',
        },
        {
          text: userIsBlocked ? 'Desbloquear' : 'Bloquear',
          onPress: async () => {
            if (userIsBlocked) {
              await unblockUser(
                listOfWhoBlockedForeignUser.includes(fireAuth.currentUser.uid),
              );
            } else {
              await blockUser();
            }
          },
        },
      ],
      { cancelable: true },
    );
  };

  const saveChanges = async () => {
    setSaving(true);
    const { uid } = fireAuth.currentUser;
    const data = {
      professions: professionsChanged,
      hobbiesAndSkills: hobbiesAndSkillsChanged,
    };
    data.aboutMe = aboutMeText ? aboutMeText.trim() : '';
    await updateUserProfile(uid, data)
      .then(() => {
        setEditing(false);
        setSaving(false);
      })
      .catch(() => alert('Aviso', 'Ocorreu problemas ao salvar os dados do perfil'));
  };

  const startPrivateMessage = () => {
    startPrivateMessageForUser(foreignUserId, profileData.username)
      .then((data) => {
        navigation.navigate(Routes.PRIVATE_CHAT, {
          foreignUserId,
          foreignName: profileData.username,
          token: data && data.result,
        });
      })
      .catch(() => alert('Aviso', 'Não foi possível processar a solicitação!'));
  };

  const notBlockedUser = useMemo(
    () => () => !userIsBlocked && !userHasBlockedMe,
    [userHasBlockedMe, userIsBlocked],
  );

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {
        loading ? <Loading desc="Buscando informações do perfil..." /> : (
          <>
            <ScrollView contentContainerStyle={{ paddingHorizontal: 15 }}>
              <ProfileHeader
                sendChanges={(data) => saveChanges(data)}
                data={profileData}
                editable={editing}
                isLoggedUser={isLoggedUser}
                notBlockedUser={notBlockedUser()}
                goToSettings={() => navigation.navigate(Routes.SETTINGS)}
              />
              {
                (notBlockedUser() && profileData !== null) && (
                  <>
                    <ProfileAbout
                      visible={(isLoggedUser || (!isLoggedUser && !!aboutMeText))}
                      editing={editing}
                      username={profileData && profileData.username}
                      text={aboutMeText}
                      onChangeText={(text) => setAboutMeText(text)}
                      placeholderText="Escreva sobre você..."
                    />
                    <ProfileProfessions
                      editing={editing}
                      allProfessions={allProfessions}
                      professions={professionsChanged}
                      onChange={(data) => setChangeInProfessions(data)}
                    />
                    <ProfileHobbiesAndSkills
                      editing={editing}
                      allHobbiesAndSkills={allHobbiesAndSkills}
                      hobbiesAndSkills={hobbiesAndSkillsChanged}
                      onChange={(data) => setChangeInHobbiesAndSkills(data)}
                    />
                  </>
                )
              }
              <ProfileButtons
                isLoggedUser={isLoggedUser}
                openReportModal={() => setShowReportModal(true)}
                askToBlockOrUnblockUser={() => askToBlockOrUnblockUser()}
                userIsBlocked={userIsBlocked}
                startChat={() => startPrivateMessage()}
              />
              <ReportModal
                visible={showReportModal}
                onRequestClose={() => setShowReportModal(false)}
                denouncedId={foreignUserId}
                denouncedEmail={profileData.email}
                denouncedName={profileData.name}
                reportType="user"
              />
            </ScrollView>
            <ProfileEdit
              isLoggedUser={isLoggedUser}
              close={() => cancelEdit()}
              saveChanges={() => saveChanges()}
              text="Editando perfil"
              saving={saving}
              editing={editing}
              startEdit={() => setEditing(true)}
            />
          </>
        )
      }
    </SafeAreaView>
  );
};

Profile.propTypes = {
  navigation: PropTypes.any.isRequired,
};

export default Profile;
