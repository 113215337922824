import styled from 'styled-components/native';

const AppCard = styled.View`
    background: #ffffff;
    border-radius: 8;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    elevation: 2;
`;

export default AppCard;
