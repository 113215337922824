import React, { useState, useEffect } from 'react';
import { View, ActivityIndicator } from 'react-native';
import { createBrowserApp } from '@react-navigation/web';
import { createSwitchNavigator } from 'react-navigation';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { get } from '../../utils/PersistenceHandler';
import Login from '../pages/Login';
import Signup from '../pages/Signup';
import RegisterCompany from '../pages/RegisterCompany';
import RecoverPassword from '../pages/RecoverPassword';
import MainStackNavigator from './MainStackNavigator';
import GreetingStack from './GreentingStack';
import persistence from '../../state/constants/Persistence';

const isPWA = () => {
  const isStandaloneMode =
    window.matchMedia('(display-mode: standalone)').matches ||
    window.navigator.standalone;
  const isAndroidApp =
    document.referrer.includes('android-app://') ||
    document.referrer.includes('android-app:');
  const isiOSStandaloneMode =
    window.navigator.platform === 'iPhone' && window.navigator.standalone;
  return isStandaloneMode || isAndroidApp || isiOSStandaloneMode;
};

const history = isPWA() ? createBrowserHistory() : createMemoryHistory();

const AuthStack = createSwitchNavigator({
  Login,
  Signup,
  RecoverPassword,
  RegisterCompany,
  GreetingStack,
  MainStackNavigator,
}, {
  initialRouteName: 'Login',
});

const AppStack = createSwitchNavigator({
  MainStackNavigator,
}, {
  initialRouteName: 'MainStackNavigator',
});

const Navigator = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const isNewUser = await get(persistence.NEW_USER);
        const isAuthenticatedResult = await get(persistence.IS_AUTHENTICATED);
        setIsAuthenticated(!!isAuthenticatedResult && !isNewUser);
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuthentication();
  }, []);

  if (isLoading) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator size="large" />
      </View>
    );
  }

  const MainNavigator = isAuthenticated ? 
  createBrowserApp(AppStack, { history }) : 
  createBrowserApp(AuthStack, { history });
  return <MainNavigator />;
};

export default Navigator;
