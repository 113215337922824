import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  SafeAreaView,
  ScrollView,
  Share,
} from 'react-native';
import alert from '../../utils/Alert'
import PropTypes from 'prop-types';
import {
  generateExternalId,
  getGroup,
  groupExit,
  subGroups,
  updateGroup,
} from 'middleware/src/database/groups';
import { StackActions } from 'react-navigation';
import { GroupType } from 'middleware/src/states/constants/groups';
import { userIsBusiness } from 'middleware/src/database/users';
import { updateEvent } from 'middleware/src/database/events';
import Title from '../atoms/Title';
import { fireAuth } from '../../utils/FirebaseSettings';
import Photos from '../../state/constants/Galery';
import Routes from '../routes/Routes';
import Loading from '../atoms/Loading';
import TextLink from '../molecules/TextLink';
import Spacer from '../atoms/Spacer';
import ButtonEdges from '../molecules/ButtonEdges';
import ButtonColored from '../molecules/ButtonColored';
import ButtonBorderless from '../molecules/ButtonBorderless';
import EventInfo from '../molecules/EventInfo';
import PlaceInfo from '../molecules/PlaceInfo';
import usePlaceInfo from '../../state/hooks/usePlaceInfo';
import SubGroupsInfo from '../molecules/SubGroupsInfo';
import DescriptionEditModal from '../organisms/EditDescriptionModal';
import useGroupDescriptionHandler from '../../state/hooks/useGroupDescriptionHandler';
import * as WebBrowser from 'expo-web-browser';
import useGroupAdminChecker from '../../state/hooks/useGroupAdminChecker';

const GroupInfo = ({ navigation }) => {
  const groupId = navigation.getParam('groupId');
  const currentUser = fireAuth.currentUser;
  const [business, setBusiness] = useState(false);

  const [groupData, setGroupData] = useState({});
  const [subGroupsResult, setSubGroupsResult] = useState([]);
  const { placePresent, placeInfo } = usePlaceInfo(groupData);
  const [seekingGroup, setSeekingGroup] = useState(true);

  const [descEditEnabled, setDescEditEnabled] = useState(false);
  const { description } = useGroupDescriptionHandler(groupData);

  const {
    admin,
  } = useGroupAdminChecker(groupId, currentUser && currentUser.uid);

  const group = useCallback(
    async () => getGroup(groupId), [groupId],
  );

  const fetchSubGroups = useCallback(
    async () => subGroups(groupId), [groupId],
  );

  useEffect(() => {
    group().then((result) => {
      fetchSubGroups().then((groups) => {
        setSubGroupsResult(groups);
        setSeekingGroup(false);
      });
      setGroupData(result);
    });
  }, [fetchSubGroups, group]);

  const shareGroup = useCallback(async () => {
    const externalId = (
      groupData.externalId || await generateExternalId(groupId)
    );
    if (externalId && Object.keys(externalId).length > 0) {
      const url = new URL(
        `actions/link.html?externalId=${externalId}`,
        'https://localchat.link',
      ).toString();
      Share.share({ message: url }).catch((error) => alert(url));
      return Promise.resolve();
    }
    return Promise.reject();
  }, [groupData.externalId, groupId])

  const showAlertAndExitGroup = async () => {
    const alertAdmin = admin ? 'e perderá a ADMINISTRAÇÃO do grupo.' : '';
    alert(
      'Tem certeza que deseja sair do grupo?',
      `Você pode não conseguir entrar novamente ${alertAdmin}`,
      [
        {
          text: 'Cancelar',
          style: 'cancel',
        },
        {
          text: 'Sim',
          onPress: async () => {
            await groupExit(groupId, fireAuth.currentUser.uid)
              .then(() => navigation.goBack())
              .catch(() => alert(
                'Aviso',
                'Não foi possível sair do grupo no momento, verifique sua conexão à internet',
              ));
          },
        },
      ],
      { cancelable: false },
    );
  };

  const Description = React.memo(() => (
    <>
      { description && GroupType.EVENT !== groupData.category && (
        <>
          <Spacer size={12} />
          <TextLink lite text={description} />
        </>
      )}
    </>
  ));

  useEffect(() => {
    const { currentUser } = fireAuth;
    if (currentUser && GroupType.PLACE === groupData.category) {
      userIsBusiness(currentUser.uid).then(setBusiness);
    }
  }, [groupData.category]);

  const canEditDescription = useMemo(() => () => (
    admin && (GroupType.EVENT === groupData.category || GroupType.SUBGROUP === groupData.category)
  ), [admin, groupData.category]);

  const canCreateSubGroups = useMemo(() => () => (
    (admin && GroupType.EVENT === groupData.category)
    || (GroupType.PLACE === groupData.category || GroupType.PLACERS === groupData.category)
  ), [admin, groupData.category]);

  const canCreateEvent = useMemo(() => () => (
    business && groupData.category === GroupType.PLACE
  ), [business, groupData.category]);

  const RenderActions = () => (
    <>
      { canCreateSubGroups() && (
        <ButtonEdges
          source={Photos.subGroup}
          style={{ marginTop: 24 }}
          text="Criar subgrupo"
          onPress={() => navigation.navigate(Routes.SUBGROUP_CREATOR, {
            parentId: groupData.id,
            name: groupData.name,
          })}
        />
      )}
      <ButtonEdges
        source={Photos.share}
        text="Compartilhar grupo"
        onPress={() => {
          setSeekingGroup(true);
          shareGroup()
            .then(() => setSeekingGroup(false))
            .catch(() => {
              setSeekingGroup(false);
              alert(
                'Aviso',
                'Não foi possível compartilhar o grupo 🥺',
              );
            });
        }}
      />
      { canCreateEvent() && (
        <ButtonColored
          style={{ marginTop: 24 }}
          text="Criar evento"
          onPress={() => navigation.navigate(Routes.EVENT_CREATOR, {
            placeId: groupData.parentId,
            placeName: groupData.name,
            business,
          })}
        />
      )}
      {
        GroupType.PLACERS === groupData.category && (
          <ButtonColored
            style={{ marginTop: 24 }}
            text="Consultar Informações"
            onPress={async () => {
              const url = "https://localchat.link/actions/info.html?search=" + groupData.name.slice(1)
              await WebBrowser.openBrowserAsync(url);
            }}
          />
        )
      }
      <Spacer size={12} />
      <ButtonBorderless
        text="Sair do grupo"
        onPress={showAlertAndExitGroup}
      />
    </>
  );

  const updateDescByCategory = async (desc) => {
    switch (groupData.category) {
      case GroupType.EVENT:
        groupData.eventInfo.desc = desc;
        return updateEvent(groupData.parentId, { 'data.description': desc }).then(
          () => updateGroup(groupId, { eventInfo: groupData.eventInfo }),
        );
      case GroupType.SUBGROUP:
        groupData.description = desc;
        return updateGroup(groupId, { description: groupData.description });
      default:
        return Promise.reject();
    }
  };

  return (
    <>
      { seekingGroup || !placePresent ? <Loading desc="Buscando informações do grupo..." /> : (
        <SafeAreaView style={{ flex: 1 }}>
          <ScrollView
            persistentScrollbar
            contentContainerStyle={{ padding: 30 }}
            style={{ flex: 1 }}
          >
            <Title bold>{groupData.name}</Title>
            <Description />
            <PlaceInfo info={placeInfo} />
            <EventInfo info={groupData.eventInfo} />
            { canEditDescription() && (
              <ButtonEdges
                source={Photos.editPrimary}
                style={{ marginTop: 16 }}
                text="Editar descrição"
                onPress={() => setDescEditEnabled(true)}
              />
            )}
            <SubGroupsInfo
              subGroups={subGroupsResult}
              onClickItem={(item) => {
                navigation.dispatch(
                  StackActions.push({
                    routeName: Routes.GROUP_CHAT,
                    params: {
                      groupId: item.id,
                      parentId: groupId,
                      groupData: { name: item.name },
                      name: item.name,
                    },
                  }),
                );
              }}
            />
            <RenderActions />
          </ScrollView>
        </SafeAreaView>
      )}
      <DescriptionEditModal
        visible={descEditEnabled}
        description={description}
        onCancel={() => setDescEditEnabled(false)}
        onConfirm={(desc) => {
          setDescEditEnabled(false);
          updateDescByCategory(desc).catch(() => {
            setDescEditEnabled(false);
          });
        }}
      />
    </>
  );
};

GroupInfo.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default GroupInfo;
