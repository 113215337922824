import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  KeyboardAvoidingView,
  Platform,
  View
} from 'react-native';
import Constants from 'expo-constants';
import SendMessage from '../molecules/SendMessage';
import MessageList from './MessageList';

const Chat = ({
  navigation,
  messages,
  onOpenChat,
  sendMessage,
  sendImage,
  canSendMessage,
  canSendImage,
  groupId,
  parentId,
  participant,
  isPrivateMessage,
  isAdmin,
  headerHeight,
  customButtonHeight
}) => {
  const [message, setMessage] = useState('');
  const [messageButtonHeight, setMessageButtonHeight] = useState(null);

  useEffect(() => {
    onOpenChat();
  }, [messages]);

  const keyboardViewProps = Platform.OS === 'ios' || Platform.OS === 'web'
    ? { behavior: 'padding', style: { flex: 1 }, keyboardVerticalOffset: Constants.statusBarHeight }
    : { style: { flex: 1 } };

  return (
    <KeyboardAvoidingView
      {...keyboardViewProps}
    >
      <MessageList
        isAdmin={isAdmin}
        data={messages}
        groupId={groupId}
        parentId={parentId}
        navigation={navigation}
        isParticipant={participant}
        isPrivateMessage={isPrivateMessage}
        topLimit={headerHeight}
        bottomLimit={participant || isPrivateMessage ? messageButtonHeight : customButtonHeight}
      />
      { canSendMessage && (
         <View onLayout={(event) => {
          const { height} = event.nativeEvent.layout;
          setMessageButtonHeight(height);
        }}>
        <SendMessage
          placeholder="Digite uma mensagem"
          onChangeText={(text) => setMessage(text)}
          message={message}
          canSendImage={canSendImage}
          onPress={() => {
            if (!!message.trim()) { 
              sendMessage(message);
              setMessage('');
            }
          }}
          sendImage={sendImage}
        />
        </View>
      )}
    </KeyboardAvoidingView>
  );
};

Chat.propTypes = {
  navigation: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  onOpenChat: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  sendImage: PropTypes.func,
  canSendMessage: PropTypes.bool,
  canSendImage: PropTypes.bool,
  headerHeight: PropTypes.number,
  customButtonHeight: PropTypes.number,
  // FIXME parameters below should not be here,
  //  refactor required in MessageList.js
  groupId: PropTypes.string,
  parentId: PropTypes.string,
  participant: PropTypes.bool,
  isPrivateMessage: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

Chat.defaultProps = {
  sendImage: undefined,
  canSendMessage: true,
  canSendImage: true,
  groupId: undefined,
  parentId: undefined,
  participant: false,
  isPrivateMessage: false,
  isAdmin: false,
  headerHeight: 0,
  customButtonHeight: 0
};

export default React.memo(Chat);
