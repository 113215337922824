import React, { useState } from 'react';
import {
  ScrollView,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
import Constants from 'expo-constants';
import PropTypes from 'prop-types';
import { StackActions } from 'react-navigation';
import { createEvent } from 'middleware/src/database/events';
import Spacer from '../atoms/Spacer';
import Input from '../atoms/Input';
import Title from '../atoms/Title';
import Routes from '../routes/Routes';
import DateHelper from '../../utils/DateHelper';
import AbstractViewForActions from '../molecules/AbstractViewForActions';
import DateTimeView from '../molecules/DateTimeView';
import ButtonColored from '../molecules/ButtonColored';
import alert from '../../utils/Alert'

const EventCreator = ({ navigation }) => {
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [mode, setMode] = useState('date');
  const [clickedField, setClickedField] = useState('');

  const [creationInProgress, setCreationInProgress] = useState(false);
  const [nameError, setNameError] = useState('');
  const [descError, setDescError] = useState('');
  const [initialDateError, setInitialDateError] = useState('');
  const [finalDateError, setFinalDateError] = useState('');

  const finalDateDefault = () => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
  };

  const [eventData, setEventData] = useState({
    placeId: navigation.getParam('placeId'),
    placeName: navigation.getParam('placeName'),
    business: navigation.getParam('business'),
    initialDate: new Date(),
    finalDate: finalDateDefault(),
  });

  const clickedFieldEvents = {
    initialDate: 'initialDate',
    initialHour: 'initialHour',
    finalDate: 'finalDate',
    finalHour: 'finalHour',
  };

  const setChangeForDates = (date) => {
    if (date) {
      if (clickedField === clickedFieldEvents.initialDate) {
        eventData.initialDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
      } else if (clickedField === clickedFieldEvents.initialHour) {
        eventData.initialDate.setHours(date.getHours(), date.getMinutes(), 0, 0);
      } else if (clickedField === clickedFieldEvents.finalDate) {
        eventData.finalDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
      } else if (clickedField === clickedFieldEvents.finalHour) {
        eventData.finalDate.setHours(date.getHours(), date.getMinutes(), 0, 0);
      }
    }
    setEventData({
      ...eventData,
      [clickedField]: date || eventData[clickedField],
    });
  };

  const showDatePicker = (selectedField, selectedMode) => {
    setClickedField(selectedField);
    setMode(selectedMode);
    setShowDateTimePicker(true);
  };

  const defaultValueForDatePicker = () => (
    clickedField === clickedFieldEvents.initialDate
    || clickedField === clickedFieldEvents.initialHour
      ? eventData.initialDate || new Date()
      : eventData.finalDate || new Date());

  const isValidInitialDate = ({
    initialDate,
    finalDate,
  }) => !(initialDate > finalDate);

  const startCreateEvent = async () => {
    if (nameError.length !== 0
      || descError.length !== 0
      || initialDateError.length !== 0
      || finalDateError.length !== 0) {
      return Promise.reject();
    }
    if (!eventData.title || eventData.title.length === 0) {
      setNameError('Nome do evento é obrigatório');
      return Promise.reject();
    }
    if (!eventData.description || eventData.description.length === 0) {
      setDescError('Descrição do evento é obrigatória');
      return Promise.reject();
    }
    if (!eventData.initialDate || !eventData.initialHour) {
      setInitialDateError('Informe a data de início do evento');
      return Promise.reject();
    }
    if (!eventData.finalDate || !eventData.finalHour) {
      setFinalDateError('Informe a data de fim do evento');
      return Promise.reject();
    }
    delete eventData.finalHour;
    delete eventData.initialHour;

    return createEvent(eventData);
  };

  const RenderDateEdit = () => (
    <>
      <Spacer size={20} />
      <Title
        bold
        primary
      >
        Início do evento
      </Title>
      <Spacer size={12} />
      <AbstractViewForActions
        error={initialDateError}
        value={`Data inicial: ${DateHelper.getDayMonthAndYear(eventData.initialDate)}`}
        action={() => showDatePicker(clickedFieldEvents.initialDate)}
      />
      <Spacer size={12} />
      <AbstractViewForActions
        error={initialDateError}
        value={eventData.initialHour
          ? `Hora inicial: ${DateHelper.getHourAndMinute(eventData.initialHour)}`
          : 'Hora inicial: 00:00'}
        action={() => showDatePicker(clickedFieldEvents.initialHour, 'time')}
      />
      <Spacer size={20} />
      <Title
        bold
        primary
      >
        Fim do evento
      </Title>
      <Spacer size={12} />
      <AbstractViewForActions
        error={finalDateError}
        value={`Data final: ${DateHelper.getDayMonthAndYear(eventData.finalDate)}`}
        action={() => showDatePicker(clickedFieldEvents.finalDate)}
      />
      <Spacer size={12} />
      <AbstractViewForActions
        error={finalDateError}
        value={eventData.finalHour
          ? `Hora final: ${DateHelper.getHourAndMinute(eventData.finalHour)}`
          : 'Hora final: 00:00'}
        action={() => showDatePicker(clickedFieldEvents.finalHour, 'time')}
      />
      <Spacer size={20} />
      <DateTimeView
        visible={showDateTimePicker}
        value={defaultValueForDatePicker()}
        dateMode={mode}
        onDateChange={(date) => {
          setChangeForDates(date);
          if (!isValidInitialDate(eventData)) {
            setInitialDateError('Data inicial maior que a data final');
          } else {
            setInitialDateError('');
          }
          setFinalDateError('');
        }}
        updateViewStatus={(status) => setShowDateTimePicker(status)}
      />
    </>
  );

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={Constants.statusBarHeight}
    >
      <ScrollView style={{ flex: 1, paddingHorizontal: 32 }}>
        <Spacer size={32} />
        <Input
          placeHolder="Nome do evento"
          errorText={nameError}
          maxLength={100}
          value={eventData.title}
          onChangeText={(text) => {
            setEventData({ ...eventData, title: text });
            if (text.length < 3) {
              setNameError('O nome deve conter no mínimo 3 caracteres');
            } else {
              setNameError('');
            }
          }}
        />
        <Spacer size={12} />
        <Input
          placeHolder="Descrição do evento"
          errorText={descError}
          multiline
          numberOfLines={10}
          value={eventData.description}
          onChangeText={(text) => {
            setEventData({ ...eventData, description: text });
            if (text.length !== 0) setDescError('');
          }}
        />
        <RenderDateEdit />
        <Spacer size={12} />
        <ButtonColored
          loading={creationInProgress}
          text="Finalizar"
          onPress={() => {
            setCreationInProgress(true);
            startCreateEvent().then(({ parentId, groupData }) => {
              setCreationInProgress(false);
              navigation.dispatch(
                StackActions.replace({
                  routeName: Routes.GROUP_CHAT,
                  params: {
                    parentId,
                    groupData,
                  },
                }),
              );
            }).catch(() => {
              setCreationInProgress(false);
              alert('Aviso', 'Não foi possível criar o evento');
            });
          }}
        />
        <Spacer size={40} />
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

EventCreator.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default EventCreator;
