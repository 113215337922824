import React, { useCallback, useEffect, useState } from 'react';
import {
  KeyboardAvoidingView,
  View,
  Platform,
} from 'react-native';
import alert from '../../utils/Alert'
import PropTypes from 'prop-types';
import Constants from 'expo-constants';
import {
  checkUsernameExists,
  currentUserExists,
  updateCurrentUser,
} from 'middleware/src/database/users';
import Title from '../atoms/Title';
import DefaultButton from '../atoms/DefaultButton';
import Hello from '../../../assets/images/hello.png';
import { fireAuth } from '../../utils/FirebaseSettings';
import Spacer from '../atoms/Spacer';
import Routes from '../routes/Routes';
import Regex from '../../utils/Regex';
import ImageAdaptiveDimensions from '../organisms/ImageAdaptiveDimensions';
import Input from '../atoms/Input';

const GreetingPage = ({ navigation }) => {
  const [name, setName] = useState('');
  const [username, setUserName] = useState('');
  const [nameError, setNameError] = useState(undefined);
  const [usernameError, setUsernameError] = useState(undefined);
  const checkCurrentUserExists = useCallback(async () => currentUserExists(), []);

  useEffect(() => {
    setTimeout(() => {
      checkCurrentUserExists().then((exist) => {
        if (exist) {
          navigation.navigate(Routes.GREETING_TEXT);
        }
      });
    }, 1000);
    return () => {};
  }, [checkCurrentUserExists, navigation]);

  const updateAuthProvider = async () => {
    if (name.length === 0) {
      setNameError('Nome é obrigatório');
      return Promise.reject();
    }
    if (username.length === 0) {
      setUsernameError('Nome de usuário é obrigatório');
      return Promise.reject();
    }
    if (usernameError || nameError) return Promise.reject();

    const usernameExist = await checkUsernameExists(username);
    if (usernameExist) {
      setUsernameError('Nome de usuário indisponível');
      return Promise.reject();
    }
    return fireAuth.currentUser.updateProfile({ displayName: name.trim() });
  };

  const alertWhenAnyErrors = () => {
    alert('Aviso', 'Não foi possível continuar o cadastro');
  };

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={Constants.statusBarHeight}
    >
      <View style={{ flex: 2, padding: 20, alignItems: 'center' }}>
        <Title bold>Olá</Title>
        {
          Platform.OS == "web" ?
            <ImageAdaptiveDimensions
              source={Hello}
              style={{
                flex: 1,
                height: 30,
                width: '100vh',
                margin: 8,
              }}
            />
            :
            <ImageAdaptiveDimensions
              source={Hello}
              style={{ flex: 1 }}
            />
        }
      </View>
      <View style={{ paddingHorizontal: 20 }}>
        <Spacer size={20} />
        <Input
          placeHolder="Como gostaria de ser chamado?"
          errorText={nameError}
          maxLength={30}
          onChangeText={(text) => {
            if (text.length < 3) {
              setNameError('O nome deve conter no mínimo 3 caracteres');
            } else if (!Regex.NAME.test(text)) {
              setNameError('Nome digitado é inválido, verifique se não contém números');
            } else {
              setNameError(null);
            }
            setName(text);
          }}
        />
        <Spacer size={20} />
        <Input
          placeHolder="Escolha um nome de usuário"
          errorText={usernameError}
          autoCapitalize="none"
          maxLength={20}
          autoComplete="false"
          keyboardType="visible-password"
          formatText={(text) => text.replace(Regex.USERNAME_MASK, '')}
          onChangeText={(text) => {
            if (text.length < 3) {
              setUsernameError('O nome de usuário deve conter no mínimo 3 caracteres');
            } else if (!Regex.USERNAME.test(text)) {
              setUsernameError('Os nomes de usuário só podem usar letras, números, sublinhados e pontos. ex: local.chat');
            } else {
              setUsernameError(null);
            }
            setUserName(text);
          }}
        />
        <Spacer size={10} />
      </View>
      <DefaultButton
        lite
        title="Continuar"
        style={{
          backgroundColor: '#FFC825',
          borderWidth: 0,
          borderRadius: 10,
          padding: 20,
          margin: 20,
          elevation: 1,
        }}
        onPress={() => updateAuthProvider().then(() => {
          updateCurrentUser({ username: username.trim().toLowerCase(), name: name.trim() })
            .then(() => navigation.navigate(Routes.GREETING_TEXT))
            .catch(() => alertWhenAnyErrors());
        }).catch(() => alertWhenAnyErrors())}
      />
    </KeyboardAvoidingView>
  );
};

GreetingPage.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default GreetingPage;
