import React, { useEffect, useState } from 'react';
import { SafeAreaView, View, ScrollView } from 'react-native';
import { usersCollection } from 'middleware/src/database/collections';
import Title from '../atoms/Title';
import DefaultButton from '../atoms/DefaultButton';
import { getAllGoogleCodes } from '../../utils/CollectionUtils';
import AppCard from '../atoms/AppCard';
import ProfileData from '../molecules/ProfileData';
import CheckItemsModal from '../organisms/CheckItemsModal';
import { fireAuth } from '../../utils/FirebaseSettings';

const InterestsScreen = () => {
  const [interests, setInterests] = useState(undefined);
  const [googleCodes, setGoogleCodes] = useState([]);
  const [interestsModal, setOpenInterestsModal] = useState(false);
  const { uid } = fireAuth.currentUser;

  useEffect(() => {
    usersCollection().doc(uid).get().then((doc) => {
      const userInterests = doc.data().interests;
      if (userInterests && userInterests.length > 0) {
        setInterests(userInterests);
      } else {
        setInterests([]);
      }
    });

    getAllGoogleCodes().then((result) => { setGoogleCodes(result); });
  }, [uid]);

  const updateData = async (data) => {
    setInterests(data);
    await usersCollection().doc(uid).update({
      interests: data,
    });
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView style={{ flex: 1, paddingHorizontal: 20, paddingTop: 30 }}>
        <View>
          <Title primary bold lite>Selecione seus locais de interesse</Title>
        </View>
        {
          interests && interests.length > 0 && (
            <AppCard
              style={{
                paddingHorizontal: 15,
                paddingVertical: 10,
                marginVertical: 25,
                marginHorizontal: 5,
              }}
            >
              <ProfileData data={interests} />
            </AppCard>
          )
        }
        <View style={{ width: 150, marginTop: 10, marginBottom: 100 }}>
          <DefaultButton
            title="Selecionar"
            onPress={() => setOpenInterestsModal(true)}
          />
        </View>
        {
          !!googleCodes && googleCodes.length > 0 && interests && (
            <CheckItemsModal
              visible={interestsModal}
              onRequestClose={() => setOpenInterestsModal(false)}
              sendPayload={updateData}
              data={googleCodes}
              initialSelected={interests}
            />
          )
        }
      </ScrollView>
    </SafeAreaView>
  );
};

export default InterestsScreen;
