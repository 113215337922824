import React, { useEffect, useState } from 'react';
import {
  View, SafeAreaView, ScrollView,
} from 'react-native';
import PropTypes from 'prop-types';
import { usersCollection } from 'middleware/src/database/collections';
import DefaultButton from '../atoms/DefaultButton';
import Title from '../atoms/Title';
import { getAllGoogleCodes } from '../../utils/CollectionUtils';
import CheckItemsModal from '../organisms/CheckItemsModal';
import AppCard from '../atoms/AppCard';
import ProfileData from '../molecules/ProfileData';
import { fireAuth } from '../../utils/FirebaseSettings';
import Routes from '../routes/Routes';
import alert from '../../utils/Alert'

const CategorySelection = ({ navigation }) => {
  const [interests, setInterests] = useState([]);
  const [googleCodes, setGoogleCodes] = useState([]);
  const [interestsModal, setOpenInterestsModal] = useState(false);
  const { uid } = fireAuth.currentUser;

  useEffect(() => {
    getAllGoogleCodes().then((result) => { setGoogleCodes(result); });
  }, []);

  const goToNextStep = async () => {
    await usersCollection().doc(uid).update({
      interests,
    }).then(() => navigation.navigate(Routes.KNOWING_THE_USER))
      .catch(() => alert('Aviso',
        'Problema ao registrar interesses, verifique sua conexão à internet'));
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView
        contentContainerStyle={{ marginTop: 30, paddingHorizontal: 30, marginVertical: 20 }}
        style={{ flex: 1 }}
      >
        <View>
          <Title primary bold lite>Selecione seus locais de interesse:</Title>
        </View>
        {
          interests.length > 0 && (
            <AppCard
              style={{
                paddingHorizontal: 15,
                paddingVertical: 10,
                marginVertical: 25,
              }}
            >
              <ProfileData data={interests} />
            </AppCard>
          )
        }
        <View style={{ width: 150, marginTop: 10 }}>
          <DefaultButton
            title="Selecionar"
            onPress={() => setOpenInterestsModal(true)}
          />
        </View>
        {
          !!googleCodes && googleCodes.length > 0 && (
            <CheckItemsModal
              visible={interestsModal}
              onRequestClose={() => setOpenInterestsModal(false)}
              sendPayload={(data) => setInterests(data)}
              data={googleCodes}
            />
          )
        }
        <View style={{ paddingBottom: 20, marginTop: 50 }}>
          <DefaultButton
            lite
            title="Continuar"
            style={{
              backgroundColor: '#FFC825',
              borderWidth: 0,
              borderRadius: 10,
              padding: 20,
              marginBottom: 40,
              elevation: 1,
            }}
            onPress={goToNextStep}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

CategorySelection.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default CategorySelection;
