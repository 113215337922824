import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { Image } from 'react-native';
import Checkbox from '../atoms/Checkbox';
import StyledText from '../atoms/StyledText';
import Spacer from '../atoms/Spacer';

const CategoryButton = styled.TouchableOpacity`
    flex-direction: row;
    padding: 5px 0;
    margin: 3px 0;
`;

const CategoryOptionItem = ({
  category, Img, onPress, checked,
}) => {
  const Style = {};

  if (checked) {
    Style.font = 'bold';
  } else {
    Style.font = 'normal';
  }

  return (
    <CategoryButton Img={Img} onPress={onPress}>
      <Checkbox checked={checked} />
      <Spacer vertical size={30} />
      { Img && (
      <Image style={{ height: 20, width: 20, marginRight: 20 }} />
      )}
      <StyledText style={{ fontWeight: Style.font }}>{category}</StyledText>
    </CategoryButton>
  );
};
CategoryOptionItem.propTypes = {
  category: PropTypes.string.isRequired,
  Img: PropTypes.any,
  onPress: PropTypes.func,
  checked: PropTypes.bool,
};
CategoryOptionItem.defaultProps = {
  Img: null,
  onPress: null,
  checked: false,
};

export default CategoryOptionItem;
