import React from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import Loading from '../atoms/Loading';
import FloatActionButton from '../atoms/FloatActionButton';
import Photos from '../../state/constants/Galery';

const View = styled.View`
    background-color: #f1f1f1;
    flex-direction: row; 
    align-content: center;
    align-items: center; 
    padding-left: 10px;
    padding-right: 10px;
    padding: 10px;
`;

const Image = styled.Image`
    width: 35px;
    height: 35px;
`;

const Text = styled.Text`
    font-size: 15;
    flex: 1;
    padding-left: 10px;
`;

const ProfileEdit = ({
  isLoggedUser,
  saving,
  close,
  saveChanges,
  text,
  startEdit,
  editing,
}) => {
  if (isLoggedUser) {
    return (
      editing
        ? (
          <View>
            <TouchableOpacity onPress={() => close()}>
              <Image source={Photos.closeEdit} />
            </TouchableOpacity>
            <Text>{text}</Text>
            <TouchableOpacity onPress={() => saveChanges()}>
              {saving ? (
                <Loading style={{ marginBottom: 30, marginRight: 40 }} />
              ) : <Image source={Photos.checkEdit} />}
            </TouchableOpacity>
          </View>
        ) : (
          <FloatActionButton
            right={`${3}%`}
            bottom={`${5}%`}
            source={Photos.edit}
            onPress={() => {
              startEdit();
            }}
          />
        )
    );
  }
  return <></>;
};

ProfileEdit.propTypes = {
  saving: PropTypes.bool.isRequired,
  saveChanges: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  startEdit: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  isLoggedUser: PropTypes.bool.isRequired,
};

export default React.memo(ProfileEdit);
