import { usersCollection } from 'middleware/src/database/collections';
import { fireStore } from './FirebaseSettings';

const parseItemsDocuments = (documents) => {
  const parsedDocuments = documents.map((doc) => ({
    id: doc.id,
    data: doc.data().name,
    name: doc.data().name,
  }));
  parsedDocuments.sort((a, b) => (a.data > b.data) ? 1 : -1);
  return parsedDocuments;
};

export const getUserData = async (currentUser) => {
  const userInstance = await usersCollection()
    .doc(currentUser.uid)
    .get();
  if (userInstance && userInstance.data()) {
    return userInstance.data();
  }
  return undefined;
};

export const getAllProfessions = async () => {
  const documents = await fireStore.collection('professions').get();
  return parseItemsDocuments(documents.docs);
};

export const getAllHobbiesAndSkills = async () => {
  const documents = await fireStore.collection('hobbiesAndSkills').get();
  return parseItemsDocuments(documents.docs);
};

export const getAllGoogleCodes = async () => {
  const documents = await fireStore.collection('googleCodes').get();
  const parsedDocuments = documents.docs.map((doc) => ({
    ...doc.data(),
    data: doc.data().name,
  }));
  parsedDocuments.sort((a, b) => (a.data > b.data) ? 1 : -1);
  return parsedDocuments;
};
