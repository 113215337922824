/** *
 * Location in meters
 */
const LocationSearchRadius = {
  SMALL: '50',
  MEDIUM: '500',
  BIG: '1000',
};

export default LocationSearchRadius;
