import React from 'react';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import MainTheme from '../../state/constants/Colors';
import BasicInput from './BasicInput';

const InputWrap = styled.View`
  border-radius: 10px; 
  background: #fff;
  border-width: 0.5px;
  border-color: ${MainTheme.primary};
  border-radius: 30;
  justify-content: space-around;
  padding: 10px;
  margin: 10px;
`;

const MessageInput = ({
  style, small, ...rest
}) => (
  <InputWrap style={style}>
    <BasicInput small={small} {...rest} />
  </InputWrap>
);
MessageInput.propTypes = {
  style: PropTypes.any,
  small: PropTypes.number,
};
MessageInput.defaultProps = {
  style: null,
  small: false,
};

export default MessageInput;
