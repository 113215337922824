import React from 'react';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import StyledText from '../atoms/StyledText';
import MainTheme from '../../state/constants/Colors';
import Loading from '../atoms/Loading';
import Row from '../atoms/Row';

const Img = styled.Image`
    height: 25;
    width: 25;
`;

const ButtonContainer = styled.TouchableOpacity`
    align-items: center;
    width: ${(props) => (props.customWidth ? props.customWidth : '100%')};
    padding: ${(props) => (props.customPadding ? props.customPadding : '10px')};
    margin: ${(props) => (props.customMargin ? props.customMargin : '0px')};
    background:  ${(props) => (props.colorPrimaryInBackground ? MainTheme.primary : '#ffffff')};
    elevation: ${(props) => (props.customElevation ? props.customElevation : '2px')};
    border-radius: 10;
    border-width: ${(props) => (props.border ? '1' : '0')};
    border-color: ${(props) => (props.border ? MainTheme.primary : MainTheme.secondary)};
`;

const Button = ({
  text,
  source,
  onPress,
  style,
  border,
  colorPrimaryInBackground,
  customMargin,
  customWidth,
  customPadding,
  customElevation,
  loading,
  ...rest
}) => (
  <>
    { loading
      ? (
        <View style={{ margin: 16, alignItems: 'center' }}>
          <Loading />
        </View>
      )
      : (
        <ButtonContainer
          colorPrimaryInBackground={colorPrimaryInBackground}
          onPress={onPress}
          customWidth={customWidth}
          customPadding={customPadding}
          customMargin={customMargin}
          customElevation={customElevation}
          border={border}
          style={style}
        >
          { source ? (
            <Row style={{ alignItems: 'center' }}>
              <Img source={source} />
              <StyledText style={{ marginLeft: 12 }} {...rest}>
                {text}
              </StyledText>
            </Row>
          ) : (
            <StyledText {...rest}>
              {text}
            </StyledText>
          )}
        </ButtonContainer>
      )}
  </>
);

Button.propTypes = {
  customMargin: PropTypes.string,
  customWidth: PropTypes.string,
  customPadding: PropTypes.string,
  customElevation: PropTypes.string,
  text: PropTypes.string,
  source: PropTypes.any,
  onPress: PropTypes.func,
  style: PropTypes.any,
  colorPrimaryInBackground: PropTypes.bool,
  loading: PropTypes.bool,
  border: PropTypes.bool,
};

Button.defaultProps = {
  customMargin: '',
  customWidth: '',
  customPadding: '',
  customElevation: '',
  text: '',
  source: null,
  onPress: null,
  style: null,
  colorPrimaryInBackground: false,
  loading: false,
  border: false,
};

export default React.memo(Button);
