import { Alert, Platform } from 'react-native'

const alertPolyfill = (title, description, options = []) => {
    const result = window.confirm([title, description].filter(Boolean).join('\n'));

    if (result) {
        const confirmOption = options.find(({ style }) => style !== 'cancel');
        if (confirmOption && typeof confirmOption.onPress === 'function') {
            confirmOption.onPress();
        }
    } else {
        const cancelOption = options.find(({ style }) => style === 'cancel');
        if (cancelOption && typeof cancelOption.onPress === 'function') {
            cancelOption.onPress();
        }
    }
};

const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert

export default alert
