import React from 'react';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import { Dimensions, Image, Platform } from 'react-native';
import MainTheme from '../../state/constants/Colors';
import StyledText from './StyledText';

const Dimension = Dimensions.get('window').width;

const ButtonContainer = styled.TouchableOpacity`
    background: ${MainTheme.primary};
    border-radius: 100;
    height: ${Platform.OS === 'web' ? '15vw' : Dimension / 6 };
    width: ${Platform.OS === 'web' ? '15vw' : Dimension / 6 };
    max-height: 100px;
    max-width: 100px;
    min-height: 50px;
    min-width: 50px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    elevation: 5;
    justify-content: center;
    align-items: center;
`;

const Label = styled.View`
    background: #ebebeb;
    border-radius: 10;
    padding: 5px 10px;
    margin-right: 15px;
`;

const Wrap = styled.View`
   bottom: ${(props) => props.bottom};
   right: ${(props) => props.right};
   position: absolute;
   flex-direction: row;
   justify-content: center;
   align-items: center;
`;

const FloatActionButton = ({
  source, bottom, right, label, onPress, style,
}) => (
  <Wrap style={style} right={right} bottom={bottom}>
    {label
        && (
        <Label>
          <StyledText>{label}</StyledText>
        </Label>
        )}
    <ButtonContainer onPress={onPress}>
      <Image style={{ height: 30, width: 30 }} source={source} />
    </ButtonContainer>
  </Wrap>
);
FloatActionButton.propTypes = {
  source: PropTypes.any.isRequired,
  bottom: PropTypes.any,
  right: PropTypes.any,
  label: PropTypes.any,
  onPress: PropTypes.func,
  style: PropTypes.any,
};
FloatActionButton.defaultProps = {
  bottom: 0,
  right: 0,
  label: false,
  onPress: null,
  style: null,
};

export default FloatActionButton;
