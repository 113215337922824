import React, {
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  SafeAreaView,
  FlatList,
  Platform,
} from 'react-native';
import DateHelper from '../../utils/DateHelper';
import Loading from '../atoms/Loading';
import ChatHistoryItem from '../molecules/ChatHistoryItem';
import { fireAuth } from '../../utils/FirebaseSettings';
import Photos from '../../state/constants/Galery';
import NotFound from '../molecules/NotFound';
import { RefreshControl } from '../../utils/refresh'

const ChatHistory = ({
  loading,
  navigation,
  messages,
  action,
  onBackPressed,
  triggerCurrentMessages,
}) => {
  const { currentUser } = fireAuth;

  useEffect(() => {
    if (currentUser) {
      triggerCurrentMessages(currentUser.uid);
      navigation.addListener('action', () => {
        onBackPressed(currentUser.uid);
      });
    }
  }, [currentUser]);

  const onRefresh = useCallback(() => {
    triggerCurrentMessages(currentUser.uid);
  }, [currentUser]);

  const getHandledMessage = useMemo(() => (lastMessage) => {
    if (lastMessage) {
      if (lastMessage.message) {
        return lastMessage.message;
      }
      if (lastMessage.imageUrl) {
        return 'Enviou uma foto';
      }
    }
    return 'Entre e digite uma mensagem';
  }, []);

  const currentUserSentMessage = useMemo(() => ({ info }) => {
    const { lastMessage } = info;
    return lastMessage && currentUser && (
      currentUser.uid === lastMessage.userId
    );
  }, [currentUser]);

  const unreadMessage = useMemo(() => ({ lastOpenedAt, info }) => {
    const lastOpenedOnDate = !!lastOpenedAt && lastOpenedAt.toDate();
    const lastMessageDate = !!info.lastMessage && info.lastMessage.createdAt.toDate();
    const validDates = !!lastOpenedOnDate && !!lastMessageDate;

    return !lastOpenedAt || (validDates && (
      lastOpenedOnDate.getTime() < lastMessageDate.getTime())
    );
  }, []);

  const renderChatItem = (item, info) => {
    const { lastMessage } = info;
    const title = info.name || item.username;
    return (
      <ChatHistoryItem
        title={title}
        message={getHandledMessage(lastMessage)}
        unreadMessage={!currentUserSentMessage(item) && unreadMessage(item)}
        hour={
          lastMessage
            ? DateHelper.getHourAndMinute(lastMessage.createdAt.toDate())
            : DateHelper.getHourAndMinute(item.createdAt.toDate())
        }
        username={lastMessage ? lastMessage.username : 'local.chat'}
        onPress={() => title && action(item)}
      />
    );
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {
        loading ? <Loading desc="Buscando histórico de mensagens..." /> : (
          <FlatList
            contentContainerStyle={{
              padding: 10,
              flexGrow: 1,
            }}
            refreshControl={(
              <RefreshControl
                refreshing={loading}
                onRefresh={onRefresh}
              />
            )}
            ListEmptyComponent={(
              <NotFound
                title="Não possui histórico de mensagens"
                desc="Tente atualizar deslizando para baixo!"
                img={Photos.personDontUnderstand}
              />
            )}
            data={messages}
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => (item.info && renderChatItem(item, item.info))}
            removeClippedSubviews={Platform.OS === 'android'}
            initialNumToRender={5}
            maxToRenderPerBatch={5}
            windowSize={10}
          />
        )
      }
    </SafeAreaView>
  );
};

ChatHistory.propTypes = {
  loading: PropTypes.bool.isRequired,
  navigation: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  action: PropTypes.func.isRequired,
  onBackPressed: PropTypes.func.isRequired,
  triggerCurrentMessages: PropTypes.func.isRequired,
};

export default React.memo(ChatHistory);
