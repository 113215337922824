import * as SplashScreen from 'expo-splash-screen';
import { createUserProfile, getUserProfile } from 'middleware/src/database/users';
import { useCallback, useEffect } from 'react';
import Routes from '../../components/routes/Routes';
import useRegistrationOngoing from './useRegistrationOngoing';

const useAuthStepHandler = (
  navigation,
  authenticated,
  currentUser,
) => {
  const {
    ongoing: registrationOngoing,
    saveOngoing,
  } = useRegistrationOngoing();

  const navigateToMainStack = async () => {
    navigation.navigate(Routes.MAIN_STACK_NAVIGATOR);
    return Promise.resolve();
  };

  const navigateToGreetingStack = async () => {
    navigation.navigate(Routes.GREETING_STACK);
    return SplashScreen.hideAsync();
  };

  const navigateToSavedStep = async () => {
    if (registrationOngoing === true) {
      return navigateToGreetingStack();
    }
    if (registrationOngoing === false) {
      return navigateToMainStack();
    }
    return SplashScreen.hideAsync();
  };

  const createUserIfNeededAndNavigateToAuthStep = async (
    { uid, email },
  ) => getUserProfile(uid).then((userData) => {
    if (!userData) {
      return createUserProfile(uid, email).then(
        () => saveOngoing(true).then(
          () => navigateToGreetingStack(),
        ),
      );
    }
    if (userData.newUser) {
      return saveOngoing(true).then(
        () => navigateToGreetingStack(),
      );
    }
    return saveOngoing(false).then(
      () => navigateToMainStack(),
    );
  });

  const navigationWhenOfflineOrLogout = async () => {
    if (authenticated === false) {
      return navigateToSavedStep();
    }
    return Promise.reject();
  };

  const navigateToAuthFlowStep = useCallback(async () => {
    if (authenticated === undefined) {
      return Promise.reject();
    }
    if (authenticated) {
      if (registrationOngoing === undefined || registrationOngoing === null) {
        return createUserIfNeededAndNavigateToAuthStep(currentUser)
      }
      return navigateToSavedStep();
    }
    return navigationWhenOfflineOrLogout();
  }, [authenticated, registrationOngoing]);

  useEffect(() => {
    navigateToAuthFlowStep().then();
  }, [navigateToAuthFlowStep]);

  return { navigateToAuthFlowStep };
};

export default useAuthStepHandler;
