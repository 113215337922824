import { Image, View } from 'react-native';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ImageAdaptiveDimensions = ({ source, ...rest }) => {
  const [imgHeight, setImgHeight] = useState(0);
  const findDimensions = (layout) => (setImgHeight(layout.height));

  return (
    <View
      onLayout={(event) => {
        findDimensions(event.nativeEvent.layout);
      }}
      {...rest}
    >
      <Image source={source} resizeMode="contain" style={{ height: imgHeight }} />
    </View>
  );
};

ImageAdaptiveDimensions.propTypes = {
  source: PropTypes.number.isRequired,
};

export default React.memo(ImageAdaptiveDimensions);
