import React from 'react';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import MainTheme from '../../state/constants/Colors';
import StyledText from '../atoms/StyledText';

const BadgeContainer = styled.View`
  background-color: ${MainTheme.primary};
  padding: 3px 10px;
  border-radius: 12;
`;

const Badge = ({ text, enabled }) => enabled && (
<BadgeContainer>
  <StyledText
    white
    bold
    lite
    style={{
      textTransform: 'uppercase',
      fontSize: 13,
    }}
  >
    {text}
  </StyledText>
</BadgeContainer>
);

Badge.propTypes = {
  text: PropTypes.string.isRequired,
};

export default React.memo(Badge);
