import { useEffect, useMemo, useState } from 'react';
import { GroupType } from 'middleware/src/states/constants/groups';

const useGroupDescriptionHandler = (groupData) => {
  const [description, setDescription] = useState(undefined);

  const descriptionByGroupCategory = useMemo(() => () => {
    switch (groupData.category) {
      case GroupType.EVENT:
        return groupData.eventInfo.desc;
      case GroupType.SUBGROUP:
        return groupData.description || undefined;
      default:
        return undefined;
    }
  }, [groupData.category, groupData.description, groupData.eventInfo]);

  useEffect(() => {
    setDescription(descriptionByGroupCategory());
  }, [descriptionByGroupCategory, groupData]);

  return { description };
};

export default useGroupDescriptionHandler;
