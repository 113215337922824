const mainTheme = {
  contrast: '#a8c686',
  darkContrast: '#023c40',
  light: '#e3ecf2',
  base: '#669bbc',
  dark: '#29335c',
  primary: '#FFC825',
  secondary: '#757575',
  link: '#039be5',
  border: '#D8D8D8',
  error: '#C00',
  webLink: '#0000EE',
  lightPrimary: '#feebb2',
};

export default mainTheme;
