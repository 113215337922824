import React, { useState, useEffect } from 'react';
import { View, SafeAreaView, TouchableOpacity } from 'react-native';
import { usersCollection } from 'middleware/src/database/collections';
import Checkbox from '../atoms/Checkbox';
import Title from '../atoms/Title';
import StyledText from '../atoms/StyledText';
import Spacer from '../atoms/Spacer';
import Row from '../atoms/Row';
import { fireAuth } from '../../utils/FirebaseSettings';

const NotificationsScreen = () => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const { uid } = fireAuth.currentUser;

    usersCollection().doc(uid).get().then((doc) => {
      const { notifications } = doc.data();

      if (notifications && notifications.enabled) {
        setChecked(true);
      } else {
        setChecked(false);
      }
    });
  }, []);

  const updateNotifications = () => {
    const { uid } = fireAuth.currentUser;

    usersCollection().doc(uid).update({
      notifications: { enabled: !checked },
    }).then(() => {
      setChecked(!checked);
    });
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ flex: 3, paddingHorizontal: 30, paddingTop: 30 }}>
        <Title primary bold lite>Configurar as notificações</Title>
        <Spacer size={20} />
        <Row style={{ marginBottom: 30 }} key={2}>
          <TouchableOpacity
            onPress={updateNotifications}
          >
            <Checkbox checked={checked} />
          </TouchableOpacity>
          <Spacer vertical size={20} />
          <StyledText>{checked ? 'Notificações estão habilitadas' : 'Notificações estão desabilitadas'}</StyledText>
        </Row>
      </View>
    </SafeAreaView>
  );
};

export default NotificationsScreen;
