import { RadioButton, TouchableRipple } from 'react-native-paper';
import { Text } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import Row from '../atoms/Row';
import MainTheme from '../../state/constants/Colors';

const DefaultRadioButton = ({ text, checked, onPress }) => (
  <TouchableRipple onPress={() => onPress()}>
    <Row style={{ alignItems: 'center' }}>
      <RadioButton
        onPress={() => onPress()}
        color={MainTheme.primary}
        status={checked ? 'checked' : 'unchecked'}
      />
      <Text>{text}</Text>
    </Row>
  </TouchableRipple>
);

DefaultRadioButton.propTypes = {
  text: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
};

export default DefaultRadioButton;
