import React from 'react';
import PropTypes from 'prop-types';
import Title from '../atoms/Title';
import Spacer from '../atoms/Spacer';

const PlaceInfo = ({ info }) => (
  <>
    { !!info && (
      <>
        <Spacer size={8} />
        <Title
          lite
          style={{ marginVertical: 15 }}
        >
          { info.formatted_address }
        </Title>
        { !!info.formatted_phone_number && (
          <Title
            lite
          >
            { info.formatted_phone_number }
          </Title>
        )}
      </>
    )}
  </>
);

PlaceInfo.propTypes = {
  info: PropTypes.object.isRequired,
};

export default React.memo(PlaceInfo);
