import moment from 'moment';
import 'moment/locale/pt-br';

const LANGUAGE = 'pt-br';

const DATE_PATTERN_FOR_DAY_MONTH_AND_YEAR = 'DD/MM/YYYY';
const DATE_PATTERN_FOR_MONTH_AND_YEAR = 'MM/YYYY';
const DATE_PATTERN_FOR_DAY = 'DD';
const DATE_PATTERN_FOR_YEAR = 'YYYY';
const DATE_PATTERN_FOR_NAME_OF_MONTH = 'MMMM';
const DATE_PATTERN_FOR_HOUR_AND_MINUTE = 'HH:mm';

const YESTERDAY = 'Ontem';
const TODAY = 'Hoje';
const DAY = 'day';

// FIXME review application languages, we have a technical debt
export default class DateHelper {
  static isSameDay(date, diffDate) {
    const currentCreatedAt = moment(date);
    const diffCreatedAt = moment(diffDate);

    if (!currentCreatedAt.isValid() || !diffCreatedAt.isValid()) {
      return false;
    }

    return currentCreatedAt.isSame(diffCreatedAt, DAY);
  }

  static getMonthAndYear(date) {
    return moment(date).format(DATE_PATTERN_FOR_MONTH_AND_YEAR);
  }

  static getDayMonthAndYear(date) {
    return moment(date).format(DATE_PATTERN_FOR_DAY_MONTH_AND_YEAR);
  }

  static getHourAndMinute(date) {
    return moment(date).format(DATE_PATTERN_FOR_HOUR_AND_MINUTE);
  }

  static getFullDateInPtBr(date) {
    const currentDate = moment(date).locale(LANGUAGE);
    const day = currentDate.format(DATE_PATTERN_FOR_DAY);
    const month = currentDate.format(DATE_PATTERN_FOR_NAME_OF_MONTH);
    const year = currentDate.format(DATE_PATTERN_FOR_YEAR);
    return `${day} de ${month} de ${year}`;
  }

  static getStringForDate(date, currentDate) {
    if (date.toDateString() === currentDate.toDateString()) {
      return TODAY;
    }
    const day = date.getDate();
    const currentDay = currentDate.getDate();
    const beforeCurrentDay = day === currentDay - 1 && (
      DateHelper.getMonthAndYear(date) === DateHelper.getMonthAndYear(currentDate)
    );
    return beforeCurrentDay ? YESTERDAY : DateHelper.getFullDateInPtBr(date);
  }
}
