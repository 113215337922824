import React from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  TouchableOpacity,
  View,
  StyleSheet,
  Platform,
} from 'react-native';
import AppCard from '../atoms/AppCard';
import Title from '../atoms/Title';
import Photos from '../../state/constants/Galery';
import MainTheme from '../../state/constants/Colors';
import Row from '../atoms/Row';
import Subtitle from '../atoms/SubTitle';

const styles = StyleSheet.create({
  image: {
    width: 30,
    height: 30,
  },
});

const Participant = ({
  canToTalk,
  enableActionsForAdmin,
  currentUserAdmin,
  itemUserAdmin,
  username,
  name,
  imageUrl,
  professions,
  profileImageVisible,
  startChat,
  openProfile,
  deleteUser,
  setAdmin,
}) => (
  <AppCard style={{ margin: 5 }}>
    <TouchableOpacity onPress={() => openProfile()}>
      <Row>
        <Image
          source={
            (profileImageVisible) ? {
              uri: imageUrl,
            } : Photos.profile
          }
          style={{
            width: 100,
            height: 130,
            borderRadius: 5,
            backgroundColor: MainTheme.lightPrimary,
          }}
        />
        <View style={{
          flex: 1,
          marginTop: 10,
          marginLeft: 10,
          marginRight: 40,
        }}
        >
          <Title
            bold
            primary
            numberOfLines={1}
            style={{
              flexWrap: 'wrap',
              fontSize: 15,
            }}
          >
            {
              (`${name == null ? '' : name.substr(0, name.indexOf(' '))} @${username}`)
            }
          </Title>
          {
            professions
            && professions.length > 0
            && professions[0].data
            && (
              <Subtitle
                numberOfLines={1}
                lite
                style={{
                  flexWrap: 'wrap',
                  marginTop: 5,
                }}
              >
                {professions[0].data}
              </Subtitle>
            )
          }
          { itemUserAdmin && (
          <Subtitle style={{ marginTop: 4 }} lite primary>
            Administrador
          </Subtitle>
          )}
        </View>
        <Row>
          {(currentUserAdmin && enableActionsForAdmin) && (
          <Row style={{ padding: 10 }}>
            <TouchableOpacity
              style={{ marginRight: 8 }}
              onPress={() => setAdmin()}
            >
              <Image style={styles.image} source={Photos.setAdmin} />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => deleteUser()}
            >
              <Image style={styles.image} source={Photos.removeUser} />
            </TouchableOpacity>
          </Row>
          )}
          { canToTalk && (
          <Row style={{
            margin: 10,
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: Platform.OS == "web" ? '100px' : undefined
          }}
          >
            <Image
              source={Photos.send}
              style={{
                width: 20,
                height: 20,
                marginRight: 5,
              }}
            />
            <TouchableOpacity onPress={() => startChat()}>
              <Subtitle lite primary>
                  ABRIR CHAT
              </Subtitle>
            </TouchableOpacity>
          </Row>
          )}
        </Row>
      </Row>
    </TouchableOpacity>
  </AppCard>
);

Participant.propTypes = {
  canToTalk: PropTypes.bool.isRequired,
  currentUserAdmin: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  enableActionsForAdmin: PropTypes.bool.isRequired,
  itemUserAdmin: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  professions: PropTypes.array.isRequired,
  profileImageVisible: PropTypes.bool.isRequired,
  startChat: PropTypes.func.isRequired,
  openProfile: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  setAdmin: PropTypes.func.isRequired,
};

export default Participant;
