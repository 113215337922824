import React from 'react';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import { View, Image } from 'react-native';
import Title from './Title';

const StyledWrap = styled.View`
    align-items: center;
    background:#fff;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
`;

const ImgWrap = styled.View`
    align-items: center;
    background: #F2F2F2;
    border-radius: 100;
    justify-content: center;
    min-width: 50;
    min-height: 50;
    margin-right: 10px;
`;

const ListItemSettings = ({ title, source }) => (
  <StyledWrap>
    <ImgWrap>
      {source ? <Image style={{ height: 25, width: 25 }} source={source} /> : <></>}
    </ImgWrap>
    <View style={{ flex: 1, justifyContent: 'center' }}>
      <Title lite>
        {title}
      </Title>
    </View>
  </StyledWrap>
);

ListItemSettings.propTypes = {
  title: PropTypes.any.isRequired,
  source: PropTypes.any,
};

ListItemSettings.defaultProps = {
  source: '',
};

export default ListItemSettings;
