import { useCallback, useEffect, useState } from 'react';
import * as Location from 'expo-location';

const useLastKnowPosition = (hasLocationPermission) => {
  const [lastKnowPosition, setLastKnowPosition] = useState(undefined);

  const captureLastKnowPosition = useCallback(async () => {
    if (hasLocationPermission) {
      const hasService = await Location.hasServicesEnabledAsync();
      const options = { accuracy: Location.LocationAccuracy.BestForNavigation };

      if (hasService) {
        const location = await Location.getCurrentPositionAsync(options);
        setLastKnowPosition(location);
      } else {
        Location.enableNetworkProviderAsync().then(async () => {
          const location = await Location.getCurrentPositionAsync(options);
          setLastKnowPosition(location);
        }).catch((e) => console.log(e));
      }
    }
  }, [hasLocationPermission]);

  useEffect(() => {
    captureLastKnowPosition().catch((e) => console.log(e));
  }, [captureLastKnowPosition]);

  return { lastKnowPosition, captureLastKnowPosition };
};

export default useLastKnowPosition;
