import { TouchableOpacity } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import StyledText from '../atoms/StyledText';
import Colors from '../../state/constants/Colors';
import Subtitle from '../atoms/SubTitle';

const AbstractViewForActions = ({ value, action, error }) => (
  <TouchableOpacity onPress={() => action()}>
    <StyledText
      lite
      style={{
        borderColor: `${error.length !== 0 ? Colors.error : '#9C9C9C'}`,
        borderWidth: 1,
        borderRadius: 5,
        padding: 18,
      }}
    >
      {value}
    </StyledText>
    { error.length !== 0
      && (
      <Subtitle
        lite
        style={{
          marginLeft: 12,
          color: Colors.error,
        }}
      >
        {error}
      </Subtitle>
      )}
  </TouchableOpacity>
);

AbstractViewForActions.propTypes = {
  value: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
};

export default React.memo(AbstractViewForActions);
