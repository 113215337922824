import React from 'react';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import { TouchableOpacity } from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';

const StyledText = styled.Text`
  color: ${(props) => (props.link ? '#FFC000' : '#4d4d4d')};
  font-size: ${(props) => (props.small ? RFValue(16) : RFValue(20))};
  font-family: Montserrat;
`;

const ButtonText = ({
  title, style, link, small, onPress, bold
}) => (
  <TouchableOpacity style={style} onPress={onPress}>
    <StyledText
      style={{
        fontWeight: bold ? 'bold' : 'normal'
      }}
      small={small}
      link={link}>
      {title}
    </StyledText>
  </TouchableOpacity>
);

ButtonText.propTypes = {
  title: PropTypes.string.isRequired,
  style: PropTypes.any,
  link: PropTypes.any,
  small: PropTypes.any,
  bold: PropTypes.bool,
  onPress: PropTypes.func,
};
ButtonText.defaultProps = {
  style: null,
  link: false,
  small: false,
  onPress: null,
  bold: false,
};


export default ButtonText;
