import { View } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

const ButtonEdges = ({ text, onPress, ...rest }) => (
  <View style={{ alignItems: 'center', marginBottom: 12 }}>
    <Button
      text={text}
      onPress={onPress}
      lite
      border
      {...rest}
    />
  </View>
);

ButtonEdges.propTypes = {
  text: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
};

export default React.memo(ButtonEdges);
