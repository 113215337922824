import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'middleware';
import PropTypes from 'prop-types';
import {
  View,
  Platform,
  KeyboardAvoidingView,
  Keyboard,
  TouchableWithoutFeedback,
  Image,
} from 'react-native';
import styled from 'styled-components/native';
import Title from '../atoms/Title';
import Row from '../atoms/Row';
import LoginButton from '../molecules/LoginButton';
import ButtonText from '../atoms/ButtonText';
import Spacer from '../atoms/Spacer';
import StyledText from '../atoms/StyledText';
import Photos from '../../state/constants/Galery';
import Input from '../atoms/Input';

const LoginForm = styled.View`
    padding: 0px 35px;
    flex-grow: 1;
`;

const LoginCard = ({
  authWithApple,
  authWithGoogle,
  registerNewUser,
  recoveryPassword,
  authDefault,
}) => {
  const [userEmail, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [payload, setPayload] = useState({});

  const error = useSelector(selectors.auth.selectAuthError);
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const emailRef = useRef(null);
  const passRef = useRef(null);

  useEffect(() => {
    setPayload({ email: userEmail, pass: password });
  }, [userEmail, password]);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
      setKeyboardOpen(true);
    });
    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
      setKeyboardOpen(false);
    });

    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, []);

  return (
    <TouchableWithoutFeedback onPress={() => {
      if (Platform.OS != "web") {
          Keyboard.dismiss();
      }
      }}>
      <View style={{ flex: 1 }}>
        <KeyboardAvoidingView
          style={{ flex: 1 }}
          behavior={Platform.OS === 'ios' ? 'padding' : null}
          keyboardVerticalOffset={Platform.select({ ios: 0, android: 500 })}
        >
          <LoginForm>
            <View>
              { !keyboardOpen && (
                <Image
                  source={Photos.logoFont}
                  style={{
                    width: '100%',
                    aspectRatio: 5 / 1,
                    maxWidth: 300,
                    alignSelf: 'center',
                  }}
                  resizeMode="contain"
                />
              )}
              <Spacer size={64} />
              <Title style={{ marginLeft: 0, fontWeight: 'bold' }}>Faça login</Title>
              <Spacer size={12} />
              <Input
                inputRef={emailRef}
                textContentType="emailAddress"
                autoCapitalize="none"
                dark
                autoComplete="true"
                value={userEmail}
                onChangeText={setUserEmail}
                placeholder="Digite o email de acesso"
                keyboardType="email-address"
                returnKeyType="next"
                onSubmitEditing={() => passRef.current && passRef.current.focus()}
              />
              <Spacer size={12} />
              <Input
                inputRef={passRef}
                secureTextEntry
                dark
                value={password}
                onChangeText={setPassword}
                placeholder="Digite a senha"
                returnKeyType="next"
                onSubmitEditing={() => authDefault(payload)}
              />
              {error && (
                <Row style={{ paddingBottom: 5 }}>
                  <StyledText lite style={{ color: '#C00' }}>
                    {error.code === "auth/network-request-failed" ?
                      "Verifique sua conexão à internet" :
                      "Verifique os dados digitados"}
                  </StyledText>
                </Row>
              )}
              <Spacer size={16} />
              <Row style={{ justifyContent: 'space-between' }}>
                <ButtonText link onPress={recoveryPassword} small title="Esqueceu a senha?" />
              </Row>
            </View>
            <View>
              <Spacer size={32} />
              <LoginButton
                white
                colorPrimaryInBackground
                title="Entrar"
                onPress={() => authDefault(payload)}
              />
              <Spacer size={12} />
              <LoginButton
                onPress={() => authWithGoogle()}
                title="Entrar com o Google"
                source={Photos.google}
              />
              <Spacer size={48} />
              <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
                <ButtonText bold link title="Crie uma conta" onPress={registerNewUser} />
              </Row>
            </View>
          </LoginForm>
        </KeyboardAvoidingView>
      </View>
    </TouchableWithoutFeedback>
  );
};

LoginCard.propTypes = {
  authWithApple: PropTypes.func.isRequired,
  authWithGoogle: PropTypes.func.isRequired,
  recoveryPassword: PropTypes.func.isRequired,
  registerNewUser: PropTypes.func.isRequired,
  authDefault: PropTypes.func.isRequired,
};

export default React.memo(LoginCard);
