import React from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  TouchableOpacity,
  View,
} from 'react-native';
import Photos from '../../state/constants/Galery';
import Title from '../atoms/Title';
import Row from '../atoms/Row';
import BottomShadowView from '../atoms/BottomShadowView';

const DefaultHeader = ({
  onPress,
  shouldDisplayLogo,
  title,
  goBack,
}) => (
  <View style={{
    backgroundColor: '#ffffff',
  }}
  >
    <Row style={{
      backgroundColor: '#ffffff',
      marginTop: 10,
      marginBottom: 15,
    }}
    >
      <TouchableOpacity onPress={onPress} style={{ marginRight: 15, marginLeft: 10 }}>
        {
            goBack
              ? (
                <Image
                  style={{ height: 30, width: 30 }}
                  source={Photos.goBack}
                />
              )
              : <></>
          }
      </TouchableOpacity>
      {
          shouldDisplayLogo
            ? <Image style={{ height: 20, width: 110 }} source={Photos.logoFont} />
            : <Title bold>{title}</Title>
        }
    </Row>
    <BottomShadowView />
  </View>
);

DefaultHeader.propTypes = {
  onPress: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  goBack: PropTypes.any,
  shouldDisplayLogo: PropTypes.bool,
};

DefaultHeader.defaultProps = {
  shouldDisplayLogo: false,
  goBack: undefined,
};

export default React.memo(DefaultHeader);
