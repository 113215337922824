import React, { useState } from 'react';
import {
  SafeAreaView, View, Image, Platform
} from 'react-native';
import alert from '../../utils/Alert'
import * as WebBrowser from 'expo-web-browser';
import PropTypes from 'prop-types';
import { usersCollection } from 'middleware/src/database/collections';
import Security from '../../../assets/images/security.png';
import Title from '../atoms/Title';
import ButtonText from '../atoms/ButtonText';
import StyledText from '../atoms/StyledText';
import Row from '../atoms/Row';
import DefaultButton from '../atoms/DefaultButton';
import { fireAuth } from '../../utils/FirebaseSettings';
import Routes from '../routes/Routes';
import useRegistrationOngoing from '../../state/hooks/useRegistrationOngoing';
import { NavigationActions, StackActions } from 'react-navigation';
import ImageAdaptiveDimensions from '../organisms/ImageAdaptiveDimensions';

const ThermsOfUse = ({ navigation }) => {
  const [imgHeight, setImgHeight] = useState(0);
  const { uid, displayName } = fireAuth.currentUser;
  const { saveOngoing } = useRegistrationOngoing();

  const findDimensions = (layout) => {
    const { height } = layout;
    setImgHeight(height);
  };

  const goToNextStep = async () => {
    await usersCollection().doc(uid).update({
      newUser: false,
    }).then(() => {
      saveOngoing(false).then(() => {
        const resetAction = StackActions.reset({
          index: 0,
          actions: [
            NavigationActions.navigate({ routeName: Routes.MAIN_STACK_NAVIGATOR }),
          ],
        });
        navigation.dispatch(resetAction);
      });
    })
      .catch(() => alert('Aviso', 'Problema ao finalizar o cadastro, verifique sua conexão à internet'));
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ flex: 1, alignItems: 'center', marginTop: 20 }} onLayout={(event) => { findDimensions(event.nativeEvent.layout); }}>
        {
          Platform.OS == "web" ?
            <ImageAdaptiveDimensions
              source={Security}
              style={{
                flex: 1,
                height: imgHeight,
                width: '100vh',
                margin: 8,
                alignSelf: 'center',
              }}
            />
            :
            <Image
              source={Security}
              resizeMode="contain"
              style={{ height: imgHeight, alignSelf: 'center' }}
            />
        }
      </View>
      <View style={{
        flex: 1, alignItems: 'center', justifyContent: 'space-evenly', paddingHorizontal: 20,
      }}
      >
        <View style={{ alignItems: 'center' }}>
          <Title bold>
            {displayName}
            ,
          </Title>
          <Title>Somos muito preocupados</Title>
          <Title>com a transparência e</Title>
          <Title>proteção dos seus dados!</Title>
        </View>

        <Row style={{ justifyContent: 'center', flexWrap: 'wrap' }}>
          <StyledText style={{ fontSize: 16 }}>Leia e aceite os </StyledText>
          <ButtonText
            small
            link
            title="termos"
            onPress={() => WebBrowser.openBrowserAsync('https://localchat.link/privacy_policy.pdf')}
          />
          <StyledText style={{ fontSize: 16 }}> para terminar o cadastro!</StyledText>
        </Row>
        <DefaultButton
          lite
          title="Aceitar"
          style={{
            backgroundColor: '#FFC825',
            borderWidth: 0,
            borderRadius: 10,
            padding: 20,
            alignSelf: 'stretch',
            elevation: 1,
          }}
          onPress={goToNextStep}
        />
      </View>
    </SafeAreaView>
  );
};
ThermsOfUse.propTypes = {
  navigation: PropTypes.object,
};
ThermsOfUse.defaultProps = {
  navigation: null,
};

export default ThermsOfUse;
