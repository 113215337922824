import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import Row from '../atoms/Row';
import Title from '../atoms/Title';
import AppCard from '../atoms/AppCard';
import Subtitle from '../atoms/SubTitle';

const ChatHistoryItem = ({
  title, message, username, hour, onPress, unreadMessage,
}) => (
  <AppCard style={{ padding: 15, marginBottom: 10 }}>
    <View>
      <TouchableOpacity onPress={onPress}>
        <View>
          <Row style={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          >
            <Title
              numberOfLines={1}
              bold
              lite
              style={{
                marginRight: 10,
                flex: 1,
                flexWrap: 'wrap',
              }}
            >
              {title}
            </Title>
            { unreadMessage ? (
              <Subtitle
                lite
                primary
              >
                {hour}
              </Subtitle>
            ) : (
              <Subtitle lite>
                {hour}
              </Subtitle>
            )}
          </Row>
        </View>

        <Row style={{
          alignItems: 'center',
          marginTop: 5,
        }}
        >
          <Subtitle
            numberOfLines={1}
            lite
            bold
            primary
          >
            { `@${username}: `}
          </Subtitle>
          {
            unreadMessage ? (
              <Subtitle
                numberOfLines={1}
                lite
                bold
                style={{
                  flex: 1,
                  flexWrap: 'wrap',
                }}
              >
                {message}
              </Subtitle>
            ) : (
              <Subtitle
                numberOfLines={1}
                lite
                style={{
                  flex: 1,
                  flexWrap: 'wrap',
                }}
              >
                {message}
              </Subtitle>
            )
          }
        </Row>
      </TouchableOpacity>
    </View>
  </AppCard>
);

ChatHistoryItem.propTypes = {
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  hour: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  unreadMessage: PropTypes.bool.isRequired,
};

export default ChatHistoryItem;
