import { useCallback, useEffect, useState } from 'react';
import { GroupType } from 'middleware/src/states/constants/groups';
import axios from 'axios';
import Constants from 'expo-constants';

const usePlaceInfo = (groupData) => {
  const [placeInfo, setPlaceInfo] = useState(undefined);
  const [placePresent, setPlacePresent] = useState(false);

  const placeId = useCallback(() => {
    switch (groupData.category) {
      case GroupType.EVENT:
        return groupData.eventInfo.placeId;
      default:
        return groupData.parentId;
    }
  }, [groupData]);

  // FIXME migrate to middleware
  const fetchPlaceInfo = useCallback(async () => {
    const instance = axios.create({
      baseURL: 'https://maps.googleapis.com/maps/api/place/',
    });
    const key = Constants.manifest.extra.googleApiKey;
    const url = `details/json?key=${key}&place_id=${placeId()}`;
    try {
      const { data } = await instance.get(url);
      return Promise.resolve({ result: data.result });
    } catch(error) {
      return Promise.resolve({ result: null });
    }
  }, [placeId]);

  useEffect(() => {
    if (GroupType.SUBGROUP === groupData.category) {
      setPlacePresent(true);
    } else {
      fetchPlaceInfo().then(({ result }) => {
        setPlaceInfo(result);
        setPlacePresent(true);
      });
    }
  }, [fetchPlaceInfo, groupData.category]);

  return { placePresent, placeInfo };
};

export default usePlaceInfo;
