import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import DefaultInput from '../atoms/DefaultInput';
import mainTheme from '../../state/constants/Colors';
import Photos from '../../state/constants/Galery';

const Container = styled.View`
    flex-direction: row;
    border-width: 1;
    border-radius: 30;
    overflow: hidden;
    border-color: ${mainTheme.border};
    align-items: center;
    margin-bottom: 10px;
`;

const SearchBarIcon = styled.Image`
    tint-color: #e6e6e6;
    margin: 5px 10px;
    height: 25;
    width: 25;
`;

const SearchBar = ({ ...rest }) => (
  <Container>
    <SearchBarIcon source={Photos.search} />
    <View style={{ flex: 1 }}>
      <DefaultInput {...rest} style={{ borderBottomWidth: 0 }} />
    </View>
  </Container>
);

export default SearchBar;
