import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { View, Platform } from 'react-native';
import alert from '../../utils/Alert'
import { useDispatch } from 'react-redux';
import { actions } from 'middleware';
import Wrapper from '../atoms/Wrapper';
import LoginCard from '../organisms/LoginCard';
import {
  signInWithGoogle,
  signInWithApple,
} from '../../utils/LoginMethods';
import useAuthStepHandler from '../../state/hooks/useAuthStepHandler';
import useAuthWatcher from '../../state/hooks/useAuthWatcher';
import useGroupAccessByURL from '../../state/hooks/useGroupAccessByURL';
import Routes from '../routes/Routes';
import * as Google from "expo-auth-session/providers/google";
import * as WebBrowser from 'expo-web-browser';
import Constants from 'expo-constants';

if (Platform.OS == "web") {
  WebBrowser.maybeCompleteAuthSession();
}

const Login = ({ navigation }) => {
  const DEFAULT_TIMEOUT_FOR_ALERT = 500;
  const dispatch = useDispatch();
  const [request, response, promptGoogle]  = Google.useIdTokenAuthRequest({
    expoClientId: Constants.manifest.extra.expoClientId,
    webClientId: Constants.manifest.extra.webClientId,
    androidClientId: Constants.manifest.extra.google.androidStandaloneAppClientId,
    iosClientId: Constants.manifest.extra.google.iosStandaloneAppClientId,
    scopes: Constants.manifest.extra.google.scopes,
  });

  useEffect(() => {
    if (!response || !response.type) return () => {};
    signInWithGoogle(
      response.params ? response.params.id_token : null, 
      response.type
    ).then(
      () => navigateToAuthFlowStep(),
    ).catch((result) => alert('Ops', result.message));
  }, [response]);

  const {
    isAuthenticated,
    currentUser,
  } = useAuthWatcher();

  const {
    navigateToAuthFlowStep,
  } = useAuthStepHandler(
    navigation,
    isAuthenticated,
    currentUser,
  );

  useGroupAccessByURL(
    navigation,
    (errorMsg) => alert('Aviso', errorMsg),
  );

  const onLogin = (payload) => {
    dispatch(actions.auth.loginRequest(payload));
  };

  const alertUnsuccessfulLogin = () => {
    // it is necessary to give time to display alert,
    // problems with the return of the authentication call (mainly google).
    setTimeout(() => {
      alert('Ops', 'A solicitação para realizar login não foi processada com sucesso');
    }, DEFAULT_TIMEOUT_FOR_ALERT);
  };

  const signWithGoogle = () => {
    promptGoogle().catch(
      () =>  alert('Ops', 'Não foi possível processar a solicitação.')
    );
  };

  const signWithApple = () => {
    signInWithApple().then(
      () => navigateToAuthFlowStep(),
    ).catch(() => alertUnsuccessfulLogin());
  };

  return (
    <Wrapper style={{
      justifyContent: 'space-between',
      padding: 25,
    }}
    >
      <View style={{
        flex: 5,
        marginTop: 80,
      }}
      >
        <LoginCard
          authWithApple={() => signWithApple()}
          authWithGoogle={() => signWithGoogle()}
          registerNewUser={() => navigation.navigate(Routes.SIGNUP)}
          recoveryPassword={() => navigation.navigate(Routes.RECOVER_PASSWORD)}
          authDefault={(payload) => onLogin(payload)}
        />
      </View>
    </Wrapper>
  );
};

Login.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default Login;
