import React from 'react';
import PropTypes from 'prop-types';
import { Clipboard } from 'react-native';
import DefaultModal from './DefaultModal';
import ButtonBorderless from './ButtonBorderless';
import Photos from '../../state/constants/Galery';

const MessageOptionsModal = ({
  visible,
  admin,
  message,
  onRequestClose,
  openReportModal,
  canReportMessage,
  deleteMessage,
}) => (
  <DefaultModal
    title="Ações de mensagem"
    visible={visible}
    cancelText="Cancelar"
    confirmText="Fechar"
    onConfirm={onRequestClose}
    disableOnCancel
  >
    <MessageOption
      icon={Photos.copy}
      text="Copiar"
      onPress={() => {
        Clipboard.setString(message);
        onRequestClose();
      }}
    />
    { admin && (
    <MessageOption
      icon={Photos.removeUser}
      text="Remover"
      onPress={deleteMessage}
    />
    )}
    { canReportMessage && (
    <MessageOption
      icon={Photos.report}
      text="Denunciar"
      onPress={openReportModal}
    />
    )}
  </DefaultModal>
);

const MessageOption = ({ icon, text, onPress }) => (
  <ButtonBorderless
    style={{ alignItems: 'flex-start' }}
    primaryColor={false}
    source={icon}
    text={text}
    onPress={onPress}
  />
);

MessageOption.propTypes = {
  icon: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
};

MessageOptionsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  admin: PropTypes.bool,
  message: PropTypes.string.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  openReportModal: PropTypes.any.isRequired,
  deleteMessage: PropTypes.any.isRequired,
  canReportMessage: PropTypes.bool,
};

MessageOptionsModal.defaultProps = {
  admin: false,
  canReportMessage: true,
};

export default MessageOptionsModal;
