import React, {
  useEffect, useState, useCallback,
} from 'react';
import {
  SafeAreaView,
  FlatList,
  View,
} from 'react-native';
import { RefreshControl } from '../../utils/refresh'
import { registerForPushNotificationsAsync } from '../../utils/NotificationsHandler';
import PropTypes from 'prop-types';
import { getEvents } from 'middleware/src/database/events';
import EventItem from '../molecules/EventItem';
import SearchBar from '../organisms/SearchBar';
import Spacer from '../atoms/Spacer';
import Routes from '../routes/Routes';
import useNearLocations from '../../state/hooks/useNearLocations';
import useSavedPlaces from '../../state/hooks/useSavedPlaces';
import useLocationPermission from '../../state/hooks/useLocationPermission';
import useLastKnowPosition from '../../state/hooks/useLastKnowPosition';
import NotFound from '../molecules/NotFound';
import Loading from '../atoms/Loading';
import Photos from '../../state/constants/Galery';

const ExploreEvent = ({ navigation }) => {
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('');

  const [places, setPlaces] = useState([]);
  const [events, setEvents] = useState([]);

  const { locationPermission } = useLocationPermission();
  const { lastKnowPosition, captureLastKnowPosition } = useLastKnowPosition(locationPermission);
  const { nearLocations } = useNearLocations(lastKnowPosition);
  const { savedPlaces } = useSavedPlaces();

  const notExpectedEvents = useCallback(
    () => events.length === 0 && (!loading && !refreshing),
    [events.length, loading, refreshing],
  );

  const getAllEvents = useCallback(async () => {
    if (places.length > 0) {
      getEvents(places.map((place) => place.id))
        .then((result) => {
          setEvents(result);
          setLoading(false);
          setRefreshing(false);
        });
    }
    return [];
  }, [places]);

  useEffect(() => {
    if (nearLocations.length !== 0) {
      setPlaces(nearLocations);
    } else {
      setPlaces(savedPlaces);
    }
  }, [nearLocations, savedPlaces]);

  useEffect(() => {
    getAllEvents().catch((e) => console.log(e));
  }, [lastKnowPosition, getAllEvents]);

  const filteredEvents = useCallback(() => {
    if (filter && !notExpectedEvents()) {
      return events.filter(
        (item) => !!(item.data.title.includes(filter)),
      );
    }
    return events;
  }, [events, filter, notExpectedEvents]);

  useEffect(() => {
    if (locationPermission !== undefined) {
      registerForPushNotificationsAsync().then();
    }
  }, [locationPermission]);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setEvents([]);
    captureLastKnowPosition().catch((e) => console.log(e));
  }, [captureLastKnowPosition]);

  const renderItem = (item) => (
    <EventItem
      onPress={() => navigation.navigate(Routes.GROUP_CHAT, {
        parentId: item.id,
        groupData: {
          name: item.data.title,
          category: item.category,
          data: item.data,
          placeId: item.placeId,
        },
      })}
      title={item.data.title || 'Nome não encontrado'}
      desc={item.data.description || ''}
      placeName={item.data.placeName || 'Localização não encontrada'}
      business={item.data.business}
    />
  );

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {
        loading ? <Loading desc="Buscando eventos próximos..." /> : (
          <FlatList
            contentContainerStyle={{
              paddingHorizontal: 20,
              paddingTop: 5,
              flexGrow: 1,
            }}
            data={filteredEvents()}
            keyExtractor={(item) => item.id}
            ItemSeparatorComponent={() => <Spacer size={10} />}
            ListHeaderComponent={(
              <View style={{ paddingTop: 15 }}>
                <SearchBar value={filter} onChangeText={(text) => setFilter(text)} />
              </View>
            )}
            refreshControl={
              <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
            ListEmptyComponent={(
              <NotFound
                visible={!refreshing}
                title="Nenhum evento próximo de você"
                desc="Tente atualizar deslizando para baixo!"
                img={Photos.personSearching}
              />
          )}
            renderItem={({ item }) => renderItem(item)}
          />
        )
      }
    </SafeAreaView>
  );
};

ExploreEvent.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default ExploreEvent;
