import React, { useCallback, useState } from 'react';
import 'react-native-gesture-handler'
import { Provider } from 'react-redux';
import AppLoading from 'expo-app-loading';
import * as SplashScreen from 'expo-splash-screen';
import _ from 'lodash';
import {
  SafeAreaView,
  StatusBar,
  Platform,
} from 'react-native';
import alert from './utils/Alert'
import { store } from 'middleware';
import * as Font from 'expo-font';
import AppNavigator from './components/routes/AppNavigator';
import './utils/FirebaseSettings';
import { initializeAuth } from './utils/FirebaseSettings';
import Montserrat from '../assets/fonts/Montserrat-Light.otf';
import MontserratBold from '../assets/fonts/Montserrat-Bold.otf';
import MontserratItalic from '../assets/fonts/Montserrat-Italic.otf';

const App = () => {
  const [ready, setReady] = useState(false);

  const startConfig = useCallback(async () => {
    const promises = [
      await SplashScreen.preventAutoHideAsync(),
      await Font.loadAsync({
        Montserrat,
        MontserratBold,
        MontserratItalic,
      }),
    ];
    if (Platform.OS == "web") {
      promises.push(await initializeAuth());
    }
    return Promise.all(promises);
  }, []);

  return (
    <>
      { ready ? (
        <SafeAreaView style={{ flex: 1 }}>
          <StatusBar backgroundColor="white" barStyle="dark-content" />
          <Provider store={store}>
            <AppNavigator />
          </Provider>
        </SafeAreaView>
      ) : (
        <AppLoading
          startAsync={startConfig}
          onFinish={() => setReady(true)}
          autoHideSplash={false}
          onError={() => {
            alert(
              'Aviso',
              'Não foi possível abrir o aplicativo, tente novamente mais tarde',
            );
          }}
        />
      )}
    </>
  );
};

export default App;
