const earthRadius = 6378137;

/** *
 *returns the difference in meters
 */
export default class DistanceHelper {
  static distanceBetweenLocations(lat1, lon1, lat2, lon2) {
    const dLat = DistanceHelper.rad(lat2 - lat1);
    const dLong = DistanceHelper.rad(lon2 - lon1);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
            + Math.cos(DistanceHelper.rad(lat1)) * Math.cos(DistanceHelper.rad(lat2))
            * Math.sin(dLong / 2) * Math.sin(dLong / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return (earthRadius * c).toFixed(2);
  }

  static rad(x) { return (x * Math.PI) / 180; }
}
