import { useEffect, useState } from 'react';
import { get, save } from '../../utils/PersistenceHandler';
import Persistence from '../constants/Persistence';

const useRegistrationOngoing = () => {
  const [
    ongoing,
    setOngoing,
  ] = useState(undefined);

  const saveOngoing = async (
    newUser,
  ) => save(Persistence.NEW_USER, newUser);

  const seekOngoing = async () => {
    return await get(Persistence.NEW_USER);
  };

  useEffect(() => {
    seekOngoing().then(setOngoing);
  }, []);

  return {
    ongoing,
    saveOngoing,
    seekOngoing,
  };
};

export default useRegistrationOngoing;
