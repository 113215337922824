import React, { useState } from 'react';
import {
  Modal, View, Text, Platform, StyleSheet, TouchableOpacity,
} from 'react-native';
import PropTypes from 'prop-types';
import { RadioButton, TouchableRipple } from 'react-native-paper';
import { reportsCollection } from 'middleware/src/database/collections';
import Spacer from '../atoms/Spacer';
import MainTheme from '../../state/constants/Colors';
import { fireAuth } from '../../utils/FirebaseSettings';
import alert from '../../utils/Alert'

let styleRadioBorder = {};
let negativeMarginLeft;
let marginLeftSO;

if (Platform.OS === 'ios') {
  negativeMarginLeft = -15;
  marginLeftSO = 15;

  styleRadioBorder = {
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: MainTheme.dark,
    width: 37,
    height: 37,
    borderRadius: 20,
  };
} else {
  negativeMarginLeft = -22;
  marginLeftSO = 3;
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5,
    paddingHorizontal: 16,
  },
});

const ReportModal = ({
  visible,
  onRequestClose,
  denouncedId,
  denouncedEmail,
  denouncedName,
  reportType,
  messageId,
  parentId,
}) => {
  const { currentUser } = fireAuth;

  const [state, setState] = useState({
    value: 'Conteúdo sexual',
  });

  const sendReport = async () => {
    const messageForSend = {
      subject: `Olá, foi feito uma nova denúncia de tipo: ${reportType}`,
      text: `A denúncia foi realizada pelo usuário: [email:${currentUser.email}, name:${currentUser.displayName}, userId:${currentUser.uid}]`
        + `, referente ao usuário: [email:${denouncedEmail}, name:${denouncedName}, userId:${denouncedId}]`
        + `; o mesmo violou a política de tipo: ${state.value} ${reportType === 'message' ? `, 
          podemos encontrar a violação na mensagem de id:${messageId}, que se encontra no grupo de id: ${parentId}` : ''}. Obrigado!`,
    };

    await reportsCollection().add({
      reportType,
      denouncedId,
      messageId,
      parentId,
      whistleBlowerId: currentUser.uid,
      kindOffense: state.value,
      createdAt: new Date(),
      from: currentUser.email,
      to: 'contato@localchat.link',
      message: messageForSend,
    }).then(() => {
      onRequestClose();
      alert("Aviso", "Sua denúncia foi enviada com sucesso");
    }, () => {
      onRequestClose();
      alert("Aviso","Ops.. ocorreu algum erro ao denunciar");
    });
  };

  return (
    <View>
      <Modal
        transparent
        animationType="none"
        visible={visible}
        onRequestClose={onRequestClose}
      >
        <View style={{
          backgroundColor: '#00000080',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <View style={{
            backgroundColor: '#fff',
            color: 'black',
            padding: 30,
            borderRadius: 20,
            width: '100%',
            maxWidth: 350,
          }}
          >
            <Text style={{ fontWeight: 'bold', fontSize: 18 }}>Denunciar</Text>
            <Spacer horizontal size={15} />
            <View style={{ marginLeft: negativeMarginLeft }}>
              <TouchableRipple
                onPress={() => setState({ value: 'Spam' })}
              >
                <View style={styles.row}>
                  <View pointerEvents="none" style={styleRadioBorder}>
                    <RadioButton
                      value="Spam"
                      color={MainTheme.primary}
                      status={
                        state.value === 'Spam' ? 'checked' : 'unchecked'
                      }
                    />
                  </View>
                  <Text style={{ marginLeft: marginLeftSO }}>Spam</Text>
                </View>
              </TouchableRipple>
              <TouchableRipple
                onPress={() => setState({ value: 'Conteúdo sexual' })}
              >
                <View style={styles.row}>
                  <View pointerEvents="none" style={styleRadioBorder}>
                    <RadioButton
                      value="Conteúdo sexual"
                      color={MainTheme.primary}
                      status={
                        state.value === 'Conteúdo sexual' ? 'checked' : 'unchecked'
                      }
                    />
                  </View>
                  <Text style={{ marginLeft: marginLeftSO }}>Conteúdo sexual</Text>
                </View>
              </TouchableRipple>
              <TouchableRipple
                onPress={() => setState({ value: 'Bullying ou assédio' })}
              >
                <View style={styles.row}>
                  <View pointerEvents="none" style={styleRadioBorder}>
                    <RadioButton
                      value="Bullying ou assédio"
                      color={MainTheme.primary}
                      status={
                        state.value === 'Bullying ou assédio' ? 'checked' : 'unchecked'
                      }
                    />
                  </View>
                  <Text style={{ marginLeft: marginLeftSO }}>Bullying ou assédio</Text>
                </View>
              </TouchableRipple>
              <TouchableRipple
                onPress={() => setState({ value: 'Mensagens ofensivas' })}
              >
                <View style={styles.row}>
                  <View pointerEvents="none" style={styleRadioBorder}>
                    <RadioButton
                      value="Mensagens ofensivas"
                      color={MainTheme.primary}
                      status={
                        state.value === 'Mensagens ofensivas' ? 'checked' : 'unchecked'
                      }
                    />
                  </View>
                  <Text style={{ marginLeft: marginLeftSO }}>Mensagens ofensivas</Text>
                </View>
              </TouchableRipple>
              <TouchableRipple
                onPress={() => setState({ value: 'Abuso infantil' })}
              >
                <View style={styles.row}>
                  <View pointerEvents="none" style={styleRadioBorder}>
                    <RadioButton
                      value="Abuso infantil"
                      color={MainTheme.primary}
                      status={
                        state.value === 'Abuso infantil' ? 'checked' : 'unchecked'
                      }
                    />
                  </View>
                  <Text style={{ marginLeft: marginLeftSO }}>Abuso infantil</Text>
                </View>
              </TouchableRipple>
            </View>
            <Spacer horizontal size={20} />
            <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
              <TouchableOpacity
                onPress={() => { onRequestClose(); }}
                accessibilityLabel="Cancelar"
              >
                <Text style={{
                  color: MainTheme.primary, fontSize: 14, fontWeight: 'bold', textTransform: 'uppercase',
                }}
                >
                  Cancelar
                </Text>
              </TouchableOpacity>

              <Spacer vertical size={20} />

              <TouchableOpacity
                onPress={() => sendReport()}
                accessibilityLabel="Enviar"
              >
                <Text style={{
                  color: MainTheme.primary, fontSize: 14, fontWeight: 'bold', textTransform: 'uppercase',
                }}
                >
                  Enviar
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

ReportModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  reportType: PropTypes.string.isRequired,
  denouncedId: PropTypes.string.isRequired,
  denouncedEmail: PropTypes.string,
  denouncedName: PropTypes.string,
  messageId: PropTypes.string,
  parentId: PropTypes.string,
};

ReportModal.defaultProps = {
  denouncedEmail: null,
  denouncedName: null,
  messageId: null,
  parentId: null,
};

export default React.memo(ReportModal);
