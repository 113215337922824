import { OutlinedTextField } from 'react-native-material-textfield';
import React from 'react';
import PropTypes from 'prop-types';
import MainTheme from '../../state/constants/Colors';

const Input = ({ placeHolder, errorText, ...rest }) => (
  <OutlinedTextField
    tintColor={MainTheme.primary}
    label={placeHolder}
    error={errorText}
    {...rest}
  />
);

Input.propTypes = {
  placeHolder: PropTypes.string.isRequired,
  errorText: PropTypes.string,
};

Input.defaultProps = {
  errorText: null,
};

export default Input;
