import { View } from 'react-native';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Title from '../atoms/Title';
import AppCard from '../atoms/AppCard';
import ProfileData from '../molecules/ProfileData';
import DefaultButton from '../atoms/DefaultButton';
import CheckItemsModal from './CheckItemsModal';
import Spacer from '../atoms/Spacer';

const ProfileProfessions = ({
  editing,
  allProfessions,
  professions,
  onChange,
}) => {
  const [professionsModal, setOpenProfessionsModal] = useState(false);

  return (
    <>
      <Title lite primary bold>Profissões</Title>
      <AppCard style={{
        paddingHorizontal: 15,
        paddingVertical: 10,
      }}
      >
        <ProfileData data={professions} />
      </AppCard>
      {
        editing && (
          <View style={{
            width: 100,
            marginTop: 15,
          }}
          >
            <DefaultButton
              lite
              style={{
                backgroundColor: '#FFC825',
                borderColor: '#FFFFFF',
              }}
              colorPrimaryInBackground
              title="Editar"
              onPress={() => setOpenProfessionsModal(true)}
            />
          </View>
        )
      }
      {
        !!allProfessions && allProfessions.length > 0 && (
          <CheckItemsModal
            visible={professionsModal}
            onRequestClose={() => setOpenProfessionsModal(false)}
            sendPayload={(data) => onChange(data)}
            data={allProfessions}
            initialSelected={professions}
          />
        )
      }
      <Spacer size={10} />
    </>
  );
};

ProfileProfessions.propTypes = {
  editing: PropTypes.bool.isRequired,
  allProfessions: PropTypes.array.isRequired,
  professions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default React.memo(ProfileProfessions);
