import * as Notifications from 'expo-notifications';
import { usersCollection } from 'middleware/src/database/collections';
import { fireAuth, fireMessaging, fireServiceWorker } from './FirebaseSettings';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Persistence from '../state/constants/Persistence';
import { Platform } from 'react-native';

export const registerForPushNotificationsAsync = async () => {
  if (!fireAuth.currentUser) {
    return null;
  }

  const { uid } = fireAuth.currentUser;

  const { status: existingStatus } = await Notifications.getPermissionsAsync();
  let finalStatus = existingStatus;

  if (existingStatus !== 'granted') {
    const { status } = await Notifications.requestPermissionsAsync();
    finalStatus = status;
  }

  if (finalStatus !== 'granted') {
    return null;
  }

  let deviceToken = null;
  try {
    if (Platform.OS == "web") {
      const result = await getTokenForWeb();
      if (!result) {
        deviceToken = null
      } else {
        deviceToken = {
          data: result,
          type: 'web',
        }
      }
    } else {
      deviceToken = await Notifications.getDevicePushTokenAsync({});
    }
  } catch(error) {
    console.warn("error device token" + error)
    deviceToken = null;
  }
  if (deviceToken == null) {
    console.warn("Error when obtaining token")
    return null;
  }
  const storageDeviceToken = await getStorageDevicePushTokenAsync();

  const tokenChanged = storageDeviceToken != null && storageDeviceToken != deviceToken.data
  const noTokenInStorage = storageDeviceToken == null || storageDeviceToken == undefined

  if(noTokenInStorage || tokenChanged)
  {
    if(deviceToken.type == "ios" )
    {
      const instance = axios.create({
        baseURL: 'https://iid.googleapis.com/'
      });

      var config = {
        headers: {'Authorization': 'key=AAAAsnzJWSw:APA91bE9Hn_MZMVOnfDSsGoN-LUVz0rmL_QwDGFFZqilnOsicOiybOaxQmA6yN4qVryQVOa_hDkgnpzgbu0e6EmyK-4Q5oNX5QbzfyvNk824S35lXBDI6MIJfSJ8V1ua5T_OuOXdGggf'}
      };

      const body = {
        "application": "com.localchat.localchat",
        "sandbox":false,
        "apns_tokens":[
          deviceToken.data
        ]
      }

      const res = await instance.post('iid/v1:batchImport', body, config);

      deviceToken = {
        data: res.data.results[0].registration_token,
        type: 'fcm',
        apns_token: res.data.results[0].apns_token
      }

      await saveDevicePushTokenAsync(deviceToken.apns_token);
    }else{
      await saveDevicePushTokenAsync(deviceToken.data);
    }

    await usersCollection().doc(uid).update({ pushToken: deviceToken });
  }

  return null;
};

async function getStorageDevicePushTokenAsync() {
  const deviceToken = await AsyncStorage.getItem(Persistence.DEVICE_PUSH_TOKEN)
  const parsed = JSON.parse(deviceToken)
  return parsed;
}

async function saveDevicePushTokenAsync(results) {
  let tokenToSave = JSON.stringify(results)
  await AsyncStorage.setItem(Persistence.DEVICE_PUSH_TOKEN, tokenToSave);
}

async function getTokenForWeb() {
  try {
    const token = await fireMessaging.getToken({
      vapidKey: 'BA2K-VKZL3817xBXxCUrq6eVQHM_BUMWx1LNHJPMi2ntu_UDFHHUDnlZVDiygJov3aDCeuH3qUCmmp2rO4z8lbY',
      serviceWorkerRegistration: fireServiceWorker
    })
    return token;
  } catch (error) {
    console.error('getTokenForWeb error:', error);
    return null;
  }
}
