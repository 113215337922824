import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  SafeAreaView,
  View,
} from 'react-native';
import alert from '../../utils/Alert'
import {
  startMessagesSnapshot,
  sendMessage,
} from 'middleware/src/database/privateMessages';
import { usersCollection } from 'middleware/src/database/collections';
import { updateLastOpenPrivateChat } from 'middleware/src/database/users';
import { fireAuth } from '../../utils/FirebaseSettings';
import Chat from '../organisms/Chat';
import Loading from '../atoms/Loading';
import Routes from '../routes/Routes';
import PrivateChatHeader from '../molecules/PrivateChatHeader';

const PrivateChat = ({ navigation }) => {
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [blockedMeUsers, setBlockedMeUsers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [token, setToken] = useState(navigation.getParam('token'));
  const foreignUserId = navigation.getParam('foreignUserId');
  const foreignName = navigation.getParam('foreignName');
  const { currentUser } = fireAuth;
  const [loading, setLoading] = useState(true);
  const [chatHeaderHeight, setChatHeaderHeight] = useState(null);

  const alertWhenAnyError = () => {
    alert(
      'Aviso', 'Problema ao processar solicitação, verifique sua conexão à internet',
    );
  };

  useEffect(() => {
    usersCollection().doc(foreignUserId).onSnapshot((snapshot) => {
      const user = snapshot.data();
      if (user) {
        if (user.blockedUsers) {
          setBlockedUsers(user.blockedUsers);
        }
        if (user.blockedMeUsers) {
          setBlockedMeUsers(user.blockedMeUsers);
        }
      }
      setLoading(false);
    });
    return () => {};
  }, [foreignUserId, currentUser]);

  useEffect(() => {
    if (token) {
      startMessagesSnapshot(token, (allMessages) => {
        setMessages(allMessages);
      });
    } else {
      setLoading(false);
    }
    return () => {};
  }, [foreignUserId, token, currentUser]);

  const checkAndUpdateLastOpenChat = useCallback(async () => {
    if (currentUser) {
      await updateLastOpenPrivateChat(currentUser.uid, foreignUserId, new Date());
    }
  }, [currentUser, foreignUserId]);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View onLayout={(event) => {
        const { height } = event.nativeEvent.layout;
        setChatHeaderHeight(height);
      }}>
        <PrivateChatHeader
          title={foreignName}
          pressOnBack={() => navigation.goBack()}
          pressOnTitle={() => navigation.navigate(Routes.PROFILE, { userId: foreignUserId })}
        />
      </View>
      {
        loading ? <Loading desc="Buscando mensagens.." /> : (
          <Chat
            isPrivateMessage
            navigation={navigation}
            messages={messages}
            onOpenChat={() => checkAndUpdateLastOpenChat()}
            sendMessage={
            (message) => sendMessage(foreignUserId, token, message)
              .then((data) => data && setToken(data.result))
              .catch(() => alertWhenAnyError())
            }
            canSendMessage={currentUser && (
              !blockedUsers.includes(currentUser.uid) && !blockedMeUsers.includes(currentUser.uid)
            )}
            canSendImage={false}
            headerHeight={chatHeaderHeight}
          />
        )
      }
    </SafeAreaView>
  );
};

PrivateChat.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default PrivateChat;
