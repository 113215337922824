import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { startPrivateChatsSnapshot } from 'middleware/src/database/users';
import ChatHistory from '../organisms/ChatHistory';
import Routes from '../routes/Routes';

const PrivateChatHistory = ({ navigation }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  const startSnapshot = useCallback((userId, enableLoading) => {
    setLoading(enableLoading);
    startPrivateChatsSnapshot(userId, (chats) => {
      setMessages(chats);
      setLoading(false);
    });
  }, []);

  return (
    <ChatHistory
      loading={loading}
      action={(item) => navigation.navigate(Routes.PRIVATE_CHAT, {
        foreignUserId: item.id,
        token: item.info.id,
        foreignName: item.username,
      })}
      messages={messages}
      navigation={navigation}
      triggerCurrentMessages={(userId) => startSnapshot(userId, true)}
      onBackPressed={(userId) => startSnapshot(userId, false)}
    />
  );
};

PrivateChatHistory.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default PrivateChatHistory;
