import React from 'react';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import StyledText from '../atoms/StyledText';
import MainTheme from '../../state/constants/Colors';

const Img = styled.Image`
    height: 25;
    width: 25;
    marginRight: 16
`;

const ButtonContainer = styled.TouchableOpacity`
    align-items: center;
    justify-content: center;    
    flex-direction: row;
    width: ${(props) => (props.customWidth ? props.customWidth : '100%')};
    padding: ${(props) => (props.customPadding ? props.customPadding : '10px')};
    border-radius: 15;
    margin: ${(props) => (props.customMargin ? props.customMargin : '0')};
    border: 1px solid #e7e7e7;
    background:  ${(props) => (props.colorPrimaryInBackground ? MainTheme.primary : '#ffffff')};
`;

const LoginButton = ({
  title, source, onPress, style, colorPrimaryInBackground, customMargin, white, customWidth, customPadding,
}) => (
  <ButtonContainer
    colorPrimaryInBackground={colorPrimaryInBackground}
    onPress={onPress}
    customWidth={customWidth}
    customPadding={customPadding}
    customMargin={customMargin}
    style={style}
  >
    { source && <Img source={source} /> }
    <StyledText bold white={white} lite>{title}</StyledText>
  </ButtonContainer>
);
LoginButton.propTypes = {
  customMargin: PropTypes.string,
  customWidth: PropTypes.string,
  customPadding: PropTypes.string,
  title: PropTypes.string,
  source: PropTypes.any,
  onPress: PropTypes.func,
  style: PropTypes.any,
  colorPrimaryInBackground: PropTypes.bool,
  white: PropTypes.bool,
};
LoginButton.defaultProps = {
  customMargin: '',
  customWidth: '',
  customPadding: '',
  title: '',
  source: null,
  onPress: null,
  style: null,
  colorPrimaryInBackground: false,
  white: false,
};

export default React.memo(LoginButton);
