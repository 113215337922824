import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Platform,
  SafeAreaView,
  View
} from 'react-native';
import alert from '../../utils/Alert'
import * as ImagePicker from 'expo-image-picker';
import {
  startMessagesSnapshot,
  sendMessage,
  uploadAndSendImage,
} from 'middleware/src/database/groupMessages';
import {
  groupIdByParentId,
  createAndInsertUserInGroup,
} from 'middleware/src/database/groups';
import { StackActions } from 'react-navigation';
import { updateLastOpenGroupChat } from 'middleware/src/database/users';
import Chat from '../organisms/Chat';
import Loading from '../atoms/Loading';
import Routes from '../routes/Routes';
import { fireAuth } from '../../utils/FirebaseSettings';
import GroupChatHeader from '../molecules/GroupChatHeader';
import ButtonColored from '../molecules/ButtonColored';
import GroupPasswordModal from '../organisms/GroupPasswordModal';
import useGroupParticipantChecker from '../../state/hooks/useGroupParticipantChecker';
import useGroupPasswordChecker from '../../state/hooks/useGroupPasswordChecker';
import useGroupAdminChecker from '../../state/hooks/useGroupAdminChecker';
import NotFound from '../molecules/NotFound';
import Photos from '../../state/constants/Galery';

const GroupChat = ({ navigation }) => {
  const [groupId, setGroupId] = useState(navigation.getParam('groupId'));
  const parentId = navigation.getParam('parentId');
  const groupData = navigation.getParam('groupData');
  const groupName = navigation.getParam('name');

  const { currentUser } = fireAuth;
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [enterGroupLoading, setEnterGroupLoading] = useState(false);
  const [canRefreshGroupId, setCanRefreshGroupId] = useState(null);
  const [chatHeaderHeight, setChatHeaderHeight] = useState(null);
  const [signupButtonHeight, setSignupButtonHeight] = useState(null);

  const findGroupId = () => {
    groupIdByParentId(parentId)
    .then((id) => {
      setCanRefreshGroupId(null);
      if (id) {
        setGroupId(id);
      } else {
        setLoadingMessages(false);
        setLoadingPassword(false);
        setEnterGroupLoading(false);
        console.log('group not exist');
      }
    }).catch(() => setCanRefreshGroupId(true));
  }

  useEffect(() => {
    if (!groupId) { findGroupId() }
  }, [groupId, parentId, enterGroupLoading]);

  const {
    admin,
  } = useGroupAdminChecker(groupId, currentUser && currentUser.uid);

  const {
    participant,
    notParticipant,
    participationError,
  } = useGroupParticipantChecker(navigation, groupId, parentId, enterGroupLoading);

  const {
    password,
    showAskPassword,
    passwordWasRequested,
    loadingPassword,
    setLoadingPassword,
  } = useGroupPasswordChecker(groupId, participant, notParticipant);

  const alertWhenAnyError = () => {
    alert(
      'Aviso', 'Problema ao processar solicitação, verifique sua conexão à internet',
    );
  };

  useEffect(() => {
    if (!!participationError) { setCanRefreshGroupId(true) }
  }, [participationError]);

  useEffect(() => {
    if (groupId && !passwordWasRequested) {
      startMessagesSnapshot(groupId, (allMessages) => {
        setMessages(allMessages);
        setLoadingMessages(false);
      });
    }
  }, [groupId, passwordWasRequested]);

  const checkAndUpdateLastOpenChat = useCallback(async () => {
    if (groupId && currentUser) {
      await updateLastOpenGroupChat(currentUser.uid, groupId, new Date());
    }
  }, [currentUser, groupId]);

  const requestMediaLibraryPermissionsAsync = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    return status == 'granted';
  }

  const requestCameraPermissionsAsync = async () => {
    const { status } = await ImagePicker.requestCameraPermissionsAsync();
    return status == 'granted';
  }

  const takePhoto = async () => {
    try {
      const result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 0.8,
      });
      await uploadAndSendImage(groupId, result);
    } catch (error) {
      alert('Aviso', 'Envio de imagem não realizado.');
    }
  };

  const pickImage = async () => {
    try {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 0.8,
      });
      await uploadAndSendImage(groupId, result);
    } catch (error) {
      alert('Aviso', 'Envio de imagem não realizado.');
    }
  };

  const sendImage = async () => {
    const requestMediaLibraryResult = await requestMediaLibraryPermissionsAsync();
    const requestCameraResult = await requestCameraPermissionsAsync();
    const canBeSatisfied = requestMediaLibraryResult && requestCameraResult;

    if (canBeSatisfied) {
      if (Platform.OS == "web") {
        alert(
          'Enviar imagem',
          'Confirme para escolher o arquivo de envio.',
          [
            {
              text: 'Enviar Arquivo',
              onPress: () => pickImage(),
            },
            { text: 'Cancelar', style: 'cancel' },
          ],
          { cancelable: false }
        );
        return;
      }
      alert(
        'Selecionar fonte da imagem',
        'Deseja tirar uma foto ou enviar um arquivo salvo?',
        [
          {
            text: 'Enviar Arquivo',
            onPress: () => pickImage(),
          },
          {
            text: 'Abrir Camera',
            onPress: () => takePhoto(),
          },
          { text: 'Cancelar', style: 'cancel' },
        ],
        { cancelable: false }
      );
    } else {
      alert(
        'Permissões não concedidas',
        'Navegue para settings>apps>local chat para permitir enviar fotos.'
      );
    }
  };

  const navigateTo = (routeName) => participant
      && navigation.dispatch(
        StackActions.push({
          routeName,
          params: { groupId },
        }),
      );

  const enterGroup = () => {
    setEnterGroupLoading(true);
    createAndInsertUserInGroup(
      parentId,
      groupData,
      groupId,
    ).then(({ id }) => {
      setGroupId(id);
      setEnterGroupLoading(false);
    }).catch(() => {
      setEnterGroupLoading(false);
      alertWhenAnyError();
    });
  };

  const keepLoading = loadingPassword || showAskPassword || loadingMessages;

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View onLayout={(event) => {
        const { height } = event.nativeEvent.layout;
        setChatHeaderHeight(height);
      }}>
        <GroupChatHeader
          navigation={navigation}
          title={groupData.name || groupName}
          visibleOptions={participant}
          pressOnTitle={() => navigateTo(Routes.GROUP_INFO)}
          pressGroupParticipants={() => navigateTo(Routes.GROUP_PARTICIPANTS)}
          pressOnBack={() => navigation.goBack()}
        />
      </View>
      {
        canRefreshGroupId ?
          <View style={{ flex: 1 }}>
            <NotFound
              title='Verifique a internet'
              desc='Clique abaixo para tentar novamente'
              img={Photos.personDontUnderstand}
            />
            <ButtonColored
              style={{ padding: 24 }}
              text='Tentar Novamente'
              onPress={() => {
                setEnterGroupLoading(true)
                findGroupId()
              }}
            />
          </View> :
          keepLoading ? <Loading /> : (
            <>
              <Chat
                isAdmin={admin}
                navigation={navigation}
                groupId={groupId}
                parentId={parentId}
                messages={messages}
                onOpenChat={() => checkAndUpdateLastOpenChat()}
                participant={participant}
                canSendMessage={participant && !loadingMessages}
                sendImage={() => sendImage()}
                sendMessage={(message) => sendMessage(groupId, message)
                  .catch(() => alertWhenAnyError())}
                headerHeight={chatHeaderHeight}
                customButtonHeight={signupButtonHeight}
              />
              {
                notParticipant && (
                  <View onLayout={(event) => {
                    const { height } = event.nativeEvent.layout;
                    setSignupButtonHeight(height);
                  }}>
                    <ButtonColored
                      style={Platform.OS === 'web'
                        ? { padding: 18 }
                        : { margin: 24 }}
                      loading={enterGroupLoading}
                      text="Entrar no grupo"
                      onPress={() => enterGroup()}
                    />
                  </View>
                )}
            </>
          )
      }
      <GroupPasswordModal
        visible={showAskPassword}
        password={password}
        onConfirm={() => enterGroup()}
        onCancel={() => navigation.goBack()}
      />
    </SafeAreaView>
  );
};

GroupChat.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default GroupChat;
