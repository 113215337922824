import React, { useState, useRef } from 'react';
import { KeyboardAvoidingView } from 'react-native';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import Spacer from '../atoms/Spacer';
import MainTheme from '../../state/constants/Colors';
import StyledText from '../atoms/StyledText';
import LoginButton from '../molecules/LoginButton';
import Input from '../atoms/Input';
import DefaultHeader from '../molecules/DefaultHeader';

const SignupForm = styled.View`
    padding: 0 18px;
    flex: 1;
`;

const SignupCard = ({ onSignup, onClose }) => {
  const [payload, setPayload] = useState({
    email: '',
    pass: '',
    passConfirm: '',
  });
  const [error, setError] = useState('');
  const emailRef = useRef(null);
  const passRef = useRef(null);
  const passConfirmRef = useRef(null);

  const sendToForm = (type, value) => {
    setPayload({
      ...payload,
      [type]: value,
    });
  };

  return (
    <KeyboardAvoidingView style={{ flex: 1 }} enabled>
      <DefaultHeader title='Criar conta' onPress={onClose} goBack={true} />
      <SignupForm>
        <Spacer size={48} />
        <Input
          inputRef={emailRef}
          autoFocus
          onChangeText={(text) => sendToForm('email', text)}
          dark
          textContentType="emailAddress"
          autoComplete="true"
          keyboardType="email-address"
          autoCapitalize="none"
          placeholder="Digite seu melhor email"
          returnKeyType="next"
          onSubmitEditing={() => passRef.current && passRef.current.focus()}
        />
        <Spacer size={18} />
        <Input
          inputRef={passRef}
          onChangeText={(text) => sendToForm('pass', text)}
          dark
          secureTextEntry
          maxLength={16}
          placeholder="Senha"
          returnKeyType="next"
          onSubmitEditing={() => passConfirmRef.current && passConfirmRef.current.focus()}
        />
        <Spacer size={18} />
        <Input
          inputRef={passConfirmRef}
          onChangeText={(text) => sendToForm('passConfirm', text)}
          dark
          maxLength={16}
          secureTextEntry
          placeholder="Repita a senha"
          returnKeyType="next"
          onSubmitEditing={() => onSignup(payload, setError)}
        />
        <Spacer size={8} />
        {!!error && (
          <StyledText lite style={{ color: MainTheme.error }}>**{error}</StyledText>
        )}
        <Spacer size={18} />
        <LoginButton
          white
          colorPrimaryInBackground
          title="Criar conta"
          onPress={() => onSignup(payload, setError)}
        />
      </SignupForm>
    </KeyboardAvoidingView>
  );
};

SignupCard.propTypes = {
  onSignup: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SignupCard;
