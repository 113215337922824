import {
  useEffect, useMemo, useState,
} from 'react';
import { checkUserIsAdmin } from 'middleware/src/database/groups';

const useGroupAdminChecker = (groupId, userId) => {
  const [admin, setAdmin] = useState(false);

  const checkAdmin = useMemo(() => async () => (
    groupId && userId
      ? checkUserIsAdmin(groupId, userId)
      : false
  ),
  [groupId, userId]);

  useEffect(() => {
    checkAdmin().then(setAdmin);
  }, [checkAdmin]);

  return { admin };
};

export default useGroupAdminChecker;
