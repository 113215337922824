import {
  fireAuth,
  googleProvider,
} from './FirebaseSettings';

const signInWithGoogle = async (idToken, type) => {
  switch (type) {
    case 'opened': {
      return Promise.reject({ type: type, message: 'O login está em andamento.' });
    }
    case 'locked': {
      return Promise.reject({ type: type, message: 'Já existe uma tentativa de login em aberto.' });
    }
    case 'success': {
      return fireAuth.signInWithCredential(googleProvider.credential(idToken))
        .then((data) => Promise.resolve({ type: type, message: 'success', data: data }))
        .catch(() => Promise.reject({ type: 'error', message: 'Verifique sua conexão à internet.' }));
    }
    case 'dismiss': {
      return Promise.reject({ type: type, message: 'Login não realizado.' });
    }
    case 'cancel': {
      return Promise.reject({ type: type, message: 'Tentativa de login cancelada.' });
    }
    case 'error': {
      return Promise.reject({ type: type, message: 'Ocorreu um erro ao processar o login.' });
    }
    default:
      return Promise.reject({ type: type, message: 'Não foi possível processar, tente novamente mais tarde.' });
  }
};

const signInWithApple = async () => {
//  const csrf = Math.random().toString(36).substring(2, 15);
//  const nonce = Math.random().toString(36).substring(2, 10);
//  const hashedNonce = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA256, nonce);
//  const appleCredential = await AppleAuthentication.signInAsync({
//    requestedScopes: [
//      AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
//      AppleAuthentication.AppleAuthenticationScope.EMAIL,
//    ],
//    state: csrf,
//    nonce: hashedNonce,
//  });
//  const { identityToken } = appleCredential;
//
//  if (identityToken) {
//    const provider = new auth.OAuthProvider('apple.com');
//    const credential = provider.credential({
//      idToken: identityToken,
//      rawNonce: nonce, // nonce value from above
//    });
//
//    return fireAuth.signInWithCredential(credential)
//      .then((data) => Promise.resolve({ type: 'success', data }))
//      .catch((error) => Promise.reject(new TypeError(error.message)));
//  }
//  return Promise.reject({ type: 'cancel' });
    return Promise.resolve();
};

export {
  signInWithGoogle,
  signInWithApple,
};
