import React from 'react';
import { Image, TouchableOpacity, View } from 'react-native';
import PropTypes from 'prop-types';
import Row from '../atoms/Row';
import ArrowBackIcon from '../../../assets/images/arrow-back-icon.png';
import Title from '../atoms/Title';
import Photos from '../../state/constants/Galery';
import BottomShadowView from '../atoms/BottomShadowView';

const GroupChatHeader = ({
  title,
  visibleOptions,
  pressOnTitle,
  pressGroupParticipants,
  pressOnBack,
}) => (
  <>
    <Row
      style={{
        alignItems: 'center',
        paddingVertical: 15,
        paddingHorizontal: 10,
      }}
    >
      <TouchableOpacity
        onPress={() => pressOnBack()}
        style={{
          width: '10%',
          alignItems: 'center',
        }}
      >
        <Image
          style={{
            height: 30,
            width: 30,
          }}
          source={ArrowBackIcon}
        />
      </TouchableOpacity>
      {visibleOptions ? (
        <View
          style={{
            width: '90%',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <TouchableOpacity
            onPress={() => pressOnTitle()}
            style={{
              width: '80%',
            }}
          >
            <Title numberOfLines={1} style={{ flexWrap: 'wrap', marginLeft: 10, marginRight: 20 }} bold>
              {title}
            </Title>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              width: '10%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={() => pressGroupParticipants()}
          >
            <Image
              style={{
                width: 30,
                height: 30,
                marginRight: 10,
              }}
              source={Photos.groups}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              width: '10%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={() => pressOnTitle()}
          >
            <Image
              style={{
                width: 30,
                height: 30,
              }}
              source={Photos.info}
            />
          </TouchableOpacity>
        </View>
      ) : (
        <TouchableOpacity
          onPress={() => pressOnTitle()}
          style={{ width: '85%' }}
        >
          <Title numberOfLines={1} style={{ flexWrap: 'wrap', marginLeft: 10 }} bold>
            {title}
          </Title>
        </TouchableOpacity>
      )}
    </Row>
    <BottomShadowView />
  </>
);

GroupChatHeader.propTypes = {
  visibleOptions: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  pressOnTitle: PropTypes.func.isRequired,
  pressGroupParticipants: PropTypes.func.isRequired,
  pressOnBack: PropTypes.func.isRequired,
};

export default React.memo(GroupChatHeader);
