import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import Constants from 'expo-constants';
import AsyncStorage from '@react-native-async-storage/async-storage';
import DistanceHelper from '../../utils/DistanceHelper';
import Persistence from '../constants/Persistence';
import LocationSearchRadius from '../constants/LocationSearchRadius';

const useNearLocations = (lastKnowPosition) => {
  const [nearLocations, setNearLocations] = useState([]);

  const distanceBetweenLocations = (
    location,
    locationDiff,
  ) => parseFloat(DistanceHelper.distanceBetweenLocations(
    location.latitude, location.longitude,
    locationDiff.latitude, locationDiff.longitude,
  ));

  const saveLastKnowPosition = async (location) => {
    await AsyncStorage.setItem(Persistence.LAST_KNOW_POSITION, JSON.stringify(location));
  };

  const saveNearLocations = async (placesResult) => {
    await AsyncStorage.setItem(Persistence.PLACES, JSON.stringify(placesResult));
  };

  const saveEventInRadius = async (value) => {
    await AsyncStorage.setItem(
      Persistence.CONFIGURED_LOCATION_RADIUS_EVENT, JSON.stringify(value),
    );
  };

  const getRadius = async () => {
    const radius = await AsyncStorage.getItem(Persistence.CONFIGURED_LOCATION_RADIUS);
    return parseFloat(!radius ? LocationSearchRadius.MEDIUM : radius).toFixed(2);
  };

  const getLastKnowPositionSaved = async () => JSON.parse(
    await AsyncStorage.getItem(Persistence.LAST_KNOW_POSITION),
  );

  const getEventInRadiusSaved = async () => JSON.parse(
    await AsyncStorage.getItem(Persistence.CONFIGURED_LOCATION_RADIUS_EVENT),
  );

  const getNearLocations = useCallback(async () => {
    if (!lastKnowPosition) return;
    const lastKnowPositionSaved = await getLastKnowPositionSaved();
    const eventInRadiusSaved = await getEventInRadiusSaved();
    const radius = await getRadius();

    const withinRadius = (
      lastKnowPositionSaved
      && distanceBetweenLocations(
        lastKnowPosition.coords,
        lastKnowPositionSaved.coords,
      ) <= radius);

    if (!(withinRadius && !eventInRadiusSaved)) {
      const instance = axios.create({
        baseURL: 'https://maps.googleapis.com/maps/api/place/',
      });
      const key = Constants.manifest.extra.googleApiKey;
      const location = `${lastKnowPosition.coords.latitude},${lastKnowPosition.coords.longitude}`;
      const url = `nearbysearch/json?key=${key}&location=${location}&radius=${radius}`;
      const res = await instance.post(url);

      const allResults = [];
      if (res.data && res.data.results) {
        res.data.results.forEach((result) => {
          allResults.push({
            name: result.name,
            category: 'place',
            id: result.place_id,
            img: result.icon,
          });
        });
      }
      await saveLastKnowPosition(lastKnowPosition);
      await saveNearLocations(allResults);
      await saveEventInRadius(false);
      setNearLocations(allResults);
    }
  }, [lastKnowPosition]);

  useEffect(() => {
    getNearLocations().catch((e) => console.log(e));
  }, [getNearLocations]);

  return { nearLocations };
};

export default useNearLocations;
