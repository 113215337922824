import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DefaultModal from '../molecules/DefaultModal';
import Input from '../atoms/Input';

const GroupPasswordModal = ({
  visible, password, onConfirm, onCancel,
}) => {
  const [text, setText] = useState('');
  const [errorText, setErrorText] = useState('');

  return (
    <DefaultModal
      visible={visible}
      title="Entrada do grupo"
      description="Para entrar no grupo é necessário informar a senha"
      onCancel={() => onCancel()}
      onConfirm={() => {
        if (text === password) {
          onConfirm();
        } else {
          setErrorText('A senha informada é inválida');
        }
      }}
    >
      <Input
        value={text}
        placeHolder="Digite a senha do grupo"
        errorText={errorText}
        onChangeText={(value) => {
          setText(value);
          if (value === password) {
            onConfirm();
          }
        }}
        secureTextEntry
      />
    </DefaultModal>
  );
};

GroupPasswordModal.propTypes = {
  password: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default React.memo(GroupPasswordModal);
