import { SafeAreaView } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import React, { useEffect, useState } from 'react';
import DropDown from '../atoms/DropDown';
import Title from '../atoms/Title';
import Spacer from '../atoms/Spacer';
import Persistence from '../../state/constants/Persistence';
import LocationSearchRadius from '../../state/constants/LocationSearchRadius';
import alert from '../../utils/Alert'

const DistanceConfiguration = () => {
  const [
    radiusLocationPreference,
    getRadiusLocationPreference,
  ] = useState(LocationSearchRadius.MEDIUM);

  useEffect(() => {
    (async function retrieveData() {
      getRadiusLocationPreference(
        await AsyncStorage.getItem(Persistence.CONFIGURED_LOCATION_RADIUS),
      );
    }());
  }, []);

  async function saveAccuracyPreference(preference) {
    try {
      await AsyncStorage.setItem(Persistence.CONFIGURED_LOCATION_RADIUS, preference);
      await AsyncStorage.setItem(
        Persistence.CONFIGURED_LOCATION_RADIUS_EVENT, JSON.stringify(true),
      );
      getRadiusLocationPreference(preference);
    } catch (error) {
      alert('Algo inesperado ocorreu ao definir a distância de busca');
    }
  }

  const optionsToSelect = [
    {
      label: 'Mais próxima',
      value: LocationSearchRadius.SMALL,
    },
    {
      label: 'Média',
      value: LocationSearchRadius.MEDIUM,
    },
    {
      label: 'Mais distante',
      value: LocationSearchRadius.BIG,
    },
  ];

  return (
    <SafeAreaView style={{
      flex: 1,
      paddingHorizontal: 20,
      paddingTop: 30,
    }}
    >
      <Title style={{paddingTop: 20}} bold primary>Preferências de busca</Title>
      <Spacer size={10} />
      <DropDown
        title="Selecionar distância"
        selectedValue={
          !radiusLocationPreference ? LocationSearchRadius.MEDIUM : radiusLocationPreference
        }
        onValueChange={(preference) => saveAccuracyPreference(preference)}
        options={optionsToSelect}
      />
    </SafeAreaView>
  );
};

export default DistanceConfiguration;
