import { useEffect, useState } from 'react';
import * as SplashScreen from 'expo-splash-screen';
import { fireAuth } from '../../utils/FirebaseSettings';
import { save } from '../../utils/PersistenceHandler';
import persistence from '../constants/Persistence';

const useAuthWatcher = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);
  const [currentUser, setUser] = useState(undefined);

  useEffect(() => {
    const unsubscribe = fireAuth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setIsAuthenticated(true);
        save(persistence.IS_AUTHENTICATED, true)
      } else {
        setIsAuthenticated(false);
        save(persistence.IS_AUTHENTICATED, false)
      }
    },
    () => {
      SplashScreen.hideAsync().then();
    });
    return () => { unsubscribe(); };
  }, []);
  return { isAuthenticated, currentUser };
};

export default useAuthWatcher;
