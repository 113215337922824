import { useCallback, useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Persistence from '../constants/Persistence';

const useSavedPlaces = () => {
  const [savedPlaces, setSavedPlaces] = useState([]);

  const getSavedPlaces = useCallback(async () => {
    const places = await JSON.parse(await AsyncStorage.getItem(Persistence.PLACES));
    if (places) {
      setSavedPlaces(places);
    }
  }, []);

  useEffect(() => {
    getSavedPlaces().then();
  }, [getSavedPlaces]);

  return { savedPlaces };
};

export default useSavedPlaces;
