import React from 'react';
import styled from 'styled-components/native';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import MainTheme from '../../state/constants/Colors';

const DateText = styled.Text`
  font-size: 12;
  text-align: center;
  padding: 20px;
  color: ${MainTheme.secondary};
  font-family: Montserrat;
`;

const DateView = ({ date }) => (
  <View>
    <DateText>{date}</DateText>
  </View>
);

DateView.propTypes = {
  date: PropTypes.string.isRequired,
};

export default DateView;
