import React from 'react';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import { View, Image } from 'react-native';
import MainTheme from '../../state/constants/Colors';
import Routes from '../routes/Routes';
import Subtitle from '../atoms/SubTitle';

const StyledWrap = styled.TouchableOpacity`
    align-items: center;
    background:#fff;
    border-width: ${(props) => (props.isHighlighted ? '1' : '0')};
    elevation: ${(props) => (props.isHighlighted ? '5' : '2')};
    border-color: ${MainTheme.primary};
    border-radius: 10;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
`;

const ImgWrap = styled.View`
    align-items: center;
    background: #F2F2F2;
    border-radius: 100;
    justify-content: center;
    min-width: 50;
    min-height: 50;
    margin-right: 10px;
`;

const LocationItem = ({
  title, source, navigation, parentId, groupData,
}) => (
  <StyledWrap
    onPress={() => {
      navigation.navigate(Routes.GROUP_CHAT, {
        parentId: parentId,
        groupData: groupData,
        name: groupData.name,
      });
    }}
  >
    <ImgWrap>
      <Image style={{ height: 25, width: 25 }} source={source} />
    </ImgWrap>
    <View style={{ flex: 1, justifyContent: 'center' }}>
      <Subtitle numberOfLines={1} style={{ flexWrap: 'wrap', marginRight: 10 }} bold lite>
        {title}
      </Subtitle>
    </View>
  </StyledWrap>
);

LocationItem.propTypes = {
  title: PropTypes.any.isRequired,
  source: PropTypes.any.isRequired,
  navigation: PropTypes.object.isRequired,
  parentId: PropTypes.string.isRequired,
  groupData: PropTypes.any.isRequired,
};

export default LocationItem;
