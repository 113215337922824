import React, { useEffect, useState } from 'react';
import {
  FlatList, Modal, TouchableOpacity, View, Platform,
} from 'react-native';
import _ from 'lodash';
import Constants from 'expo-constants';
import PropTypes from 'prop-types';
import Row from '../atoms/Row';
import DefaultInput from '../atoms/DefaultInput';
import MainTheme from '../../state/constants/Colors';
import CategoryOptionItem from '../molecules/CategoryOptionItem';
import Title from '../atoms/Title';

const CheckItemsModal = ({
  visible,
  onRequestClose,
  data,
  sendPayload,
  initialSelected,
}) => {
  const [category, setCategory] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState([]);
  const [filter, setFilter] = useState('');
  const [selectedItems, setSelectedItems] = useState({});

  useEffect(() => {
    const newSelectedItems = {};
    const initialSelectedIds = initialSelected.map((item) => item.id);
    data.forEach((item) => {
      newSelectedItems[item.id] = !!initialSelectedIds.includes(item.id);
    });

    setSelectedItems(newSelectedItems);
    setCategory(data);
  }, [data, initialSelected]);

  useEffect(() => {
    let newCategory = category;
    if (filter && filter.trim()) {
      newCategory = category.filter((currentCategory) => (
        currentCategory.data ? currentCategory.data.includes(filter) : false
      ));
    }
    setFilteredCategory(newCategory);
  }, [category, filter]);

  const selectCategory = (id) => {
    const newSelectedItems = _.cloneDeep(selectedItems);
    newSelectedItems[id] = !newSelectedItems[id];
    setSelectedItems(newSelectedItems);
  };

  const selectionFilter = () => {
    const allSelectedItems = [];
    Object.keys(selectedItems).forEach(
      (key) => !!selectedItems[key] && allSelectedItems.push(key),
    );
    const selectedCategories = category.filter((res) => allSelectedItems.includes(res.id));
    sendPayload(selectedCategories);
    onRequestClose();
  };

  return (
    <Modal
      animationType="slide"
      transparent={false}
      visible={visible}
      onRequestClose={onRequestClose}
    >
      <Row
        style={{
          width: `${100}%`,
          zIndex: 2,
          paddingHorizontal: 15,
          paddingTop: Platform.OS == "web" ? 32 : Constants.statusBarHeight,
        }}
      >
        <View style={{ flex: 1 }}>
          <DefaultInput
            placeholder="Pesquisar"
            small
            style={{ borderColor: MainTheme.border, marginBottom: 10 }}
            onChangeText={(text) => setFilter(text)}
          />
        </View>
        <TouchableOpacity
          style={{ marginLeft: 8 }}
          onPress={selectionFilter}
        >
          <Title primary bold>Concluir</Title>
        </TouchableOpacity>
      </Row>
      <FlatList
        data={filteredCategory}
        style={{ padding: 15 }}
        keyExtractor={(item) => item.id}
        ListFooterComponent={<View style={{ height: 50 }} />}
        renderItem={({ item }) => (
          <CategoryOptionItem
            category={item.data}
            checked={!!selectedItems[item.id]}
            onPress={() => selectCategory(item.id)}
          />
        )}
      />
    </Modal>
  );
};

CheckItemsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  sendPayload: PropTypes.func.isRequired,
  initialSelected: PropTypes.any,
};

CheckItemsModal.defaultProps = {
  initialSelected: [],
};

export default React.memo(CheckItemsModal);
