import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import {
  FlatList,
  SafeAreaView,
  Platform,
} from 'react-native';
import alert from '../../utils/Alert'
import PropTypes from 'prop-types';
import {
  deleteUser,
  startPrivateMessageForUser, startUserSnapshot,
} from 'middleware/src/database/users';
import {
  startGroupUsersSnapshot,
  setAdmin,
  checkUserIsGroupOwner,
} from 'middleware/src/database/groups';
import _ from 'lodash';
import { fireAuth } from '../../utils/FirebaseSettings';
import Participant from '../molecules/Participant';
import Loading from '../atoms/Loading';
import Routes from '../routes/Routes';
import NumberParticipantsIndicator from '../molecules/NumberParticipantsIndicator';
import useGroupAdminChecker from '../../state/hooks/useGroupAdminChecker';

const GroupParticipants = ({ navigation }) => {
  const groupId = navigation.getParam('groupId');
  const currentUser = fireAuth.currentUser;
  const [users, setUsers] = useState([]);
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [blockedMeUsers, setBlockedMeUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const userGroupOwner = useCallback(
    async (groupId, userId) => checkUserIsGroupOwner(groupId, userId), [],
  );

  const {
    admin,
  } = useGroupAdminChecker(groupId, currentUser && currentUser.uid);

  const showAlertForDeleteUser = async (userId) => {
    setLoading(true);
    const owner = await userGroupOwner(groupId, userId);

    if (owner) {
      alert(
        'Remover usuário',
        'Não é possivel remover o usuário criador do grupo',
        [
          {
            text: 'Fechar',
            onPress: () => setLoading(false),
          },
        ],
      );
    } else {
      alert(
        'Tem certeza que deseja remover?',
        'O usuário só conseguirá retornar para o grupo quando estiver próximo a localização ou através do link do grupo.',
        [
          {
            text: 'Cancelar',
            onPress: () => setLoading(false),
          },
          {
            text: 'Remover',
            onPress: async () => deleteUser(userId, groupId).catch(() => {
              alert('Aviso', 'Não foi possível remover o usuário');
            }),
          },
        ],
      );
    }
  };

  const showAlertForSetAdmin = async (userId, admin) => {
    setLoading(true);
    const defineOrRemove = !admin ? 'definir' : 'remover';
    const owner = await userGroupOwner(groupId, userId);

    if (owner) {
      alert(
        'Remover como adminstrador',
        'Não é possível remover a permissão de administrador do criador do grupo',
        [
          {
            text: 'Fechar',
            onPress: () => setLoading(false),
          },
        ],
      );
    } else {
      alert(
        `${_.capitalize(defineOrRemove)} como administrador`,
        `Tem certeza que deseja ${defineOrRemove} o usuário como administrador?`,
        [
          {
            text: 'Cancelar',
            onPress: () => setLoading(false),
          },
          {
            text: 'Confirmar',
            onPress: async () => setAdmin(userId, groupId, !admin),
          },
        ],
      );
    }
  };

  useEffect(() => {
    startGroupUsersSnapshot(groupId, (allUsers) => {
      setUsers(allUsers);
      setLoading(false);
    });
  }, [groupId]);

  useEffect(() => {
    startUserSnapshot(fireAuth.currentUser.uid, (user) => {
      if (user.blockedUsers) {
        setBlockedUsers(user.blockedUsers);
      }
      if (user.blockedMeUsers) {
        setBlockedMeUsers(user.blockedMeUsers);
      }
    });
    return () => { };
  }, []);

  const userNotBlocked = useMemo(
    () => (userId) => !blockedUsers.includes(userId) && !blockedMeUsers.includes(userId),
    [blockedMeUsers, blockedUsers],
  );

  const userNotLogged = useMemo(() => (
    itemId,
  ) => itemId !== fireAuth.currentUser.uid, []);

  const startChat = (item) => {
    startPrivateMessageForUser(item.id, item.username)
      .then((data) => {
        navigation.navigate(Routes.PRIVATE_CHAT, {
          foreignUserId: item.id,
          foreignName: item.username,
          token: data && data.result,
        });
      })
      .catch(() => alert('Aviso', 'Não foi possível processar a solicitação!'));
  };

  const renderItem = (item) => (
    <Participant
      username={item.username}
      name={item.name}
      imageUrl={item.avatarUrl}
      professions={item.professions}
      itemUserAdmin={item.admin}
      currentUserAdmin={admin}
      enableActionsForAdmin={userNotLogged(item.id)}
      profileImageVisible={userNotBlocked(item.id) && item.avatarUrl}
      openProfile={() => navigation.navigate(Routes.PROFILE, { userId: item.id })}
      startChat={() => startChat(item)}
      deleteUser={() => userNotLogged(item.id) && showAlertForDeleteUser(item.id)}
      setAdmin={() => showAlertForSetAdmin(item.id, item.admin)}
      canToTalk={(userNotBlocked(item.id) && userNotLogged(item.id))}
    />
  );

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {
        loading && <Loading desc="Buscando participantes..." />
      }
      <FlatList
        ListHeaderComponent={(
          <NumberParticipantsIndicator
            canEnable={!loading} 
            amount={users.length}
          />
        )}
        removeClippedSubviews={Platform.OS === 'android'}
        initialNumToRender={5}
        maxToRenderPerBatch={5}
        updateCellsBatchingPeriod={100}
        windowSize={10}
        data={users}
        contentContainerStyle={{ padding: 10 }}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => renderItem(item)}
      />
    </SafeAreaView>
  );
};

GroupParticipants.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default GroupParticipants;
