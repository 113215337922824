import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { View, KeyboardAvoidingView } from 'react-native';
import Title from '../atoms/Title';
import Spacer from '../atoms/Spacer';
import { fireAuth } from '../../utils/FirebaseSettings';
import DefaultHeader from '../molecules/DefaultHeader';
import Input from '../atoms/Input';
import ButtonColored from '../molecules/ButtonColored';
import Wrapper from '../atoms/Wrapper';
import alert from '../../utils/Alert'

const RecoverPassword = ({ navigation }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const recoverPassword = async () => {
    setLoading(true)
    if (!email) {
      alert('Aviso', 'Por favor, digite um email válido');
      setLoading(false);
      return;
    }

    try {
      await fireAuth.sendPasswordResetEmail(email);
      alert(
        'Solicitação Enviada',
        'Verifique a caixa de entrada do seu email. Você receberá um email com as instruções para recuperar a senha!',
      );
      setLoading(false);
    } catch (error) {
      alert('Aviso', 'Problema ao processar solicitação, verifique sua conexão à internet');
      setLoading(false);
    }
  };

  return (
    <Wrapper style={{ justifyContent: 'space-between', padding: 25 }}>
      <View style={{ flex: 1 }}>
        <KeyboardAvoidingView style={{ flex: 1 }} enabled>
          <DefaultHeader
            title="Recuperar Acesso"
            goBack={true}
            onPress={() => navigation.navigate('Login')}
          />
          <Spacer size={64} />
          <Title lite style={{ textAlign: 'center' }}>
            Digite seu email cadastrado para recuperar o acesso ao Local Chat
          </Title>
          <Spacer size={64} />
          <View style={{ flex: 1, paddingStart: 32, paddingEnd: 32 }}>
            <Input
              autoFocus
              value={email}
              onChangeText={setEmail}
              textContentType="emailAddress"
              autoCapitalize="none"
              placeholder="Email cadastrado"
              keyboardType="email-address"
              autoComplete="true"
              returnKeyType="next"
              onSubmitEditing={recoverPassword}
            />
            <ButtonColored
              style={{ marginTop: 8 }}
              loading={loading}
              text="Enviar solicitação"
              onPress={recoverPassword}
            />
          </View>
        </KeyboardAvoidingView>
      </View>
    </Wrapper>
  );
};

RecoverPassword.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default RecoverPassword;
