import React from 'react';
import styled, { css } from 'styled-components/native';
import { View, Platform } from 'react-native';
import PropTypes from 'prop-types';
import MainTheme from '../../state/constants/Colors';
import StyledText from '../atoms/StyledText';

const Wrap = styled.View`
    padding: 5px 20px;
    border-width: 1;
    border-radius: 20;
    border-color: ${MainTheme.border};
    background: #ffffff;
    margin: 3px;
    flex-shrink: 1;
`;

const webStyles = css`
  word-wrap: break-word;
  overflow: hidden;
`;

const StyledTextWrapper = styled(StyledText)`
  flex-shrink: 1;
  flex-wrap: wrap;
  ${Platform.OS === 'web' && webStyles}
`;

const ProfileData = ({ data, style }) => (
  <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
    {data.map ? data.map((item) => (
      <Wrap style={style} key={item.id}>
        <StyledTextWrapper>{item.data}</StyledTextWrapper>
      </Wrap>
    )) : <></>}
  </View>
);
ProfileData.propTypes = {
  data: PropTypes.any.isRequired,
  style: PropTypes.object,
};
ProfileData.defaultProps = {
  style: null,
};

export default ProfileData;
