import { useCallback, useEffect, useState } from 'react';
import alert from '../../utils/Alert'
import * as Permissions from 'expo-permissions';

const useLocationPermission = () => {
  const [locationPermission, setLocationPermission] = useState(undefined);

  const showPermissionDialog = (action) => {
    alert(
      'Localização',
      'Por favor, habilite a permissão de localização para que '
      + 'possamos encontrar grupos próximos a você',
      [
        {
          text: 'Confirmar',
          onPress: action(),
        },
      ],
      { cancelable: false },
    );
  };

  const requestLocationPermission = useCallback(async () => {
    const permissionResult = await Permissions.getAsync(Permissions.LOCATION);
    if (permissionResult.status !== 'granted') {
      showPermissionDialog(async () => {
        const askPermission = await Permissions.askAsync(Permissions.LOCATION);
        if (askPermission.status === 'granted') {
          setLocationPermission(true);
        }
      });
    } else {
      setLocationPermission(true);
    }
  }, []);

  useEffect(() => {
    requestLocationPermission().catch((e) => console.log(e));
  }, [requestLocationPermission]);

  return { locationPermission };
};

export default useLocationPermission;
