import React from 'react';
import { Image, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import Row from '../atoms/Row';
import ArrowBackIcon from '../../../assets/images/arrow-back-icon.png';
import Title from '../atoms/Title';
import BottomShadowView from '../atoms/BottomShadowView';

const PrivateChatHeader = ({
  title,
  pressOnTitle,
  pressOnBack,
}) => (
  <>
    <Row
      style={{
        alignItems: 'center',
        paddingVertical: 15,
        paddingHorizontal: 10,
      }}
    >
      <TouchableOpacity
        onPress={() => pressOnBack()}
        style={{
          width: '10%',
          alignItems: 'center',
        }}
      >
        <Image
          style={{
            height: 30,
            width: 30,
          }}
          source={ArrowBackIcon}
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => pressOnTitle()}
        style={{ width: '85%' }}
      >
        <Title numberOfLines={1} style={{ flexWrap: 'wrap', marginLeft: 10 }} bold>
          {title}
        </Title>
      </TouchableOpacity>
    </Row>
    <BottomShadowView />
  </>
);

PrivateChatHeader.propTypes = {
  title: PropTypes.string.isRequired,
  pressOnTitle: PropTypes.func.isRequired,
  pressOnBack: PropTypes.func.isRequired,
};

export default React.memo(PrivateChatHeader);
