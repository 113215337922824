import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-native';
import Title from '../atoms/Title';
import Row from '../atoms/Row';
import Spacer from '../atoms/Spacer';
import Photos from '../../state/constants/Galery';

const NumberParticipantsIndicator = ({
    amount,
    canEnable
}) => canEnable ? (
    <Row style={{ alignItems: 'center', marginBottom: 8 }}>
        <Spacer vertical size={4} />
        <Image
            style={{
                height: 30,
                width: 30,
            }}
            source={Photos.participantsIcon}
        />
        <Title bold lite>{amount}</Title>
    </Row>
) : <></>;

NumberParticipantsIndicator.propTypes = {
    canEnable: PropTypes.bool.isRequired,
  };

export default NumberParticipantsIndicator;
