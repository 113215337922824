import { View } from 'react-native';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Title from '../atoms/Title';
import AppCard from '../atoms/AppCard';
import ProfileData from '../molecules/ProfileData';
import DefaultButton from '../atoms/DefaultButton';
import CheckItemsModal from './CheckItemsModal';
import Spacer from '../atoms/Spacer';

const ProfileHobbiesAndSkills = ({
  editing,
  allHobbiesAndSkills,
  hobbiesAndSkills,
  onChange,
}) => {
  const [hobbiesAndSkillsModal, setOpenHobbiesAndSkillsModal] = useState(false);

  return (
    <>
      <Title lite primary bold>Hobbies e Habilidades</Title>
      <AppCard style={{
        paddingHorizontal: 15,
        paddingVertical: 10,
      }}
      >
        <ProfileData data={hobbiesAndSkills} />
      </AppCard>
      {
        !!editing && (
          <View style={{
            width: 100,
            marginTop: 15,
          }}
          >
            <DefaultButton
              lite
              style={{
                backgroundColor: '#FFC825',
                borderColor: '#FFFFFF',
              }}
              title="Editar"
              onPress={() => setOpenHobbiesAndSkillsModal(true)}
            />
          </View>
        )
      }
      {
        !!allHobbiesAndSkills && allHobbiesAndSkills.length > 0 && (
          <CheckItemsModal
            visible={hobbiesAndSkillsModal}
            onRequestClose={() => setOpenHobbiesAndSkillsModal(false)}
            sendPayload={(data) => onChange(data)}
            data={allHobbiesAndSkills}
            initialSelected={hobbiesAndSkills}
          />
        )
      }
      <Spacer size={10} />
    </>
  );
};

ProfileHobbiesAndSkills.propTypes = {
  editing: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  allHobbiesAndSkills: PropTypes.array.isRequired,
  hobbiesAndSkills: PropTypes.array.isRequired,
};

export default React.memo(ProfileHobbiesAndSkills);
