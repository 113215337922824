import { View } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

const ButtonBorderless = ({
  text, onPress, primaryColor, ...rest
}) => (
  <View style={{ alignItems: 'center', marginBottom: 16 }} {...rest}>
    <Button
      text={text}
      onPress={onPress}
      primaryColor={primaryColor}
      customElevation="0px"
      {...rest}
    />
  </View>
);

ButtonBorderless.propTypes = {
  text: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  primaryColor: PropTypes.bool,
};

ButtonBorderless.defaultProps = {
  primaryColor: true,
};

export default React.memo(ButtonBorderless);
