import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  View, SafeAreaView, ScrollView, KeyboardAvoidingView,
} from 'react-native';
import { usersCollection } from 'middleware/src/database/collections';
import Title from '../atoms/Title';
import DefaultButton from '../atoms/DefaultButton';
import Spacer from '../atoms/Spacer';
import ProfileData from '../molecules/ProfileData';
import { getAllHobbiesAndSkills, getAllProfessions } from '../../utils/CollectionUtils';
import CheckItemsModal from '../organisms/CheckItemsModal';
import AppCard from '../atoms/AppCard';
import { fireAuth } from '../../utils/FirebaseSettings';
import Routes from '../routes/Routes';
import alert from '../../utils/Alert'

const KnowingTheUser = ({ navigation }) => {
  const [professions, setProfessions] = useState([]);
  const [hobbiesAndSkills, setHobbiesAndSkills] = useState([]);
  const [selectedProfession, setSelectedProfession] = useState([]);
  const [selectedHobbiesAndSkills, setSelectedHobbiesAndSkills] = useState([]);
  const [hobbiesAndSkillsModal, setOpenHobbiesAndSkillsModal] = useState(false);
  const [professionsModal, setOpenProfessionsModal] = useState(false);
  const { uid } = fireAuth.currentUser;

  const getData = () => {
    getAllProfessions().then((result) => setProfessions(result));
    getAllHobbiesAndSkills().then((result) => setHobbiesAndSkills(result));
  };

  useEffect(() => {
    getData();
  }, []);

  const goToNextStep = async () => {
    if (setSelectedProfession.length <= 0 || selectedHobbiesAndSkills.length <= 0) {
      alert('Atenção',
        'Por favor, selecione ao menos um item de cada categoria.');
      return null;
    }
    await usersCollection().doc(uid).update({
      professions: selectedProfession,
      hobbiesAndSkills: selectedHobbiesAndSkills,
    }).then(() => navigation.navigate(Routes.THERMS))
      .catch(() => alert('Aviso',
        'Problema ao processar solicitação, verifique sua conexão à internet'));
    return true;
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior="padding"
        enabled
      >
        <ScrollView contentContainerStyle={{
          marginTop: 30, paddingHorizontal: 30, marginVertical: 20,
        }}
        >
          <Title bold lite primary>Nos conte um pouco sobre você:</Title>
          <View style={{ padding: 15, justifyContent: 'space-evenly' }}>
            <Title bold lite>Profissão:</Title>
            {
              selectedProfession.length > 0 && (
                <AppCard style={{ paddingHorizontal: 15, paddingVertical: 10 }}>
                  <ProfileData data={selectedProfession} />
                </AppCard>
              )
            }
            <View style={{ width: 150, marginTop: 10 }}>
              <DefaultButton
                title="Selecionar"
                onPress={() => setOpenProfessionsModal(true)}
              />
            </View>
            {
              !!professions && professions.length > 0 && (
                <CheckItemsModal
                  visible={professionsModal}
                  onRequestClose={() => setOpenProfessionsModal(false)}
                  sendPayload={(data) => setSelectedProfession(data)}
                  data={professions}
                />
              )
            }
            <Spacer size={50} />
            <Title bold lite>Hobbies e Habilidades:</Title>
            {
              selectedHobbiesAndSkills.length > 0 && (
                <AppCard style={{ paddingHorizontal: 15, paddingVertical: 10 }}>
                  <ProfileData data={selectedHobbiesAndSkills} />
                </AppCard>
              )
            }
            <View style={{ width: 150, marginTop: 15 }}>
              <DefaultButton
                title="Selecionar"
                onPress={() => setOpenHobbiesAndSkillsModal(true)}
              />
            </View>
            {
              !!hobbiesAndSkills && hobbiesAndSkills.length > 0 && (
                <CheckItemsModal
                  visible={hobbiesAndSkillsModal}
                  onRequestClose={() => setOpenHobbiesAndSkillsModal(false)}
                  sendPayload={(data) => setSelectedHobbiesAndSkills(data)}
                  data={hobbiesAndSkills}
                />
              )
            }
            <Spacer size={5} />
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
      <View style={{ paddingBottom: 20, paddingHorizontal: 20 }}>
        <DefaultButton
          lite
          title="Continuar"
          style={{
            backgroundColor: '#FFC825',
            borderWidth: 0,
            borderRadius: 10,
            padding: 20,
            elevation: 1,
          }}
          onPress={goToNextStep}
        />
      </View>
    </SafeAreaView>
  );
};
KnowingTheUser.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default KnowingTheUser;
