import { View } from 'react-native';
import React from 'react';

const BottomShadowView = () => (
  <View style={{
    overflow: 'hidden',
    paddingBottom: 1,
  }}
  >
    <View
      style={{
        backgroundColor: '#ffffff',
        width: '100%',
        height: 1,
        shadowColor: '#000',
        shadowOffset: {
          width: 1,
          height: 1,
        },
        shadowOpacity: 0.1,
        shadowRadius: 3,
        elevation: 1,
      }}
    />
  </View>
);

export default BottomShadowView;
