import React from 'react';
import { createStackNavigator } from 'react-navigation-stack';
import GreetingPage from '../pages/GreetingPage';
import GreetingText from '../pages/GreetingText';
import CategorySelection from '../pages/CategorySelection';
import KnowingTheUser from '../pages/KnowingTheUser';
import mainTheme from '../../state/constants/Colors';
import ThermsOfUse from '../pages/ThermsOfUse';
import DefaultHeader from '../molecules/DefaultHeader';
import MainStackNavigator from './MainStackNavigator';

const GreetingStack = createStackNavigator({
  GreetingPage: {
    screen: GreetingPage,
    navigationOptions: {
      headerLeft: null,
      header: null,
    },
  },
  GreetingText: {
    screen: GreetingText,
    navigationOptions: ({ navigation }) => ({
      header: <DefaultHeader goBack title="Seja bem-vindo" onPress={() => navigation.goBack()} />,
    }),
  },
  CategorySelection: {
    screen: CategorySelection,
    navigationOptions: ({ navigation }) => ({
      header: <DefaultHeader goBack title="Teus interesses" onPress={() => navigation.goBack()} />,
    }),
  },
  KnowingTheUser: {
    screen: KnowingTheUser,
    navigationOptions: ({ navigation }) => ({
      header: <DefaultHeader goBack title="Sobre ti" onPress={() => navigation.goBack()} />,
    }),
  },
  Therms: {
    screen: ThermsOfUse,
    navigationOptions: ({ navigation }) => ({
      header: <DefaultHeader goBack title="Termos de uso" onPress={() => navigation.goBack()} />,
    }),
  },
  MainStackNavigator: {
    screen: MainStackNavigator,
    navigationOptions: ({ navigation }) => ({
      headerLeft: null,
      header: null,
    }),
  }
},
{
  initialRouteName: 'GreetingPage'
},
{
  defaultNavigationOptions: {
    header: null,
    headerTitleStyle: { color: mainTheme.primary, fontFamily: 'Montserrat' },
    headerStyle: {
      elevation: 2,
      shadowOpacity: 2,
      borderBottomColor: 'transparent',
    },
  },
});

export default GreetingStack;
