import React from 'react';
import PropTypes from 'prop-types';
import Spacer from '../atoms/Spacer';
import Title from '../atoms/Title';
import SubGroups from './SubGroups';

const SubGroupsInfo = ({ subGroups, onClickItem }) => (
  <>
    { subGroups.length !== 0 && (
      <>
        <Spacer size={12} />
        <Title
          bold
          primary
          lite
        >
          Subgrupos disponíveis
        </Title>
        <Spacer size={12} />
        <SubGroups
          subGroups={subGroups}
          onClickItem={onClickItem}
        />
      </>
    )}
  </>
);

SubGroupsInfo.propTypes = {
  subGroups: PropTypes.array.isRequired,
  onClickItem: PropTypes.func.isRequired,
};

export default React.memo(SubGroupsInfo);
