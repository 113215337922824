import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import MessageInput from '../atoms/MessageInput';
import Row from '../atoms/Row';
import Photos from '../../state/constants/Galery';

const SendButton = styled.Image`
  height: 30;
  width: 30;
`;

const SendMessage = ({
  message,
  onPress,
  sendImage,
  canSendImage,
  placeholder,
  onChangeText,
}) => {
  const [connected, setConnected] = useState(true);

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      setConnected(state.isConnected);
    });

    return () => unsubscribe();
  }, []);

  return (
    <View>
      { connected && (
        <Row 
        style={{
   
          width: '100%',
          alignItems: 'center',
          paddingHorizontal: 5,
        }}
        >
          <MessageInput
            style={{
              flex: 1,
            }}
            maxLength={5000}
            multiline
            small={16}
            placeholder={placeholder}
            onChangeText={onChangeText}
            value={message}
          />
          { canSendImage && (
            <TouchableOpacity
              onPress={sendImage}
              style={{ paddingRight: 5 }}
            >
              <SendButton source={Photos.photoLibrary} />
            </TouchableOpacity>
          )}
          <TouchableOpacity
            onPress={onPress}
            style={{ padding: 10 }}
          >
            <SendButton source={Photos.sendMessage} />
          </TouchableOpacity>
        </Row>
      )}
    </View>
  );
};

SendMessage.propTypes = {
  onChangeText: PropTypes.func.isRequired,
  onPress: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  canSendImage: PropTypes.bool.isRequired,
  sendImage: PropTypes.func,
};

SendMessage.defaultProps = {
  sendImage: () => {},
};

export default React.memo(SendMessage);
