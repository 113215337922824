import styled from 'styled-components/native';
import { RFValue } from 'react-native-responsive-fontsize';
import MainTheme from '../../state/constants/Colors';

const color = (props) => {
  if (props.white) {
    return '#ffffff';
  }
  if (props.warning) {
    return MainTheme.error;
  }
  return props.primaryColor ? MainTheme.primary : MainTheme.secondary;
};

const StyledText = styled.Text`
    color: ${(props) => color(props)};
    font-size: ${(props) => (props.lite ? RFValue(12) : RFValue(16))};
    font-family: ${(props) => (props.bold ? 'MontserratBold' : 'Montserrat')};
`;

export default StyledText;
