import React from 'react';
import { createStackNavigator } from 'react-navigation-stack';
import DefaultHeader from '../molecules/DefaultHeader';
import GroupInfo from '../pages/GroupInfo';
import Profile from '../pages/Profile';
import InterestsScreen from '../pages/InterestsScreen';
import Settings from '../pages/Settings';
import FAQPage from '../pages/FAQPage';
import NotificationsScreen from '../pages/NotificationsScreen';
import DistanceConfiguration from '../pages/DistanceConfiguration';
import ViewThermsPage from '../pages/ViewThermsPage';
import MainTabNavigator from './MainTabNavigator';
import GroupParticipants from '../pages/GroupParticipants';
import PrivateChat from '../pages/PrivateChat';
import GroupChat from '../pages/GroupChat';
import EventCreator from '../pages/EventCreator';
import SubGroupCreator from '../pages/SubGroupCreator';
import LogoutScreen from '../pages/LogoutScreen';

const MainStackNavigator = createStackNavigator({
  Home: {
    screen: MainTabNavigator,
    navigationOptions: ({ navigation }) => ({
      header: () => (
        <DefaultHeader shouldDisplayLogo title="Local Chat" onPress={() => navigation.navigate('Home')} />
      ),
    }),
  },
  PrivateChat: {
    screen: PrivateChat,
  },
  GroupChat: {
    screen: GroupChat,
  },
  Profile: {
    screen: Profile,
    navigationOptions: ({ navigation }) => ({
      header: () => (
        <DefaultHeader goBack title="Perfil" onPress={() => navigation.goBack()} />
      ),
    }),
  },
  GroupInfo: {
    screen: GroupInfo,
    navigationOptions: ({ navigation }) => ({
      header: () => (
        <DefaultHeader goBack title="Detalhes do Grupo" onPress={() => navigation.goBack()} />
      ),
    }),
  },
  GroupParticipants: {
    screen: GroupParticipants,
    navigationOptions: ({ navigation }) => ({
      header: () => (
        <DefaultHeader goBack title="Participantes" onPress={() => navigation.goBack()} />
      ),
    }),
  },
  Settings: {
    screen: Settings,
    navigationOptions: ({ navigation }) => ({
      header: () => (
        <DefaultHeader goBack title="Configurações" onPress={() => navigation.goBack()} />
      ),
    }),
  },
  Interests: {
    screen: InterestsScreen,
    navigationOptions: ({ navigation }) => ({
      header: () => (
        <DefaultHeader goBack title="Interesses" onPress={() => navigation.goBack()} />
      ),
    }),
  },
  FAQPage: {
    screen: FAQPage,
  },
  Notifications: {
    screen: NotificationsScreen,
    navigationOptions: ({ navigation }) => ({
      header: () => (
        <DefaultHeader goBack title="Notificações" onPress={() => navigation.goBack()} />
      ),
    }),
  },
  DistanceConfiguration: {
    screen: DistanceConfiguration,
    navigationOptions: ({ navigation }) => ({
      header: () => (
        <DefaultHeader goBack title="Distância de busca" onPress={() => navigation.goBack()} />
      ),
    }),
  },
  ViewThermsPage: {
    screen: ViewThermsPage,
  },
  EventCreator: {
    screen: EventCreator,
    navigationOptions: ({ navigation }) => ({
      header: () => (
        <DefaultHeader goBack title="Detalhes do evento" onPress={() => navigation.goBack()} />
      ),
    }),
  },
  SubGroupCreator: {
    screen: SubGroupCreator,
    navigationOptions: ({ navigation }) => ({
      header: () => (
        <DefaultHeader goBack title="Criar subgrupo" onPress={() => navigation.goBack()} />
      ),
    }),
  },
  LogoutScreen: {
    screen: LogoutScreen,
    navigationOptions: ({ navigation }) => ({
      header: null, 
      headerLeft: null
    }),
  },
},
{
  defaultNavigationOptions: () => ({
    header: null,
    headerTitle: '',
    headerStyle: {
      elevation: 0,
      shadowOpacity: 0,
      borderBottomColor: 'transparent',
    },
  }),
});

export default MainStackNavigator;
