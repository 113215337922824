import { View } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import Spacer from '../atoms/Spacer';
import Title from '../atoms/Title';
import TextLink from './TextLink';
import Row from '../atoms/Row';
import DateHelper from '../../utils/DateHelper';

const EventInfo = ({ info }) => (
  <>
    { !!info && (
      <>
        <Spacer size={15} />
        <Title bold style={{ fontSize: 15 }}>Descrição do evento</Title>
        <TextLink style={{ fontSize: 17 }} text={info.desc} />
        <Row style={{ justifyContent: 'space-between' }}>
          <View>
            <Title bold style={{ marginTop: 15, fontSize: 15 }}>Data inicial</Title>
            <Title
              lite
              style={{ fontSize: 17 }}
            >
              { DateHelper.getDayMonthAndYear(info.initialDate.toDate()) }
            </Title>
          </View>
          <View>
            <Title bold style={{ marginTop: 15, fontSize: 15 }}>Hora inicial</Title>
            <Title
              lite
              style={{ fontSize: 17 }}
            >
              { DateHelper.getHourAndMinute(info.initialDate.toDate()) }
            </Title>
          </View>
        </Row>
        <Row style={{ justifyContent: 'space-between' }}>
          <View>
            <Title bold style={{ marginTop: 15, fontSize: 15 }}>Data final</Title>
            <Title
              lite
              style={{ fontSize: 17 }}
            >
              { DateHelper.getDayMonthAndYear(info.finalDate.toDate()) }
            </Title>
          </View>
          <View>
            <Title bold style={{ marginTop: 15, fontSize: 15 }}>Hora final</Title>
            <Title
              lite
              style={{ fontSize: 17 }}
            >
              { DateHelper.getHourAndMinute(info.finalDate.toDate()) }
            </Title>
          </View>
        </Row>
        <Spacer size={36} />
      </>
    )}
  </>
);

EventInfo.propTypes = {
  info: PropTypes.object.isRequired,
};

export default React.memo(EventInfo);
