import { useCallback, useEffect, useState } from 'react';
import { groupPresentIntoUser } from 'middleware/src/database/users';
import { groupIdByParentId } from 'middleware/src/database/groups';
import { fireAuth } from '../../utils/FirebaseSettings';

const useGroupParticipantChecker = (navigation, groupId, parentId, enterGroupLoading) => {
  const { currentUser } = fireAuth;
  const [participantStatus, setParticipantStatus] = useState(null);
  const [loadingParticipant, setLoadingParticipant] = useState(true);

  const [notParticipant, setNotParticipant] = useState(null);
  const [participant, setParticipant] = useState(null);
  const [participationError, setParticipationError] = useState(null);

  const updateUserParticipation = (status) => {
    setParticipantStatus(status);
    setLoadingParticipant(false);
  };

  useEffect(() => {
    setNotParticipant(participantStatus === false && loadingParticipant === false)
    setParticipant(participantStatus === true && loadingParticipant === false)
  }, [participantStatus, loadingParticipant]);

  const checkParticipation = useCallback(async () => {
    setParticipationError(null);
    if (!currentUser || !parentId || currentUser.uid == null) {
      return Promise.reject(new Error('currentUser or parentId are null'));
    }
    if (groupId) {
      try {
        const result = await groupPresentIntoUser(currentUser.uid, groupId);
        return { result };
      } catch (error) {
        console.error('2 - Error checking participation:', error);
        setParticipationError(error);
        return Promise.reject(error);
      }
    } else {
      try {
        const groupIdResult = await groupIdByParentId(parentId);
        if (groupIdResult === false) {
          console.log("group not exist" + groupIdResult)
          return { result: groupIdResult };
        }
        const result = await groupPresentIntoUser(currentUser.uid, groupIdResult)
        return { result };
      } catch (error) {
        console.error('1 - Error checking participation:', error);
        setParticipationError(error);
        return Promise.reject(error);
      }
    }
  }, [currentUser, groupId, parentId, enterGroupLoading]);

  useEffect(() => {
    const handleResult = ({ result }) => updateUserParticipation(result);
    checkParticipation().then(handleResult);
    navigation.addListener(
      'action', () => checkParticipation().then(handleResult));
  }, [checkParticipation, navigation]);

  return {
    participant,
    notParticipant,
    participationError,
  };
};

export default useGroupParticipantChecker;
