import DropIcon from '../../../assets/images/drop-icon.png';
import MapFocusedIcon from '../../../assets/gifs/map-focused-icon.gif';
import EventsIcon from '../../../assets/images/events-icon.png';
import MessagesIcon from '../../../assets/images/messages-icon.png';
import NotificationIcon from '../../../assets/images/notification-icon.png';
import PrivacyIcon from '../../../assets/images/privacy-icon.png';
import InterestsIcon from '../../../assets/images/interests-icon.png';
import SearchIcon from '../../../assets/images/search-icon.png';
import FocusedEventsIcon from '../../../assets/gifs/focused-events-icon.gif';
import MessagesFocusedIcon from '../../../assets/gifs/messages-focused-icon.gif';
import MapIcon from '../../../assets/images/map-icon.png';
import EditIcon from '../../../assets/images/pen.png';
import EditPrimaryIcon from '../../../assets/images/pen-yellow.png';
import RemoveIcon from '../../../assets/images/remove-icon.png';
import Close from '../../../assets/images/close.png';
import Check from '../../../assets/images/check.png';
import Info from '../../../assets/images/info-icon.png';
import MembersIcon from '../../../assets/images/members-icon.png';
import AttachmentIcon from '../../../assets/images/attachment-icon.png';
import SendMessageIcon from '../../../assets/images/send-message-icon.png';
import Send from '../../../assets/images/send.png';
import ArrowBackIcon from '../../../assets/images/arrow-back-icon.png';
import ArrowDownIcon from '../../../assets/images/arrow-down.webp';
import LogoFont from '../../../assets/images/logo-font.png';
import AddIcon from '../../../assets/images/add.png';
import Settings from '../../../assets/images/settings.png';
import ExploreIcon from '../../../assets/images/explore-icon.png';
import ProfileMenu from '../../../assets/images/profile.png';
import Profile from '../../../assets/images/profile-user.png';
import ProfileIconFocused from '../../../assets/images/profile-focused.png';
import TermsIcon from '../../../assets/images/terms.png';
import FaqIcon from '../../../assets/images/faq.png';
import RemoveUser from '../../../assets/images/remove.png';
import CloseEdit from '../../../assets/images/close-edit-grey.png';
import CheckEdit from '../../../assets/images/check-edit.png';
import FacebookIcon from '../../../assets/images/facebook.png';
import GoogleIcon from '../../../assets/images/google-icon.png';
import AppleIcon from '../../../assets/images/apple-logo.png';
import PersonSearching from '../../../assets/images/person-searching.png';
import PersonDontUnderstand from '../../../assets/images/person-dontunderstand.png';
import Share from '../../../assets/images/share.png';
import SubGroup from '../../../assets/images/subgroup.png';
import SetAdmin from '../../../assets/images/admin.png';
import CopyIcon from '../../../assets/images/copy.png';
import ReportIcon from '../../../assets/images/report.png';
import ParticipantsIcon from '../../../assets/images/participants-icon.png';

const Photos = {
  drop: DropIcon,
  event: EventsIcon,
  event_focused: FocusedEventsIcon,
  map_focused: MapFocusedIcon,
  map: MapIcon,
  notification: NotificationIcon,
  privacy: PrivacyIcon,
  sendMessage: SendMessageIcon,
  send: Send,
  search: SearchIcon,
  interests: InterestsIcon,
  message: MessagesIcon,
  message_focused: MessagesFocusedIcon,
  edit: EditIcon,
  editPrimary: EditPrimaryIcon,
  add: AddIcon,
  remove: RemoveIcon,
  check: Check,
  close: Close,
  info: Info,
  groups: MembersIcon,
  profileMenu: ProfileMenu,
  profile_focused: ProfileIconFocused,
  photoLibrary: AttachmentIcon,
  goBack: ArrowBackIcon,
  logoFont: LogoFont,
  settings: Settings,
  explore: ExploreIcon,
  terms: TermsIcon,
  faq: FaqIcon,
  profile: Profile,
  removeUser: RemoveUser,
  closeEdit: CloseEdit,
  checkEdit: CheckEdit,
  google: GoogleIcon,
  apple: AppleIcon,
  facebook: FacebookIcon,
  arrowDown: ArrowDownIcon,
  setAdmin: SetAdmin,
  share: Share,
  subGroup: SubGroup,
  copy: CopyIcon,
  report: ReportIcon,
  personSearching: PersonSearching,
  personDontUnderstand: PersonDontUnderstand,
  participantsIcon: ParticipantsIcon,
};

export default Photos;
