import React from 'react';
import {
  KeyboardAvoidingView,
  Modal,
  Platform,
  View,
} from 'react-native';
import PropTypes from 'prop-types';
import Constants from 'expo-constants';
import Title from '../atoms/Title';
import AppCard from '../atoms/AppCard';
import ButtonColored from './ButtonColored';
import ButtonBorderless from './ButtonBorderless';
import Row from '../atoms/Row';
import Subtitle from '../atoms/SubTitle';
import Spacer from '../atoms/Spacer';

const DefaultModal = ({
  title,
  description,
  visible,
  width,
  confirmText,
  cancelText,
  onCancel,
  onConfirm,
  disableOnCancel,
  ...rest
}) => (
  <Modal
    animationType="slide"
    transparent
    visible={visible}
    onRequestClose={onCancel}
  >
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={Platform.OS === 'ios' && 'padding'}
      keyboardVerticalOffset={Platform.OS === 'ios' && Constants.statusBarHeight}
    >
      <View style={{
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <AppCard style={{
          margin: 24,
          paddingHorizontal: 24,
          width,
        }}
        >
          <Spacer size={24} />
          <Title primary bold>{ title }</Title>
          <Spacer size={24} />
          { description && (
            <>
              <Subtitle>{description}</Subtitle>
              <Spacer size={24} />
            </>
          )}
          <View {...rest} />
          <Spacer size={24} />
          <Row style={{ justifyContent: 'flex-end' }}>
            { !disableOnCancel && (
              <>
                <ButtonBorderless text={cancelText} onPress={onCancel} />
                <Spacer size={12} vertical />
              </>
            )}
            <ButtonColored text={confirmText} onPress={onConfirm} />
          </Row>
          <Spacer size={8} />
        </AppCard>
      </View>
    </KeyboardAvoidingView>
  </Modal>
);

DefaultModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  width: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  disableOnCancel: PropTypes.bool,
};

DefaultModal.defaultProps = {
  width: undefined,
  description: undefined,
  cancelText: 'Cancelar',
  confirmText: 'Confirmar',
  onCancel: () => {},
  disableOnCancel: false,
};

export default React.memo(DefaultModal);
