import React from 'react';
import PropTypes from 'prop-types';
import DefaultButton from '../atoms/DefaultButton';

const ProfileButtons = ({
  openReportModal,
  askToBlockOrUnblockUser,
  isLoggedUser,
  userIsBlocked,
  startChat,
}) => (!isLoggedUser && (
  <>
    <DefaultButton
      onPress={() => startChat()}
      style={{
        padding: 10,
        marginBottom: 10,
      }}
      title="Chamar no privado"
    />
    <DefaultButton
      onPress={() => openReportModal()}
      style={{
        padding: 10,
        marginBottom: 10,
      }}
      title="Denunciar usuário"
    />
    <DefaultButton
      warningText
      onPress={() => askToBlockOrUnblockUser()}
      style={{
        padding: 10,
        marginBottom: 30,
      }}
      title={userIsBlocked ? 'Desbloquear usuário' : 'Bloquear usuário'}
    />
  </>
)
);

ProfileButtons.propTypes = {
  userIsBlocked: PropTypes.bool.isRequired,
  isLoggedUser: PropTypes.bool.isRequired,
  askToBlockOrUnblockUser: PropTypes.func.isRequired,
  openReportModal: PropTypes.func.isRequired,
  startChat: PropTypes.func.isRequired,
};

export default React.memo(ProfileButtons);
