import { View, Image } from 'react-native';
import PropTypes from 'prop-types';
import React from 'react';
import CheckedIcon from '../../../assets/images/checked-icon.png';
import MainTheme from '../../state/constants/Colors';

const Checkbox = ({ checked }) => (
  <View
    style={{
      height: 20,
      width: 20,
      borderWidth: 2,
      borderRadius: 3,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: checked ? MainTheme.primary : '#ffffff',
      borderColor: checked ? MainTheme.primary : MainTheme.secondary,
    }}
  >
    <Image
      style={{
        height: 15,
        width: 15,
      }}
      source={CheckedIcon}
    />
  </View>
);
Checkbox.propTypes = {
  checked: PropTypes.bool,
};
Checkbox.defaultProps = {
  checked: false,
};

export default Checkbox;
