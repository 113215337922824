import React, { useRef, useState } from 'react';
import {
  FlatList,
  SafeAreaView,
  Platform,
  View,
  useWindowDimensions,
} from 'react-native';
import alert from '../../utils/Alert'
import PropTypes from 'prop-types';
import { deleteMessage } from 'middleware/src/database/groupMessages';
import MessageBalloon from '../molecules/MessageBalloon';
import { fireAuth } from '../../utils/FirebaseSettings';
import dateHelper from '../../utils/DateHelper';
import MessageOptionsModal from '../molecules/MessageOptionsModal';
import ReportModal from '../molecules/ReportModal';
import NotFound from '../molecules/NotFound';
import Photos from '../../state/constants/Galery';

const MessageList = ({
  data,
  groupId,
  parentId,
  navigation,
  isParticipant,
  isPrivateMessage,
  isAdmin,
  topLimit,
  bottomLimit
}) => {
  const windowHeight = useWindowDimensions().height;
  const heightLimit = () => {
    const top = topLimit + 10;
    const bottom = bottomLimit;
    console.warn("windowHeight:" + windowHeight + "." + "TOP:" + top + "/" + "BOTTOM:" + bottom)
    return windowHeight - top - bottom;
  }

  const flatListRef = useRef('flatListRef');
  const { currentUser } = fireAuth;
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);

  const [denouncedId, setDenouncedId] = useState('');
  const [denouncedName, setDenouncedName] = useState('');
  const [messageId, setMessageId] = useState('');
  const [messageItem, setMessageItem] = useState('');

  const openOptionsModal = async ({
    id, userId, name, message,
  }) => {
    setMessageId(id);
    setDenouncedId(userId);
    setDenouncedName(name);
    setMessageItem(message);
    setShowOptionsModal(true);
  };

  const openReportModal = async () => {
    setShowOptionsModal(false);
    setShowReportModal(true);
  };

  const showAlertAndDeleteMessage = async () => {
    alert(
      'Deletar Mensagem',
      'Deseja realmente deletar essa mensagem?',
      [
        {
          text: 'Não',
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
        {
          text: 'Sim',
          onPress: () => deleteMessage(groupId, messageId)
            .then(() => {
              alert('Aviso', 'Mensagem apagada');
              setShowOptionsModal(false);
            }, () => {
              alert('Ops..', 'ocorreu algum erro ao deletar a mensagem');
              setShowOptionsModal(false);
            }),
        },
      ],
    );
  };

  const checkPreviousItemNotHaveSameDate = (
    item, previousItem,
  ) => previousItem && !dateHelper.isSameDay(
    item.createdAt, previousItem.createdAt,
  );

  const renderItem = (item, index) => (
    <MessageBalloon
      userId={item.userId}
      navigation={navigation}
      item={item}
      dateInHeader={dateHelper.getStringForDate(item.createdAt, new Date())}
      shouldDisplayDateInHeader={checkPreviousItemNotHaveSameDate(item, data[index + 1])}
      sent={currentUser && (item.userId === currentUser.uid)}
      isAdmin={isAdmin}
      openOptionsModal={() => openOptionsModal(item)}
      allowInteractions={isParticipant || isPrivateMessage}
    />
  );

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <MessageOptionsModal
        visible={showOptionsModal && isParticipant && !isPrivateMessage}
        admin={isAdmin}
        messageId={messageId}
        message={messageItem}
        onRequestClose={() => setShowOptionsModal(false)}
        openReportModal={openReportModal}
        deleteMessage={showAlertAndDeleteMessage}
        canReportMessage={currentUser && (denouncedId !== currentUser.uid)}
      />
      <ReportModal
        visible={showReportModal}
        onRequestClose={() => setShowReportModal(false)}
        messageId={messageId}
        denouncedId={denouncedId}
        denouncedName={denouncedName}
        reportType="message"
        parentId={parentId}
      />
      <FlatList
        removeClippedSubviews={Platform.OS === 'android'}
        initialNumToRender={5}
        maxToRenderPerBatch={5}
        updateCellsBatchingPeriod={10}
        windowSize={10}
        ref={flatListRef}
        inverted={data.length != 0}
        disableVirtualization={Platform.OS == "web"}
        style={{ height: Platform.OS == "web" ? heightLimit() : undefined }}
        data={data}
        contentContainerStyle={data.length === 0 ? { flex: 1, justifyContent: 'center' } : { paddingHorizontal: 15, paddingBottom: 10 }}
        keyExtractor={(item) => item.id}
        renderItem={({ item, index }) => renderItem(item, index)}
        ListEmptyComponent={() => (
          <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', padding: 16 }}>
            <NotFound
              title={isPrivateMessage ? "Agora é com você!" : "Seja o primeiro(a)"}
              desc={isPrivateMessage ? "Se apresente enviando a primeira mensagem." : "Envie a primeira mensagem da comunidade."}
              img={Photos.personSearching}
            />
          </View>
        )}
      />
    </SafeAreaView>
  );
};

MessageList.propTypes = {
  data: PropTypes.any.isRequired,
  groupId: PropTypes.string,
  navigation: PropTypes.any.isRequired,
  isParticipant: PropTypes.bool,
  isPrivateMessage: PropTypes.bool,
  parentId: PropTypes.string,
  isAdmin: PropTypes.bool,
  topLimit: PropTypes.number,
  bottomLimit: PropTypes.number
};

MessageList.defaultProps = {
  isPrivateMessage: false,
  isParticipant: false,
  isAdmin: false,
  parentId: undefined,
  groupId: undefined,
  topLimit: 0,
  bottomLimit: 0
};

export default React.memo(MessageList);
