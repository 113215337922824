import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { Image, TouchableOpacity } from 'react-native';
import MainTheme from '../../state/constants/Colors';

const ButtonContainer = styled.View`
  align-items: center;
  background: ${(props) => (props.colorPrimaryInBackground ? MainTheme.primary : '#ffffff')};
  border-color: ${MainTheme.secondary};
  border-radius: 20;
  border-width: 1;
  flex-direction: row;
  justify-content: center;
`;

const ButtonText = styled.Text`
  color: ${(props) => (props.warningText ? '#ff0000' : props.lite ? '#fff' : MainTheme.secondary)};
  font-size: 20;
  font-family: Montserrat;
`;

const DefaultButton = ({
  style, imgStyle, onPress, title, source, lite, warningText, colorPrimaryInBackground, ...rest
}) => (
  <TouchableOpacity onPress={onPress}>
    <ButtonContainer background={colorPrimaryInBackground} style={style} {...rest}>
      {source && (
        <Image style={imgStyle} source={source} />
      )}
      <ButtonText warningText={warningText} lite={lite}>{title}</ButtonText>
    </ButtonContainer>
  </TouchableOpacity>
);
DefaultButton.propTypes = {
  style: PropTypes.any,
  onPress: PropTypes.func,
  title: PropTypes.any,
  source: PropTypes.any,
  imgStyle: PropTypes.any,
  lite: PropTypes.bool,
  colorPrimaryInBackground: PropTypes.bool,
  warningText: PropTypes.bool,
};

DefaultButton.defaultProps = {
  style: null,
  onPress: null,
  title: null,
  source: null,
  imgStyle: null,
  lite: false,
  colorPrimaryInBackground: false,
  warningText: false,
};

export default DefaultButton;
