import React, { useEffect } from 'react';
import { BackHandler, View } from 'react-native';
import PropTypes from 'prop-types';
import Wrapper from '../atoms/Wrapper';
import NotFound from '../molecules/NotFound';
import Photos from '../../state/constants/Galery';
import Subtitle from '../atoms/SubTitle';
import Title from '../atoms/Title';
import Spacer from '../atoms/Spacer';
import { ActivityIndicator } from 'react-native-paper';
import mainTheme from '../../state/constants/Colors';

const LogoutScreen = ({ navigation }) => {
  useEffect(() => {
    const backHandler = BackHandler.addEventListener('hardwareBackPress', () => true);
    return () => backHandler.remove();
  }, []);

  return (
    <Wrapper style={{ flex: 1, justifyContent: 'space-between', padding: 32 }}>
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <Title primary bold style={{ textAlign: 'center' }}>Até breve</Title>
        <Spacer size={12} />
        <Subtitle style={{ textAlign: 'center' }}>Encerre o aplicativo</Subtitle>
        <Subtitle style={{ textAlign: 'center' }}>para iniciar uma nova sessão</Subtitle>
        <Spacer size={64} />
        <ActivityIndicator size="large" color={mainTheme.primary} />
      </View>
      <View style={{ alignItems: 'center', paddingBottom: 32 }}>
        <NotFound
          title={""}
          desc={""}
          img={Photos.logoFont}
        />
      </View>
    </Wrapper>
  );
};

LogoutScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default LogoutScreen;
