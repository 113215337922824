import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Picker } from '@react-native-picker/picker'
import styled from 'styled-components/native';
import MainTheme from '../../state/constants/Colors';
import Subtitle from './SubTitle';

const DefaultBorder = styled.View`
      borderColor: ${MainTheme.primary};
      borderWidth: 1;
      borderRadius: 10;
`;

const DropDown = ({
  title, selectedValue, onValueChange, options,
}) => (
  <View style={{
    flex: 1,
  }}
  >
    <Subtitle
      style={{
        marginTop: 20,
        marginBottom: 20,
      }}
      lite
      bold
    >
      {title}
    </Subtitle>
    <DefaultBorder>
      <Picker
        mode="dropdown"
        selectedValue={selectedValue}
        onValueChange={onValueChange}
      >
        {options.map((e) => (
          <Picker.Item label={e.label} value={e.value} />
        ))}
      </Picker>
    </DefaultBorder>
  </View>
);

DropDown.propTypes = {
  title: PropTypes.string.isRequired,
  selectedValue: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default DropDown;
