import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import AppCard from '../atoms/AppCard';
import Title from '../atoms/Title';
import MainTheme from '../../state/constants/Colors';
import DefaultButton from '../atoms/DefaultButton';
import Subtitle from '../atoms/SubTitle';
import { fireAuth } from '../../utils/FirebaseSettings';

const View = styled.View`    
    margin-bottom: 10px;
`;

const TextInput = styled.TextInput`    
    border-width: 0;
    width: 100%;
    color: ${MainTheme.secondary};
    line-height: 20;
`;

const ProfileAbout = ({
  visible,
  editing,
  username,
  text,
  onChangeText,
  placeholderText,
}) => {
  const inputRef = useRef('inputRef');

  return (
    <View>
      { visible && (
        <>
          <Title lite primary bold>
          Sobre {username || (fireAuth.currentUser && fireAuth.currentUser.displayName)}
          </Title>
          <AppCard style={{
            paddingHorizontal: 15,
            paddingVertical: 10,
          }}
          >
            {editing ? (
              <TextInput
                ref={inputRef}
                placeholder={placeholderText}
                onChangeText={(value) => onChangeText(value)}
                value={text}
                maxLength={250}
                multiline
                numberOfLines={3}
              />
            ) : <Subtitle>{text || 'Adicione uma breve descrição 😊'}</Subtitle>}
          </AppCard>
          {
        !!editing && (
          <View style={{
            width: 100,
            marginTop: 15,
          }}
          >
            <DefaultButton
              lite
              style={{
                backgroundColor: '#FFC825',
                borderColor: '#FFFFFF',
              }}
              title="Editar"
              onPress={() => {
                inputRef.current.focus();
              }}
            />
          </View>
        )
      }
        </>
      )}
    </View>
  );
};

ProfileAbout.propTypes = {
  username: PropTypes.string.isRequired,
  text: PropTypes.string,
  onChangeText: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  editing: PropTypes.bool.isRequired,
  placeholderText: PropTypes.string.isRequired,
};

ProfileAbout.defaultProps = {
  text: undefined,
  visible: true,
};

export default React.memo(ProfileAbout);
