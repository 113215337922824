import React, { useState } from 'react';
import {
  Image,
  TouchableOpacity,
  View,
  Platform,
} from 'react-native';
import alert from '../../utils/Alert'
import PropTypes from 'prop-types';
import * as ImagePicker from 'expo-image-picker';
import { usersCollection } from 'middleware/src/database/collections';
import MainTheme from '../../state/constants/Colors';
import { fireAuth, fireStorage } from '../../utils/FirebaseSettings';

const ProfilePicture = ({
  data,
  company,
  isLoggedUser,
  notBlockedUser,
}) => {
  const [dimension, setDimension] = useState(0);

  function findDimensions(layout) {
    const {
      width,
    } = layout;
    setDimension(width);
  }

  const uploadProfileImage = async (result) => {
    const { uid } = fireAuth.currentUser;
    if (!result.cancelled && uid) {
      try {
        const storageRef = fireStorage.ref();
        const avatarImageRef = storageRef.child(`images/${uid}_avatar.jpg`);

        // eslint-disable-next-line no-undef
        const response = await fetch(result.uri);
        const blob = await response.blob();

        const uploadTask = avatarImageRef.put(blob);
        uploadTask.on('state_changed', () => {
        }, (error) => {
          console.log(error);
        }, () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            await usersCollection().doc(uid).update({ avatarUrl: downloadURL });
          });
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const requestMediaLibraryPermissionsAsync = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    return status == 'granted';
  }

  const requestCameraPermissionsAsync = async () => {
    const { status } = await ImagePicker.requestCameraPermissionsAsync();
    return status == 'granted';
  }

  const takePhoto = async () => {
    try {
      const result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 0.8,
        aspect: [1, 1],
      });
      await uploadProfileImage(result);
    } catch (error) {
      alert('Aviso', 'Envio de imagem não realizado.');
    }
  };

  const pickImage = async () => {
    try {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 0.8,
        aspect: [1, 1],
      });
      await uploadProfileImage(result);
    } catch (error) {
      alert('Aviso', 'Envio de imagem não realizado.');
    }
  };

  const changePicture = async () => {
    const requestMediaLibraryResult = await requestMediaLibraryPermissionsAsync();
    const requestCameraResult = await requestCameraPermissionsAsync();
    const canBeSatisfied = requestMediaLibraryResult && requestCameraResult;
    if (canBeSatisfied) {
      if (Platform.OS == "web") {
        alert(
          'Enviar imagem',
          'Confirme para escolher o arquivo de envio.',
          [
            {
              text: 'Enviar Arquivo',
              onPress: () => pickImage(),
            },
            { text: 'Cancelar', style: 'cancel' },
          ],
          { cancelable: false }
        );
        return;
      }
      alert(
        'Selecionar fonte da imagem',
        'Deseja tirar uma foto ou enviar um arquivo salvo?',
        [
          {
            text: 'Enviar Arquivo',
            onPress: () => pickImage(),
          },
          {
            text: 'Abrir Camera',
            onPress: () => takePhoto(),
          },
          { text: 'Cancelar', style: 'cancel' },
        ],
        { cancelable: false }
      );
    } else {
      alert(
        'Permissões não concedidas',
        'Navegue para settings>apps>local chat para permitir alterar a foto.'
      );
    }
  };

  return (
    <TouchableOpacity
      onPress={() => (isLoggedUser ? changePicture() : {})}
    >
      <View
        company={company}
        onLayout={(event) => { findDimensions(event.nativeEvent.layout); }}
        style={{
          backgroundColor: MainTheme.border,
          height: dimension,
          maxHeight: Platform.OS == "web" ? "300px" : undefined,
          maxWidth: Platform.OS == "web" ? "300px" : undefined,
          borderRadius: company ? 15 : 200,
          overflow: 'hidden',
        }}
      >
        { data && data.avatarUrl && (
          <Image
            source={notBlockedUser ? { uri: data.avatarUrl } : false}
            style={{
              width: dimension,
              height: dimension,
            }}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

ProfilePicture.propTypes = {
  company: PropTypes.bool,
  data: PropTypes.any.isRequired,
  isLoggedUser: PropTypes.bool.isRequired,
  notBlockedUser: PropTypes.bool.isRequired,
};

ProfilePicture.defaultProps = {
  company: false,
};

export default React.memo(ProfilePicture);
