import React, { useCallback, useEffect, useState } from 'react';
import * as SplashScreen from 'expo-splash-screen';
import {
  SafeAreaView,
  View,
} from 'react-native';
import { RefreshControl } from '../../utils/refresh'
import alert from '../../utils/Alert'
import PropTypes from 'prop-types';
import axios from 'axios';
import LocationList from '../organisms/LocationList';
import Loading from '../atoms/Loading';
import NotFound from '../molecules/NotFound';
import Photos from '../../state/constants/Galery';
import { registerForPushNotificationsAsync } from '../../utils/NotificationsHandler';
import * as Notifications from 'expo-notifications';
import Routes from '../routes/Routes';
import { fireStore } from '../../utils/FirebaseSettings';
import { Platform } from 'react-native';
import useGroupAccessByURL from '../../state/hooks/useGroupAccessByURL';

const ExploreStateLocation = ({ navigation }) => {
  const [refreshing, setRefreshing] = useState(false);
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(true);

  useGroupAccessByURL(
    navigation,
    (errorMsg) => alert('Aviso', errorMsg),
  );

  useEffect(() => {
    setTimeout(() => {
      SplashScreen.hideAsync().then();
    });
  }, []);

  useEffect(() => {
    registerForPushNotificationsAsync().then();
    Notifications.setNotificationHandler({
      handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: true
      })
    });
    if (Platform.OS != "web") {
      Notifications.getLastNotificationResponseAsync().then((response) => {
        if (response == null) {
          console.warn("ExploreStateLocation[getLastNotificationResponseAsync] Notification is null")
          return;
        }
        redirect("ExploreStateLocation[getLastNotificationResponseAsync]:", response.notification);
      });
    }
    const subscription = Notifications.addNotificationResponseReceivedListener((response) => {
      redirect("ExploreStateLocation[addNotificationResponseReceivedListener]:", response.notification);
    });
    return () => subscription.remove()
  }, [])

  const redirect = (text, notification) => {
    console.warn(text + "Redirect was called")
    if (notification) {
      const { content } = notification.request;
      const data = content.data;
      console.warn(text + "Notification data" + JSON.stringify(data))
      if (data.type == "group") {
        if (data.groupId && (data.parentId || data.localOrEventId)) {
          console.warn(text + "Navigate to group")
          navigation.navigate(Routes.GROUP_CHAT, {
            groupId: data.groupId,
            parentId: data.parentId || data.localOrEventId,
            groupData: {
              "name": data.name
            },
            name: data.name,
          });
        } else {
          console.warn(text + "data is null")
        }
      } else if (data.type == "message") {
        if (!data.foreignUserId || !data.foreignName || !data.token) return;
        navigation.navigate(Routes.PRIVATE_CHAT, {
          foreignUserId: data.foreignUserId,
          foreignName: data.foreignName,
          token: data.token,
        });
      } else {
        console.warn(text + "type not found")
      }
    } else {
      console.warn(text + "Notification is null")
    }
  }

  useEffect(() => {
    const fetchMunicipalities = async () => {
      try {
        const icon = Photos.map
        axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/RS/municipios').then((res) => {
          const parsedResults = res.data.flat().map((result) => ({
            name: "@" + result.nome,
            id: result.id.toString(),
            category: 'place-RS',
            img: icon,
          }));
          setPlaces(parsedResults);
          setLoading(false);
          setRefreshing(false);
          console.warn("Request groups for api")
        }).catch((error) => {
          // fallback
          fireStore.collection('places')
          .doc('ixol4LvakLOQB8BKOVsF')
          .collection('states')
          .doc('MpbJ3utsiVHBJfKT1DTM')
          .collection('cities')
          .get()
          .then((query) => {
            if(!query.empty) {
              const parsedResults = query.docs.map((doc) => ({
                name: "@" + doc.data().name,
                id: doc.data().id.toString(),
                category: doc.data().category,
                img: icon,
              }));
              setPlaces(parsedResults);
              setLoading(false);
              setRefreshing(false);
            }
          })
          console.warn("Request groups for firebase")
        });
      } catch (error) {
        alert("Aviso", "Erro ao realizar consulta. Verifique sua conexâo à internet.")
        setLoading(false);
        setRefreshing(false);
      }
    };
    fetchMunicipalities();
  }, [refreshing]);

  const onRefresh = useCallback(() => {
    registerForPushNotificationsAsync().then();
    setRefreshing(true);
    setLoading(true);
    setPlaces([]);
  }, []);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <LocationList
        data={places}
        navigation={navigation}
        isHighlighted={false}
        refreshControl={(
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
          />
        )}
      />
      {loading && <Loading desc="Buscando locais próximos..." />}
      {places.length === 0 && (!loading && !refreshing) && (
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <NotFound
            title="Nenhum local próximo de você"
            img={Photos.personDontUnderstand}
          />
        </View>
      )}
    </SafeAreaView>
  );
};

ExploreStateLocation.propTypes = {
  navigation: PropTypes.object,
};
ExploreStateLocation.defaultProps = {
  navigation: null,
};

export default ExploreStateLocation;
