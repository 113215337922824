import DateTimePickerModal from 'react-native-modal-datetime-picker';
import DateTimePicker from '@react-native-community/datetimepicker';
import React from 'react';
import { Platform } from 'react-native';
import PropTypes from 'prop-types';

// FIXME library used for ios works perfectly, being a window for date selection;
//  but when used for android, it presents these
//  problem https://github.com/react-native-community/datetimepicker/issues/54
const DateTimeView = ({
  visible,
  value,
  dateMode,
  onDateChange,
  updateViewStatus,
}) => (
  <>
    { Platform.OS === 'ios'
      ? (
        <DateTimePickerModal
          confirmTextIOS="Confirmar"
          cancelTextIOS="Cancelar"
          date={value}
          isVisible={visible}
          mode={dateMode}
          is24Hour
          isDarkModeEnabled={false}
          minimumDate={new Date()}
          onConfirm={(date) => {
            updateViewStatus(false);
            onDateChange(date);
          }}
          onCancel={() => updateViewStatus(false)}
        />
      )
      : (
        visible
        && (
        <DateTimePicker
          value={value}
          mode={dateMode}
          is24Hour
          display="default"
          minimumDate={new Date()}
          onChange={(event, date) => {
            updateViewStatus(false);
            onDateChange(date);
          }}
        />
        )
      )}
  </>
);

DateTimeView.propTypes = {
  visible: PropTypes.bool.isRequired,
  value: PropTypes.object.isRequired,
  dateMode: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
  updateViewStatus: PropTypes.func.isRequired,
};

DateTimeView.defaultProps = {
  dateMode: 'date',
};

export default DateTimeView;
