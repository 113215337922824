import { FlatList, Platform } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import Spacer from '../atoms/Spacer';
import SubGroup from './SubGroup';

const SubGroups = ({ subGroups, onClickItem }) => (
  <FlatList
    contentContainerStyle={{ padding: 5, flex: 1 }}
    data={subGroups}
    removeClippedSubviews={Platform.OS === 'android'}
    initialNumToRender={5}
    maxToRenderPerBatch={5}
    windowSize={10}
    ItemSeparatorComponent={() => <Spacer size={8} />}
    keyExtractor={(item) => item.id}
    renderItem={({ item }) => (
      <SubGroup
        name={item.name}
        onPress={() => onClickItem(item)}
      />
    )}
  />
);

SubGroups.propTypes = {
  subGroups: PropTypes.array.isRequired,
  onClickItem: PropTypes.func.isRequired,
};

export default React.memo(SubGroups);
