import React from 'react';
import { TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import AppCard from '../atoms/AppCard';
import Title from '../atoms/Title';
import Row from '../atoms/Row';
import Spacer from '../atoms/Spacer';
import Badge from './Badge';
import Subtitle from '../atoms/SubTitle';

const EventItem = ({
  title, onPress, desc, placeName, business,
}) => (
  <AppCard style={{ padding: 16, marginBottom: 10 }}>
    <TouchableOpacity
      style={{ flex: 1, justifyContent: 'center' }}
      onPress={onPress}
    >
      <Title
        numberOfLines={1}
        bold
        lite
      >
        {title}
      </Title>
      { desc && (
        <>
          <Spacer size={16} />
          <Subtitle numberOfLines={2}>
            {desc}
          </Subtitle>
        </>
      )}
      <Spacer size={16} />
      <Row style={{ justifyContent: 'space-between' }}>
        <Subtitle
          style={{
            flex: 1,
            marginRight: 8,
          }}
          numberOfLines={1}
          bold
        >
          {placeName}
        </Subtitle>
        <Badge enabled={business} text="business" />
      </Row>
    </TouchableOpacity>
  </AppCard>
);

EventItem.propTypes = {
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  desc: PropTypes.string.isRequired,
  placeName: PropTypes.string.isRequired,
  business: PropTypes.bool,
};

EventItem.defaultProps = {
  business: false,
};

export default EventItem;
