import React from 'react';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import PropTypes from 'prop-types';
import { Image } from 'react-native';
import Photos from '../../state/constants/Galery';
import MainTheme from '../../state/constants/Colors';
import ExploreStateLocation from '../pages/ExploreStateLocation';
import ExploreEvents from '../pages/ExploreEvents';
import MessageTabNavigator from './MessageTabNavigator';
import Profile from '../pages/Profile';

const MainTabNavigator = createBottomTabNavigator({
  // FIXME: Temporarily removed for emergency functionality.
  // Locais: ExploreLocation,
  Locais: ExploreStateLocation,
//  Eventos: ExploreEvents,
  Mensagens: MessageTabNavigator,
  Perfil: Profile,
},
{
  defaultNavigationOptions: ({ navigation }) => ({
    tabBarIcon: ({ focused }) => { // eslint-disable-line react/prop-types
      const { routeName } = navigation.state;
      let iconName;

      if (routeName === 'Locais') {
        iconName = focused ? Photos.map_focused : Photos.map;
      } else if (routeName === 'Eventos') {
        iconName = focused ? Photos.event_focused : Photos.event;
      } else if (routeName === 'Mensagens') {
        iconName = focused ? Photos.message_focused : Photos.message;
      } else if (routeName === 'Perfil') {
        iconName = focused ? Photos.profile_focused : Photos.profileMenu;
      }

      return <Image source={iconName} style={{ height: 25, width: 25 }} />;
    },
    tabBarLabel: navigation.state.routeName,
  }),
  tabBarOptions: {
    keyboardHidesTabBar: true,
    activeTintColor: MainTheme.primary,
    inactiveTintColor: MainTheme.secondary,
    showIcon: true,
    showLabel: true,
    style: {
      elevation: 8,
      backgroundColor: '#ffffff',
      borderTopColor: '#ffffff',
      paddingTop: 2,
      paddingBottom: 2,
    },
  },
});

MainTabNavigator.propTypes = {
  navigation: PropTypes.object.isRequired,
};

MainTabNavigator.navigationOptions = {
  title: 'Navigation',
};

export default MainTabNavigator;
