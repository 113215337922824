import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  View, TouchableOpacity, Image,
} from 'react-native';
import Title from '../atoms/Title';
import StyledText from '../atoms/StyledText';
import DefaultInput from '../atoms/DefaultInput';
import DefaultButton from '../atoms/DefaultButton';
import Spacer from '../atoms/Spacer';
import ArrowBackIcon from '../../../assets/images/arrow-back-icon.png';
import MainTheme from '../../state/constants/Colors';
import Row from '../atoms/Row';
import Wrapper from '../atoms/Wrapper';

const RegisterCompany = ({ navigation }) => {
  const [email, setEmail] = useState('');

  return (
    <Wrapper style={{ justifyContent: 'space-between', padding: 25 }}>
      <Row style={{ paddingTop: 15 }}>
        <TouchableOpacity onPress={() => navigation.navigate('Login')}>
          <Image
            style={{
              tintColor: MainTheme.primary,
              height: 30,
              width: 30,
              marginRight: 20,
            }}
            source={ArrowBackIcon}
          />
        </TouchableOpacity>
        <Title style={{ color: '#FFC000' }}>Sou uma empresa</Title>
      </Row>
      <View style={{ flex: 1, padding: 20, justifyContent: 'space-between' }}>
        <Title>Olá, empreendedor gostariamos muito de ter você conosco!</Title>
        <View>
          <Title bold primary>Qual seu email?</Title>
          <Spacer size={40} />
          <DefaultInput
            value={email}
            onChangeText={(text) => setEmail(text)}
            placeholder="Digite aqui"
          />
        </View>
        <StyledText style={{ textAlign: 'center' }}>Entraremos em contato em breve!</StyledText>
      </View>
      <View style={{ flex: 1, padding: 20, justifyContent: 'flex-end' }}>
        <DefaultButton
          colorPrimaryInBackground
          lite
          style={{ padding: 15, borderWidth: 0, elevation: 2 }}
          title="Enviar"
        />
      </View>
    </Wrapper>
  );
};
RegisterCompany.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default RegisterCompany;
