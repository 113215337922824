import Hyperlink from 'react-native-hyperlink';
import * as WebBrowser from 'expo-web-browser';
import React from 'react';
import PropTypes from 'prop-types';
import MainTheme from '../../state/constants/Colors';
import Title from '../atoms/Title';

const TextLink = ({ text, ...rest }) => (
  <Hyperlink
    onPress={(url) => WebBrowser.openBrowserAsync(url)}
    linkStyle={{ color: MainTheme.link }}
  >
    <Title {...rest}>
      { text }
    </Title>
  </Hyperlink>
);

TextLink.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TextLink;
