import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DefaultModal from '../molecules/DefaultModal';
import Input from '../atoms/Input';

const EditDescriptionModal = ({
  visible, description, onConfirm, onCancel,
}) => {
  const [text, setText] = useState(description);
  const [errorText, setErrorText] = useState('');

  useEffect(() => { setText(description); }, [description]);

  return (
    <DefaultModal
      visible={visible}
      width="90%"
      title="Edição de descrição"
      confirmText="Salvar"
      onCancel={() => onCancel()}
      onConfirm={() => {
        if (text.length !== 0) {
          onConfirm(text);
        } else {
          setErrorText('Necessário informar a descrição');
        }
      }}
    >
      <Input
        style={{ paddingVertical: 12 }}
        placeHolder="Descrição do grupo"
        errorText={errorText}
        multiline
        numberOfLines={12}
        height={200}
        value={text}
        onChangeText={setText}
      />
    </DefaultModal>
  );
};

EditDescriptionModal.propTypes = {
  description: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default React.memo(EditDescriptionModal);
