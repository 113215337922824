import React, { useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';
import PropTypes from 'prop-types';
import LocationItem from '../molecules/LocationItem';
import Spacer from '../atoms/Spacer';
import SearchBar from './SearchBar';

const LocationList = ({ data, navigation, refreshControl }) => {
  const [filter, setFilter] = useState('');
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (filter && data && data.length > 0) {
      const filteredResults = data.filter((item) => {
        const itemName = item.name && item.name.toString();
        if (!itemName) return false;
        const lowerCaseItemName = itemName.toLowerCase();
        const lowerCaseFilter = filter.toLowerCase();
        return lowerCaseItemName.includes(lowerCaseFilter);
      });
      setResults(filteredResults);
    } else if (data && data.length > 0) {
      setResults(data);
    } else {
      setResults([]);
    }
  }, [data, filter]);

  return (
    <FlatList
      refreshControl={refreshControl}
      contentContainerStyle={{ paddingHorizontal: 20, paddingVertical: 20 }}
      data={results}
      ListHeaderComponent={(
        <View style={{ marginBottom: 15, width: '100%' }}>
          <SearchBar value={filter} onChangeText={(value) => setFilter(value)} />
        </View>
      )}
      keyExtractor={(item) => item.id}
      renderItem={({ item }) => (
        <View>
          <LocationItem
            title={item.name}
            parentId={item.id}
            groupData={item}
            source={typeof item.img === 'string' ? { uri: item.img } : item.img}
            navigation={navigation}
          />
          <Spacer size={10} />
        </View>
      )}
    />
  );
};
LocationList.propTypes = {
  data: PropTypes.any.isRequired,
  navigation: PropTypes.object.isRequired,
  refreshControl: PropTypes.object.isRequired
};

export default React.memo(LocationList);
