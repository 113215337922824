import { useEffect } from 'react';
import * as Linking from 'expo-linking';

const useListenUrl = (urlEvent) => {
  useEffect(() => {
    Linking.getInitialURL()
      .then((url) => (url ? urlEvent(url) : {}));
    Linking.addEventListener('url',
      (event) => urlEvent(event.url));
  }, []);
};

export default useListenUrl;
