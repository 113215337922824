import React, { useState } from 'react';
import {
  ScrollView,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
import alert from '../../utils/Alert'
import Constants from 'expo-constants';
import PropTypes from 'prop-types';
import { StackActions } from 'react-navigation';
import { createAndInsertUserInGroup } from 'middleware/src/database/groups';
import { GroupType } from 'middleware/src/states/constants/groups';
import Spacer from '../atoms/Spacer';
import Input from '../atoms/Input';
import Routes from '../routes/Routes';
import Title from '../atoms/Title';
import ButtonColored from '../molecules/ButtonColored';
import DefaultRadioButton from '../molecules/DefaultRadioButton';

const SubGroupCreator = ({ navigation }) => {
  const [privateGroup, setPrivateGroup] = useState(false);
  const [creationInProgress, setCreationInProgress] = useState(false);

  const [nameError, setNameError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [groupRefData, setGroupRefData] = useState({
    parentId: navigation.getParam('parentId'),
    category: GroupType.SUBGROUP,
  });

  const createSubGroupIfNecessary = async () => {
    if (nameError.length !== 0 || passwordError.length !== 0) {
      return Promise.reject();
    }
    if (!groupRefData.name || groupRefData.name.length === 0) {
      setNameError('Nome do subgrupo é obrigatório');
      return Promise.reject();
    }
    if (privateGroup && (
      !groupRefData.password || groupRefData.password.length === 0)
    ) {
      setPasswordError('A senha para o subgrupo é obrigatória');
      return Promise.reject();
    }
    return createAndInsertUserInGroup(groupRefData.parentId, groupRefData);
  };

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={Constants.statusBarHeight}
    >
      <ScrollView style={{ flex: 1, paddingHorizontal: 32 }}>
        <Spacer size={32} />
        <Input
          placeHolder="Nome do grupo"
          errorText={nameError}
          maxLength={100}
          value={groupRefData.name}
          onChangeText={(text) => {
            setGroupRefData({ ...groupRefData, name: text });
            if (text.length < 3) {
              setNameError('O nome deve conter no mínimo 3 caracteres');
            } else {
              setNameError('');
            }
          }}
        />
        <Spacer size={12} />
        <Input
          placeHolder="Descrição para o grupo"
          autoCapitalize="none"
          multiline
          numberOfLines={10}
          value={groupRefData.description}
          onChangeText={(text) => {
            setGroupRefData({ ...groupRefData, description: text });
          }}
        />
        <Spacer size={24} />
        <Title
          bold
          lite
          primary
        >
          Categoria de acesso
        </Title>
        <Spacer size={16} />
        <DefaultRadioButton
          text="Público"
          onPress={() => {
            setPrivateGroup(false);
            setGroupRefData({ ...groupRefData, private: false });
          }}
          checked={!privateGroup}
        />
        <Spacer size={12} />
        <DefaultRadioButton
          text="Privado"
          onPress={() => {
            setPrivateGroup(true);
            setGroupRefData({ ...groupRefData, private: true });
          }}
          checked={privateGroup}
        />
        <Spacer size={24} />
        { privateGroup && (
        <Input
          placeHolder="Definir senha de acesso"
          errorText={passwordError}
          secureTextEntry
          value={groupRefData.password}
          onChangeText={(text) => {
            setGroupRefData({ ...groupRefData, password: text });
            if (text.length < 3) {
              setPasswordError('A senha deve conter no mínimo 3 caracteres');
            } else {
              setPasswordError('');
            }
          }}
        />
        )}
        <Spacer size={24} />
        <ButtonColored
          loading={creationInProgress}
          text="Criar subgrupo"
          onPress={() => {
            setCreationInProgress(true);
            createSubGroupIfNecessary().then(({ id }) => {
              setCreationInProgress(false);
              navigation.dispatch(
                StackActions.replace({
                  routeName: Routes.GROUP_CHAT,
                  params: {
                    groupId: id,
                    parentId: groupRefData.parentId,
                    groupData: { name: groupRefData.name },
                    name: groupRefData.name,
                  },
                }),
              );
            }).catch(() => {
              setCreationInProgress(false);
              alert(
                'Aviso',
                'Não foi possível criar o subgrupo, verifique sua conexão à internet',
              );
            });
          }}
        />
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

SubGroupCreator.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default SubGroupCreator;
