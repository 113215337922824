import React from 'react';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import MainTheme from '../../state/constants/Colors';
import BasicInput from './BasicInput';

const InputWrap = styled.View`
  flex-direction: row; 
  border-bottom-width: 1px;
  border-color: ${(props) => (props.dark ? '#212121' : MainTheme.border)};
  border-radius: 2px; 
`;

// FIXME unify with input.js
const DefaultInput = ({
  style, dark, small, ...rest
}) => (
  <InputWrap dark={dark} style={style}>
    <BasicInput small={small} {...rest} />
  </InputWrap>
);
DefaultInput.propTypes = {
  style: PropTypes.any,
  dark: PropTypes.bool,
  small: PropTypes.bool,
};
DefaultInput.defaultProps = {
  style: null,
  dark: false,
  small: false,
};

export default DefaultInput;
