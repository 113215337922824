import React, { useCallback, useEffect } from 'react';
import { View } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import { withNavigation } from 'react-navigation';

const ViewThermsPage = ({ navigation }) => {
  const handleThermsView = useCallback(async () => {
    const result = await WebBrowser.openBrowserAsync('https://localchat.link/privacy_policy.pdf');
    if (result) {
      navigation.goBack();
    }
  }, [navigation]);

  useEffect(() => {
    handleThermsView().then();

    const focusListener = navigation.addListener('didFocus', () => {
      handleThermsView().then();
    });

    return () => focusListener.remove();
  }, [handleThermsView]);

  return <View />;
};

export default withNavigation(ViewThermsPage);
