import React from 'react';
import { createMaterialTopTabNavigator } from 'react-navigation-tabs';
import MainTheme from '../../state/constants/Colors';
import GroupChatHistory from '../pages/GroupChatHistory';
import PrivateChatHistory from '../pages/PrivateChatHistory';

const MessageTabNavigator = createMaterialTopTabNavigator({
  GRUPOS: GroupChatHistory,
  PRIVADO: PrivateChatHistory,
},
{
  tabBarOptions: {
    activeTintColor: MainTheme.primary,
    inactiveTintColor: MainTheme.secondary,
    showIcon: false,
    showLabel: true,
    style: {
      backgroundColor: 'white',
      color: 'black',
    },
  },
});

export default MessageTabNavigator;
