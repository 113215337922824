import React, { useCallback, useEffect } from 'react';
import { View } from 'react-native';
import alert from '../../utils/Alert'
import PropTypes from 'prop-types';
import { createUserProfile, getUserProfile } from 'middleware/src/database/users';
import Wrapper from '../atoms/Wrapper';
import SignupCard from '../organisms/SignupCard';
import useAuthWatcher from '../../state/hooks/useAuthWatcher';
import { fireAuth } from '../../utils/FirebaseSettings';
import { FIREBASE_ERRORS } from '../../state/constants/FirebaseErrors';
import Routes from '../routes/Routes';
import useRegistrationOngoing from '../../state/hooks/useRegistrationOngoing';

const Signup = ({ navigation }) => {
  const { isAuthenticated, currentUser } = useAuthWatcher();
  const { saveOngoing } = useRegistrationOngoing();

  // FIX-ME Refactor when changing the flow of new user registration.
  const handleNewUser = useCallback(async () => {
    if (isAuthenticated) {
      const { uid, email } = currentUser;
      const userData = await getUserProfile(uid);
      if (!userData) {
        createUserProfile(uid, email).then(() => {
          saveOngoing(true).then(() => {
            navigation.navigate(Routes.GREETING_STACK);
          });
        }).catch(
          () => alert(
            'Ops', 'Não foi possível registrar o usuário, verfique sua conexão à internet',
          ),
        );
      } else {
        alert('Aviso',
          'Usuário já cadastrado, faça login ou entre em contato pelo email contato@localchat.link');
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    handleNewUser().then();
  }, [handleNewUser]);

  const register = async (payload, setError) => {
    if (payload.pass === '' || payload.passConfirm === '' || payload.email === '') {
      setError('Os campos devem ser preenchidos');
    } else if (payload.pass.length < 8) {
      setError('A senha deve conter no mínimo 8 caracteres');
    } else if (payload.pass !== payload.passConfirm) {
      setError('As senhas digitadas não coincidem');
    } else {
      await fireAuth.createUserWithEmailAndPassword(payload.email, payload.pass)
        .then(() => {
          setError(false);
          handleNewUser();
        })
        .catch((e) => {
          setError(FIREBASE_ERRORS[e.code]);
        });
    }
  };

  return (
    <Wrapper style={{ justifyContent: 'space-between', padding: 25 }}>
      <View style={{ flex: 1 }}>
        <SignupCard
          onSignup={(payload, setError) => register(payload, setError)}
          onClose={() => navigation.navigate('Login')}
        />
      </View>
    </Wrapper>
  );
};

Signup.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default Signup;
