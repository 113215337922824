import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import 'firebase/compat/messaging';
import firebase from 'firebase/compat/app';

import { browserLocalPersistence, getAuth } from 'firebase/auth';

const FIREBASE_CONFIG = {
    apiKey: 'AIzaSyAr_f_X3sfsv-jbq0kuuqDLhpVSu0Uq5CY',
    authDomain: 'local-chat-ebab4.firebaseapp.com',
    databaseURL: 'https://local-chat-ebab4.firebaseio.com',
    projectId: 'local-chat-ebab4',
    storageBucket: 'local-chat-ebab4.appspot.com',
    messagingSenderId: '766597749036',
    appId: '1:766597749036:web:dff7e60a6f65444f5b7489',
    measurementId: 'G-9MMGKXVHFH',
};

let fireApp = null;

const initializeFirebaseApp = () => {
    if (!fireApp) {
        fireApp = firebase.initializeApp(FIREBASE_CONFIG);
    }
};

const initializeAuth = async () => {
    initializeFirebaseApp();
    const auth = getAuth(fireApp);
    await auth.setPersistence(browserLocalPersistence);
};

const initializeFirebase = async () => {
    await initializeAuth();
};

await initializeFirebase();

const registerServiceWorker = async () => {
    if ('serviceWorker' in navigator) {
        const registrations = await navigator.serviceWorker.getRegistrations();
        for (const item of registrations) {
            const url = item.active.scriptURL;
            if (url.endsWith('firebase-service-worker.js')) {
                console.warn("Service worker already registered");
                return item;
            }
        }
    } else {
        console.log('Browser is not compatible')
    }
}
await registerServiceWorker();

export const fireAuth = fireApp.auth();
export const fireStore = fireApp.firestore();
export const fireStorage = fireApp.storage();
export const fireFunctions = fireApp.functions();

export const fireMessaging = fireApp.messaging();
export const fireServiceWorker = await registerServiceWorker();
export const googleProvider = firebase.auth.GoogleAuthProvider;
export { initializeAuth };
