import React from 'react';
import { Image, TouchableOpacity, View } from 'react-native';
import PropTypes from 'prop-types';
import styled from 'styled-components/native/dist/styled-components.native.esm';
import StyledText from '../atoms/StyledText';
import Row from '../atoms/Row';
import Title from '../atoms/Title';
import ProfilePicture from '../organisms/ProfilePicture';
import Spacer from '../atoms/Spacer';
import Photos from '../../state/constants/Galery';

const ProfileHeader = ({
  data,
  isLoggedUser,
  notBlockedUser,
  goToSettings,
}) => {
  const SettingsIconWrap = styled.View`
    position: absolute;
    z-index: 999; 
    right: 10;
    top: 10;
    width: 40; 
    height: 40; 
  `;

  return (
    <View style={{
      backgroundColor: '#fbfbfb',
      marginBottom: 8,
    }}>
      {isLoggedUser && (
        <SettingsIconWrap>
          <TouchableOpacity onPress={() => goToSettings()}>
            <Image
              source={Photos.settings}
              style={{
                width: 40,
                height: 40,
              }}
            />
          </TouchableOpacity>
        </SettingsIconWrap>
      )}
      <Row style={{ paddingHorizontal: 10, paddingVertical: 15, justifyContent: 'center' }}>
        <View style={{ flexDirection: 'row', width: '100%', maxWidth: 700 }}>
          <View style={{
            flex: 1, marginRight: 5, justifyContent: 'center',
          }}>
            <ProfilePicture data={data} isLoggedUser={isLoggedUser} notBlockedUser={notBlockedUser} />
          </View>
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <StyledText bold>Nome</StyledText>
            <Title lite>{data && data.name}</Title>
            <StyledText bold>Usuário</StyledText>
            <Title lite>{data && data.username}</Title>
          </View>
        </View>
      </Row>
      <Spacer size={10} />
    </View>
  );
};

ProfileHeader.propTypes = {
  data: PropTypes.any.isRequired,
  isLoggedUser: PropTypes.bool.isRequired,
  notBlockedUser: PropTypes.bool.isRequired,
  goToSettings: PropTypes.func.isRequired,
};

export default React.memo(ProfileHeader);
