import { View } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

const ButtonColored = ({ text, onPress, ...rest }) => (
  <View style={{ alignItems: 'center', marginBottom: 12 }} {...rest}>
    <Button
      text={text}
      onPress={onPress}
      bold
      white
      colorPrimaryInBackground
      {...rest}
    />
  </View>
);

ButtonColored.propTypes = {
  text: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
};

export default React.memo(ButtonColored);
