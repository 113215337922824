const Routes = {
  MAIN_STACK_NAVIGATOR: 'MainStackNavigator',
  GREETING_STACK: 'GreetingStack',
  KNOWING_THE_USER: 'KnowingTheUser',
  CATEGORY_SELECTION: 'CategorySelection',
  THERMS: 'Therms',
  GREETING_TEXT: 'GreetingText',
  SIGNUP: 'Signup',
  RECOVER_PASSWORD: 'RecoverPassword',
  GROUP_CHAT: 'GroupChat',
  GROUP_INFO: 'GroupInfo',
  GROUP_PARTICIPANTS: 'GroupParticipants',
  LOGIN: 'Login',
  LOGOUT: 'LogoutScreen',
  PRIVATE_CHAT: 'PrivateChat',
  PROFILE: 'Profile',
  SETTINGS: 'Settings',
  EVENT_CREATOR: 'EventCreator',
  EXPLORE_LOCATIONS: 'Locais',
  SUBGROUP_CREATOR: 'SubGroupCreator',
  VIEW_THERMS: 'ViewThermsPage',
  FAQ: 'FAQPage',
  DISTANCE_CONFIGURATION: 'DistanceConfiguration',
  INTERESTS: 'Interests',
  NOTIFICATIONS: 'Notifications',
};

export default Routes;
