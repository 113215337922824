import React from 'react';
import styled from 'styled-components/native';
import { ActivityIndicator } from 'react-native';
import PropTypes from 'prop-types';
import MainTheme from '../../state/constants/Colors';
import Subtitle from './SubTitle';
import Spacer from './Spacer';

const LoadingContainer = styled.View`
    justifyContent: center;
    alignItems: center;
    position: absolute;
    height: 100%;
    width: 100%;
    zIndex: 999; 
    opacity: 0.5;
    backgroundColor: #FFF;
`;

const Loading = ({ desc, ...rest }) => (
  <LoadingContainer>
    <ActivityIndicator size="large" color={MainTheme.primary} {...rest} />
    { !!desc && desc !== '' && (
      <>
        <Spacer size={22} />
        <Subtitle lite bold primary>{desc}</Subtitle>
      </>
    )}
  </LoadingContainer>
);


Loading.propTypes = {
  desc: PropTypes.string,
};

Loading.defaultProps = {
  desc: '',
};

export default Loading;
