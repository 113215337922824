import styled from 'styled-components/native';
import { RFValue } from 'react-native-responsive-fontsize';

const BasicInput = styled.TextInput`
    background: transparent;
    flex-grow: 1;
    font-size: ${(props) => (props.small && props.small !== true ? props.small : RFValue(16))};
    font-family: Montserrat;
`;

export default BasicInput;
