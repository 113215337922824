import styled from 'styled-components/native';
import { RFValue } from 'react-native-responsive-fontsize';
import MainTheme from '../../state/constants/Colors';

const Subtitle = styled.Text`
    font-size: ${(props) => (props.lite ? RFValue(11) : RFValue(15))};
    color: ${(props) => (props.primary ? MainTheme.primary : MainTheme.secondary)};
    font-family: ${(props) => (props.bold ? 'MontserratBold' : 'Montserrat')};
`;

export default Subtitle;
