import React from 'react';
import PropTypes from 'prop-types';
import { View, SafeAreaView, Image, Platform } from 'react-native';
import Title from '../atoms/Title';
import DefaultButton from '../atoms/DefaultButton';
import Welcome from '../../../assets/images/welcome.png';
import { fireAuth } from '../../utils/FirebaseSettings';
import Routes from '../routes/Routes';
import ImageAdaptiveDimensions from '../organisms/ImageAdaptiveDimensions';

const GreetingText = ({ navigation }) => {
  const { displayName } = fireAuth.currentUser;

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ flex: 2, padding: 20, alignItems: 'center' }}>
        <Title>
          Bem vindo
          {' '}
          {displayName}
          ,
        </Title>
        <View
          style={{ flex: 1, marginTop: 20 }}
        >
          {
            Platform.OS == "web" ?
              <ImageAdaptiveDimensions
                source={Welcome}
                style={{
                  flex: 1,
                  height: 50,
                  width: '100vh',
                  margin: 8,
                }}
              />
              :
              <Image source={Welcome} resizeMode="contain" style={{ flex: 1 }} />
          }
        </View>
        <Title>Aqui você vai se sentir</Title>
        <Title bold>parte da comunidade,</Title>
        <Title style={{ fontFamily: 'MontserratItalic' }}>não importa onde esteja.</Title>
      </View>
      <View style={{ flex: 1, justifyContent: 'flex-end', padding: 20 }}>
        <DefaultButton
          lite
          title="Continuar"
          style={{
            backgroundColor: '#FFC825',
            borderWidth: 0,
            borderRadius: 10,
            padding: 20,
            elevation: 1,
          }}
          onPress={() => navigation.navigate(Routes.CATEGORY_SELECTION)}
        />
      </View>
    </SafeAreaView>
  );
};
GreetingText.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default GreetingText;
