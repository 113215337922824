import { Image, View } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import Subtitle from '../atoms/SubTitle';
import Spacer from '../atoms/Spacer';

const NotFound = ({
  title, desc, img, visible,
}) => (
  <>
    { visible && (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Subtitle
        bold
        primary
        style={{
          textAlign: 'center',
        }}
      >
        { title }
      </Subtitle>
      <Spacer size={48} />
      <Image
        style={{
          width: 200,
          height: 200,
          resizeMode: 'contain',
        }}
        source={img}
      />
      <Spacer size={24} />
      <Subtitle
        bold
        lite
      >
        {desc}
      </Subtitle>
    </View>
    )}
  </>
);

NotFound.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string,
  img: PropTypes.number.isRequired,
  visible: PropTypes.bool,
};

NotFound.defaultProps = {
  visible: true,
  desc: '',
};

export default React.memo(NotFound);
