import styled from 'styled-components/native';
import { RFValue } from 'react-native-responsive-fontsize';
import MainTheme from '../../state/constants/Colors';

const size = (props) => {
  if (props.nano) {
    return RFValue(10);
  }
  if (props.micro) {
    return RFValue(12);
  }
  if (props.lite) {
    return RFValue(17);
  }
  return RFValue(19);
};

const Title = styled.Text`
    font-size: ${(props) => size(props)};
    color: ${(props) => (props.primary ? MainTheme.primary : MainTheme.secondary)};
    font-family: ${(props) => (props.bold ? 'MontserratBold' : 'Montserrat')};
`;

export default Title;
