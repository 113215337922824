import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import { startGroupsSnapshot } from 'middleware/src/database/users';
import { useDispatch } from 'react-redux';
import { actions } from 'middleware';
import Routes from '../routes/Routes';
import ChatHistory from '../organisms/ChatHistory';

const GroupChatHistory = ({ navigation }) => {
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const dispatch = useDispatch();

  const startSnapshot = useCallback((userId, enabledLoading) => {
    setLoading(enabledLoading);
    startGroupsSnapshot(userId, (allGroups) => {
      setGroups(allGroups);
      dispatch(actions.users.updateGroupData(allGroups));
      setLoading(false);
    });
  }, []);

  return (
    <ChatHistory
      loading={loading}
      action={(item) => navigation.navigate(Routes.GROUP_CHAT, {
        parentId: item.info.parentId,
        groupData: { name: item.info.name },
        groupId: item.id,
        name: item.info.name,
      })}
      messages={groups}
      navigation={navigation}
      triggerCurrentMessages={(userId) => startSnapshot(userId, true)}
      onBackPressed={(userId) => startSnapshot(userId, false)}
    />
  );
};

GroupChatHistory.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default GroupChatHistory;
