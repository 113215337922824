import React from 'react';
import {
  SafeAreaView,
  View,
  FlatList,
  Pressable,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import PropTypes from 'prop-types';
import Photos from '../../state/constants/Galery';
import ListItemSettings from '../atoms/ListItemSettings';
import DefaultButton from '../atoms/DefaultButton';
import { fireAuth } from '../../utils/FirebaseSettings';
import Persistence from '../../state/constants/Persistence';
import Routes from '../routes/Routes';
import ShareMessage from '../../utils/ShareMessage';
import alert from '../../utils/Alert'

const Settings = ({ navigation }) => {
  const shareActionId = 'share';

  const screens = [
    {
      id: Routes.NOTIFICATIONS,
      title: 'Notificacoes',
      image: Photos.notification
    },
    {
      id: Routes.INTERESTS,
      title: 'Interesses',
      image: Photos.interests
    },
    {
      id: Routes.DISTANCE_CONFIGURATION,
      title: 'Distância de busca',
      image: Photos.settings
    },
    {
      id: Routes.FAQ,
      title: 'FAQ',
      image: Photos.faq
    },
    {
      id: Routes.VIEW_THERMS,
      title: 'Termos e Condições',
      image: Photos.terms
    },
    {
      id: shareActionId,
      title: 'Convidar amigos...',
      image: Photos.share
    },
  ];

  const logout = () => {
    fireAuth.signOut()
      .then(async () => {
        const clearStorageTask = Promise.all([
          await AsyncStorage.removeItem(Persistence.NEW_USER, () => { }),
          await AsyncStorage.removeItem(Persistence.DEVICE_PUSH_TOKEN, () => { }),
          await AsyncStorage.removeItem(Persistence.IS_AUTHENTICATED, () => { })
        ])
        clearStorageTask.then(() => navigation.navigate(Routes.LOGOUT));
      }).catch(() => alert('Aviso', 'Não foi possível processar a solicitação'));
  };

  const share = () => {
    const url = new URL(
      'actions/download.html',
      'https://localchat.link',
    ).toString()

    ShareMessage.run("Estou convidando você para conhecer o Local Chat!"
      + `\n\nInstale o aplicativo para se conectar com tudo ao seu redor agora 💛 ${url}`)
  }

  const navigateTo = (id) => {
    if (id === shareActionId) {
      share();
    } else {
      navigation.navigate(id)
    }
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <FlatList
        contentContainerStyle={{ paddingHorizontal: 20, paddingVertical: 20 }}
        data={screens}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => (
          <View>
            <Pressable onPress={() => { console.log("Pressionado:", item.title); navigateTo(item.id); }}>
              <ListItemSettings title={item.title} source={item.image} />
            </Pressable>
          </View>
        )}
      />
      <DefaultButton
        onPress={() => logout()}
        style={{
          padding: 10,
          margin: 30,
        }}
        title="Desconectar"
      />
    </SafeAreaView>
  );
};

Settings.propTypes = {
  navigation: PropTypes.any.isRequired,
};

export default Settings;
